<template>
  <div >
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div 
      class="d-flex align-items-center justify-content-between" 
      style="width: 100%; cursor: pointer;"
      @click="navigateToDashboard"
    >
      <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
    </div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button 
          class="regresar-button" 
          style="margin-left:20px;"
          @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
         Regresar
        </button>
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>
    <div class="container">
      <div class="row justify-content-center" >
        <div class="col-md-8">
          <div class="mb-2">
            <div class="card-body mb-2">
              <div
              class="card mb-2 mt-4 text-white"
              :class="{ selected: selectedCard === planType }"
              :style="{ background: cardGradient }"
              @click="selectCard(planType)"
            >
              <div class="content">
                <div class="flex-container">
                  <div class="left-content">
                    <div style="font-size: 2em">{{ planName }}</div>
                    <div style="font-size: 2em; margin-top: -10px; margin-bottom: 20px">{{ planLabel }}</div>
                  </div>
                  <div class="right-content">
                    <div style="font-size: 2em;">
                      ${{ planPrice }}<span style="font-size: 0.4em">MXN</span>
                    </div>
                    <span style="font-size: 0.8em; margin-top: -10px; margin-bottom: 48px">{{ planPeriod }}</span>
                  </div>
                </div>
              </div>
            </div>
<p style="color:white; margin-bottom:0px; margin-top:40px">Beneficios exclusivos para miembros Premium</p>
<div class="card mb-2 mt-2" 
     :class="{ selected: selectedCard === 'familiar' }"
     @click="selectCard('familiar')">
  <div class="content">
    <div class="flex-container">
      <div class="left-content">
        <p>Acceso a promociones especiales.</p>
        <p v-if="this.$route.params.plan=='familiar'">Consultas ilimitadas para 5 perfiles.</p>
        <p v-else>Consultas ilimitadas para 1 perfil.</p>

        <p>Recibe 10% de CASHBACK<br>
          en cada compra de tratamiento.</p>
      </div>
      <div class="right-content">
        <p>
          <img :src="CheckIcon" alt="Check Icon" style="margin-right: 20px; height: 20px; margin-bottom:5px" />
        </p>
        <p>
          <img :src="CheckIcon" alt="Check Icon" style="margin-right: 20px; height: 20px; margin-bottom:5px" />
        </p>
        <p>
          <img :src="CheckIcon" alt="Check Icon" style="margin-right: 20px; height: 20px; margin-bottom:20px" />
        </p>
      </div>
    </div>
  </div>
</div>

<div style="color:white; text-align:left; margin-left: 30px; margin-bottom:10px">
  <input required class="form-check-input" type="checkbox" id="TermsYes" v-model="terms" style="margin-top: 20px; margin-right:10px">
  <label style="margin-top: 17px; color: white" class="form-check-label" for="TermsYes">
      Acepto los 
      <a href="https://www.dermapp.com/terminos-y-condiciones" target="_blank" style="color: white; text-decoration: underline;">términos y condiciones</a>
  </label>
</div>
<p style="color:white; text-align:left; margin-left: 50px">
  *Puedes cancelar tu membresía<br> en cualquier momento, sin complicaciones.
</p>


<div>
  <button class="btn-primary custom-button mt-4"
   @click="subscribe()">
  Convertirme en Miembro</button>
</div>

<div v-if="this.$route.params.plan=='premium'">
  <button class="btn-primary custom-button mt-4"
   @click="$router.push({ name: 'SubscriptionDetails', params: { plan: 'anual' } })">
  Ahorra $600 con la Membresia Anual</button>
</div>
            
      
        </div>
      </div>
    </div>
  </div>
</div>

          </div>
</template>
  
  
  <script>
  import axios from 'axios';
  import { mapState } from 'vuex';
  import { loadStripe } from '@stripe/stripe-js';
  import Swal from 'sweetalert2';
  import { getAuth, onAuthStateChanged } from 'firebase/auth';



  export default {
  data() {
    return {
      HeaderLogo: require('@/assets/dermapp-white.png'),
      CheckIcon: require('@/assets/green-check-icon.webp'),
      BackIcon: require('@/assets/arrow-back.png'),

      selectedCard: null, // Track the selected card,
      price: '',
      terms: '',
    };
  },
  computed: {
      ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName', 'primaryName', 'primaryBirthday']),

      planType() {
      return this.$route.params.plan;
    },
    planName() {
      switch (this.planType) {
        case 'premium':
          return 'Membresía';
        case 'anual':
          return 'Membresía';
        case 'familiar':
          return 'Membresía';
        default:
          return 'Membresía';
      }
    },
    planLabel() {
      switch (this.planType) {
        case 'premium':
          return 'Premium';
        case 'anual':
          return 'Anual';
        case 'familiar':
          return 'Familiar';
        default:
          return '';
      }
    },
    planPrice() {
      switch (this.planType) {
        case 'premium':
          return 125;
        case 'anual':
          return 900;
        case 'familiar':
          return 300;
        default:
          return 0;
      }
    },
    planPeriod() {
      switch (this.planType) {
        case 'premium':
          return 'cada mes';
        case 'anual':
          return 'cada año';
        case 'familiar':
          return 'cada mes';
        default:
          return '';
      }
    },
    cardGradient() {
      switch (this.planType) {
        case 'premium':
          return 'linear-gradient(90deg, #ffb838, #ff860b)';
        case 'anual':
          return 'linear-gradient(90deg, #19d2ff, #0d80ff)';
        case 'familiar':
          return 'linear-gradient(90deg, #ff38b4, #1512ff)';
        default:
          return 'transparent';
      }
    },
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });


},
  methods: {
    navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },
    selectCard(cardType) {
      this.selectedCard = cardType; // Save the selected card type
    },
    async subscribe() {

      if (!this.terms) {
        Swal.fire({
          icon: 'warning',
          text: 'Por favor acepta los terminos y condiciones para continuar.',
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#0d6efd',
        });
      } else {
        const stripe = await loadStripe(`${this.$loadstripepk}`);
      let price ='';

      switch (this.$route.params.plan) {
  case 'anual': price = `${this.$priceannual}`; break;
  case 'familiar': price = `${this.$pricefamily}`; break;
  case 'premium': price = `${this.$pricemonthly}`; break;

  default:
    break;
}
      axios
        .post(`${this.$baseURL}/api/create-subscription-checkout-session`, {
          user_id: this.userId,
          email: this.email,
          price: price,
          assessment_id: this.userId,
          payment_method: 'card',
          phone: this.phone,
          patient_name: this.profileName,
          profile_id: this.profileId,
        })
        .then((response) => {
          const sessionId = response.data.sessionId;
          stripe.redirectToCheckout({ sessionId });
        })
        .catch((error) => {
          console.error('Error creating checkout session:', error);
        });
      }

      
    },
  },
};

  </script>
  
  <style scoped>
  .regresar-button {
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: auto;
    margin-right: 20px; /* Ajusta según sea necesario */
    border: 2px solid #0D80FF;
    border-radius: 10px;
    background-color: #0D80FF; /* Color de fondo azul */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transición suave opcional */
  }
  
  .regresar-button:hover {
    background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
  }
  
  .header {
    margin-top: -5px;
    position: relative;
    width: 100%;
    height: auto;
    background: #36b5ff;
    z-index: 1;
  }
  
  .nav-item {
    margin-right: 20px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .content {
    text-align: left;
  }
  
  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-content, .right-content {
    display: flex;
    flex-direction: column;
  }
  
  .title {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  p {
    font-size: 1em;
    color: black;
  }

  .right-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Aligns children to the right */
  }


  
  .card {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 30px 20px 10px 20px;
    margin: 20px 10px;
    position: relative;
  }


  .custom-button {
    background-color: #1512ff;
    color: white;
    width: 80%;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 1.0em;
    font-weight: 500;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.custom-button:hover {
    background-color: #0f0ccc;
}
  
  
  </style>
  

  