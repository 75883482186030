<template>
  <div class="discount-code-container">
    <h3 class="discount-title">Cupón:</h3>
    
    <!-- Before Applying Coupon -->
    <div 
      :class="['discount-box', { 'applied': appliedDiscount }]" 
    >
      <div v-if="!appliedDiscount" class="input-section">
        <input 
          type="text" 
          v-model="couponCode" 
          placeholder="Ingresa el cupón" 
          class="coupon-input"
        />
        <button 
          @click="applyCoupon" 
          class="apply-button"
        >
          Aplicar
        </button>
      </div>
      
      <!-- After Applying Coupon -->
      <div v-else class="applied-section">
        <img 
          src="@/assets/checkmark-icon.png" 
          alt="Cupón aplicado" 
          class="checkmark-icon"
        />
        <div class="applied-details">
          <p class="applied-text">{{ appliedDiscount.code_name }} aplicado</p>
          <p class="discount-amount">{{ formattedDiscountAmount }}</p>
        </div>
        <button 
          @click="removeCoupon" 
          class="remove-button"
        >
          Quitar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscountCodeBox',
  props: {
    appliedDiscount: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      couponCode: '',
    };
  },
  computed: {
    formattedDiscountAmount() {
      if (!this.appliedDiscount) return '';
      
      const { discount_type, coupon_discount, discount_value } = this.appliedDiscount;
      
      switch (discount_type) {
        case 'percentage':
          return `MX$-${coupon_discount.toFixed(2)} (-${discount_value}%)`;
        case 'shipping':
          return `MX$-${coupon_discount.toFixed(2)} (Envío gratis)`;
        case 'fixed':
        default:
          return `MX$-${coupon_discount.toFixed(2)}`;
      }
    },
  },
  methods: {
    applyCoupon() {
      if (this.couponCode.trim() === '') {
        this.$emit('show-alert', 'Por favor, ingresa un cupón válido.');
        return;
      }
      // Emit the coupon code to the parent component
      this.$emit('apply-coupon', this.couponCode.trim());
      // Clear the input field after applying
      this.couponCode = '';
    },
    removeCoupon() {
      // Emit an event to remove the applied coupon
      this.$emit('remove-coupon');
    },
  },
};
</script>

<style scoped>
/* Container Styles */
.discount-code-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto 20px;
  padding: 0 20px; /* Updated padding */
  box-sizing: border-box;
}

/* Title Styles */
.discount-title {
  margin-bottom: 10px;
  font-size: 18px;
  color: #000;
  text-align: left;
  font-weight: bold;
}


/* Discount Box Styles */
.discount-box {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 2px solid #d3d3d3; /* Default border color */
  border-radius: 8px;
  padding: 10px;
  transition: border-color 0.3s;
}

.discount-box.applied {
  border-color: #5fc05f; /* Green border when applied */
}

/* Input Section Styles */
.input-section {
  display: flex;
  align-items: center;
  width: 100%;
}

.coupon-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 5px;
}

.apply-button {
  background: none;
  border: none;
  color: #0D80FF;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  transition: color 0.3s;
}

.apply-button:hover {
  color: #0056b3;
}

/* Applied Section Styles */
.applied-section {
  display: flex;
  align-items: center;
  width: 100%;
}

.checkmark-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.applied-details {
  flex: 1;
}

.applied-text {
  margin: 0;
  font-size: 14px;
  color: black;
  font-weight: 500;
  text-align: left;
}

.discount-amount {
  margin: 0;
  font-size: 12px;
  color: gray;
  text-align: left;
}

/* Remove Button Styles */
.remove-button {
  background: none;
  border: none;
  color: #FF38B5;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  transition: color 0.3s;
}

.remove-button:hover {
  color: #FF38B5;
}

/* Media Queries */
@media (min-width: 768px) {
  .discount-code-container {
    margin: 0 20px 20px 20px;
    padding: 0;
    max-width: 300px;
  }
}

/* Additional media query for mobile if necessary */
@media (max-width: 767.98px) {
  .discount-code-container {
    padding: 0 20px; /* Ensures consistent padding on mobile */
  }
}
</style>
