<template>
  <div class="container" style="height: 100vh;">
    <div class="header" style="margin-top: 10px; height: 200px">
      <img :src="Logo" alt="CreateLogo" class="create-logo" style="height: 200px;"/>
    </div>
      <div class="card-container d-flex justify-content-center align-items-center">
    <div class="card" style="width: 500px;">
      <div class="card-body">
        <h5 class="text-center mb-2 mt-4">Cambiar contraseña</h5>
        <form @submit.prevent="changePassword">
          <div class="form-group">
            <label for="currentPassword">Contraseña actual</label>
            <div class="input-group">
              <input :type="showCurrentPassword ? 'text' : 'password'" class="form-control" id="currentPassword" v-model="currentPassword" required>
              <button class="btn btn-outline-secondary" type="button" @click="showCurrentPassword = !showCurrentPassword">{{ showCurrentPassword ? 'Ocultar' : 'Mostrar' }}</button>
            </div>
          </div>
          <div class="form-group">
            <label for="newPassword">Nueva contraseña</label>
            <div class="input-group">
              <input :type="showNewPassword ? 'text' : 'password'" class="form-control" id="newPassword" v-model="newPassword" required>
              <button class="btn btn-outline-secondary" type="button" @click="showNewPassword = !showNewPassword">{{ showNewPassword ? 'Ocultar' : 'Mostrar' }}</button>
            </div>
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirmar nueva contraseña</label>
            <div class="input-group">
              <input :type="showConfirmPassword ? 'text' : 'password'" class="form-control" id="confirmPassword" v-model="confirmPassword" required :class="passwordsMatch ? 'is-valid' : 'is-invalid'">
              <button class="btn btn-outline-secondary" type="button" @click="showConfirmPassword = !showConfirmPassword">{{ showConfirmPassword ? 'Ocultar' : 'Mostrar' }}</button>
            </div>
          </div>
          <div class="text-center" style="margin-top:20px;">
            <button type="submit" class="btn btn-primary">Cambiar contraseña</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  </div>
</template>
  
  <script>
import { getAuth, onAuthStateChanged, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
  
  export default {
    data() {
      return {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        showCurrentPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
        Logo: require('@/assets/dermapp-white.webp')
      };
    },
    mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });


},
    computed: {
      passwordsMatch() {
        return this.newPassword === this.confirmPassword;
      },
    },
    methods: {
        async changePassword() {
  if (this.newPassword !== this.confirmPassword) {
    alert("New password and confirm password do not match.");
    return;
  }

  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, this.currentPassword);

    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, this.newPassword);
    alert("Password changed successfully.");
    this.$router.push("/patient-dashboard"); // Redirect to home or another page after successful password change
  } catch (error) {
    console.error("Error changing password:", error.message);
    alert(error.message);
  }
},

    },
  }
  </script>
  
  <style>
  body {
    background: #38b6ff;
  }
  
  .is-valid {
    border-color: #28a745;
    background-color: rgba(40, 167, 69, 0.1);
  }
  
  .is-invalid {
    border-color: #dc3545;
    background-color: rgba(220, 53, 69, 0.1);
  }
  </style>
