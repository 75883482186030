<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div 
  class="d-flex align-items-center justify-content-between" 
  style="width: 100%; cursor: pointer;"
  @click="navigateToDashboard"
>
  <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
</div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button 
          class="regresar-button" 
          style="margin-left:20px;"
          @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
         Regresar
        </button>
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>
    
    <div class="container">
      <div class="row justify-content-center">
        <div v-if='this.isDataLoaded' class="col-md-8">
          <div>
            <div class="card mb-2 d-flex align-items-center flex-row">
              <img :src="CreditsIcon" alt="CreditsIcon" class="credits-icon" style="height: 100px; width: auto; margin: 20px" />
              <div style="flex-grow: 1; text-align: center;">
                <p style="font-size:40px; margin-bottom: 0px">${{ this.credits.toFixed(2) }}</p>
                <p class="card-text">Mis Créditos</p>
              </div>
            </div>
          </div>
          
          <!-- Adjusted container to have a fixed height and be scrollable -->
            
            <div class="card-body"  style="height: 65vh; overflow-y: auto;">
              <!-- Profile cards will be here and scrollable within the container -->
              <h5 style="text-align:left; color:white; margin-top:20px">Historial de Créditos</h5>
              <div v-for="credit in this.credits_history" :key="credit.id">
                <div class="card mb-2" style="padding:10px">
                  <div class="d-flex justify-content-between align-items-center">
                    <div style="width:75%; text-align:left;">
                      <p class="mb-1" style="font-size: 16px; font-weight: bold;">
                        {{ credit.description }}
                      </p>
                      <p class="text-muted mb-1" style="font-size:12px">{{ new Date(credit.created_at).toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' }) }}</p>
                    </div>
                    <div style="width:25%; text-align:right">
                      <p :style="{ color: credit.type === 'add' ? '#96ff38' : '#ffbb38', fontWeight: 'bold', fontSize: '20px' }">
                        {{ credit.type === 'add' ? '+' : '-' }}{{ credit.amount.toFixed(2) }}
                      </p>
                      <p class="text-muted mb-1" style="font-size:12px">
                        {{ credit.source_type === 'purchase' ? 'Pedido No. ' + credit.source_id : credit.source_type === 'mission' ? 'Mision No. ' + credit.source_id : 'Regalo de dermapp' }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="card-body d-flex justify-content-center align-items-center">
                <div v-if="this.new_message == 1" style="margin-right:20px">
                  <router-link :to="{ name: 'UserMessages', params: { id: this.userId }}">
                    <img :src="Messages" alt="Messages" class="messages" style="height: 40px; width: auto;" />
                  </router-link>
                </div>
              </div>  
        </div>
      </div>
    </div>
  </div>
</template>


  
  <script>
    import { getAuth, onAuthStateChanged } from 'firebase/auth';
  import { mapActions, mapState } from 'vuex';
  import axios from 'axios';
  
  import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';

export default {
  setup() {
    const { track } = useFacebookPixel();

    

    onMounted(() => {
      track('ViewContent', { content_name: 'Patient dashboard' });

    });

    return {};
  },
    data() {
      return {
        profiles: [],
        credits_history: [],
        isDataLoaded: false,
        HeaderLogo: require('@/assets/dermapp-white.png'),
        BackIcon: require('@/assets/arrow-back.png'),


        CreditsIcon: require('@/assets/credits-icon.webp'),
        User: require('@/assets/edit-user-icon.webp'),
        AddProfile: require('@/assets/add-profile-icon.png'),
        Messages: require('@/assets/message-icon.png'),
        NewMessages: require('@/assets/new-message-icon.png'),
        UserOrders: require('@/assets/user-orders.webp'),
        UserProfiles: require('@/assets/user-profiles.webp'),


        new_message: null,

      };
    },
    computed: {
      ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName', 'primaryName', 'primaryBirthday']),
    },

    mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
          // Fetch the user credits
      const token = localStorage.getItem('Token')
      axios.get(`${this.$baseURL}/api/user-credits-history`, {
      params: {
        user_id: this.userId,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    }).then((credits_response) => {
      this.credits_history = credits_response.data.credits_history
    });
      
      this.isDataLoaded = true;
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });
    
},
    methods: {
      navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },

      ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),

      calculateAge(birthday) {
    const birthdate = new Date(birthday);
    const ageDifMs = Date.now() - birthdate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  },

      parseSex(sex){
        if(sex=='Male'){
          return 'Masculino';
        } else {
          return 'Femenina';
        }
      },
      
      async getProfiles() {
        try {
          const response = await axios.get(`${this.$baseURL}/api/get-profiles`, {
            params: {
              user_id: this.userId,
            },
          });

          this.profiles = response.data.data;
  
        } catch (error) {
          console.error('Error fetching profiles:', error);
        }
      },

      addNewProfile() {
        this.$router.push({ name: 'CreateProfile' });
      },
    },

  };
  </script>
  
  <style scoped>
  .regresar-button {
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: auto;
    margin-right: 20px; /* Ajusta según sea necesario */
    border: 2px solid #0D80FF;
    border-radius: 10px;
    background-color: #0D80FF; /* Color de fondo azul */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transición suave opcional */
  }
  
  .regresar-button:hover {
    background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
  }
  

.header {
    margin-top: -5px;
    position: relative;
    width: 100%;
    height: auto;
    background: #36b5ff;
    z-index: 1;
  }

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-link-style {
  text-decoration: none;
  color: inherit; /* Ensure that the text color remains unchanged */
}

  </style>
  