<template>
    <div>
      
      <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
        <div 
  class="d-flex align-items-center justify-content-between" 
  style="width: 100%; cursor: pointer;"
  @click="navigateToDashboard"
>
  <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
</div>
        <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
          <button 
            class="regresar-button" 
            style="margin-left:20px;"
            @click="$router.back();">
            <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
           Regresar
          </button>
          <button 
            class="nav-item" 
            style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
            @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
            Créditos: {{ credits }}
          </button>
        </nav>
      </header>
      <div class="container d-flex align-items-center" style="height: 100vh; width: 100vw; flex-direction: column;">
        <div class="card-container" style="max-width: 100%;">
          <div class="card mb-3"> <!-- Add the mb-4 class here for bottom margin -->
            <h5 class="text-center mb-2 mt-4">Crear Perfil</h5>
          <div class="card-body">
            <form @submit.prevent="createProfile()" style="text-align: left;">
              <div class="mb-3" >
                Nombre
                  <input type="text" class="form-control" id="name" v-model="name" placeholder="Ingresa tu nombre">
                </div>
                <div class="mb-3">
                  Apellido
              <input type="text" class="form-control" id="name" v-model="last_name" placeholder="Ingresa tu apellido" required>
            </div>
            <div class="mb-3">
              Fecha de nacimiento
              <div class="input-group">
                <input type="date" class="form-control" v-model="birthday" required>
              </div>
            </div>
                <div class="mb-3">
                  Peso
                  <input type="text" class="form-control" id="weight" v-model="weight" placeholder="Ingresa tu peso (kilogramos)">
                </div>
                <div class="mb-3">
                  Altura
                  <input type="text" class="form-control" id="height" v-model="height" placeholder="Ingresa tu altura (metros)">
                </div>
                <div class="mb-3">
                  Sexo
                  <select class="form-control" id="sex" v-model="sex">
                    <option value="" disabled selected>Selecciona tu sexo</option>
                    <option value="Male">Masculino</option>
                    <option value="Female">Femenino</option>
                  </select>
                </div>
                <div class="mb-3">
                  Tienes alguna enfermedad?
        <div class="input-group">
          <input type="text" class="form-control" id="disease" v-model="disease" placeholder="Ingresa un padecimiento">
          <button class="btn btn-primary" type="button" @click="addDisease">Agregar</button>
        </div>
        <ul class="list-group mt-3">
          <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(disease, index) in diseasesHistory" :key="index">
            {{ disease }}
            <button type="button" class="btn-close" @click="removeDisease(index)"></button>
          </li>
        </ul>
      </div>
      <div class="mb-3">
        Tienes alergia a algun medicamento?
        <div class="input-group">
          <input type="text" class="form-control" id="allergy" v-model="allergy" placeholder="Ingresa una alergia">
          <button class="btn btn-primary" type="button" @click="addAllergy">Agregar</button>
        </div>
        <ul class="list-group mt-3">
          <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(allergy, index) in allergies" :key="index">
            {{ allergy }}
            <button type="button" class="btn-close" @click="removeAllergy(index)"></button>
          </li>
        </ul>
      </div>
      <div class="text-center"> <!-- Add this line -->
        <button type="submit" class="btn btn-primary mb-3" style="width: 80%;">Crear perfil</button>
      </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>
    import { getAuth, onAuthStateChanged } from 'firebase/auth';

  import axios from 'axios';
  import { mapState } from 'vuex';

  import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';
  
  
  export default {
    setup() {
    const { track } = useFacebookPixel();


    onMounted(() => {
      track('CompleteRegistration', { content_name: 'Create Profile' });
      track('ViewContent', { content_name: 'Create Profile' });
  
    });

    return {};
  }, 
    data() {
      return {
        referral_code: '',
        name:'',
        weight:'',
        height:'',
        sex:'',
        birthday: '',
        year: '',
        month: '',
        day: '',
        diseasesHistory:[],
        disease:'',
        allergies:[],
        allergy:'',
        HeaderLogo: require('@/assets/dermapp-white.png'),
        BackIcon: require('@/assets/arrow-back.png'),

      };
    },
  
    components: {
    },

    mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });


},

    computed: {
      ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'primaryName', 'primaryBirthday']),
    },
    
    methods: {
      navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },
      
  
      addDisease() {
        if (this.disease) {
          this.diseasesHistory.push(this.disease);
          this.disease = '';
        }
      },
  
      removeDisease(index) {
        this.diseasesHistory.splice(index, 1);
      },

      addAllergy() {
        if (this.allergy) {
          this.allergies.push(this.allergy);
          this.allergy = '';
        }
      },
  
      removeAllergy(index) {
        this.allergies.splice(index, 1);
      },

      formatYear() {
        if (this.year >= 0 && this.year <= 99) {
            if (this.year < 24) {  // Assuming years < 33 belong to 2000s. Adjust based on your use case.
                return '20' + this.padZero(this.year);
            } else {
                return '19' + this.padZero(this.year);
            }
        }
        return this.year;  // Assumes year is already in YYYY format.
    },

    padZero(num) {
        return num.toString().padStart(2, '0');
    },
  
      async createProfile() {
        try{
          
          const profile = {
            user_id: this.userId,
            name: this.name +' '+ this.last_name,
            birthday: this.birthday,
            weight: this.weight,
            height: this.height,
            sex: this.sex,
            diseases_history: this.diseasesHistory,
            allergies: this.allergies,
          }
  
  
          const responseProfile = await axios.post(`${this.$baseURL}/api/create-profile`, profile);
  
  
          if(responseProfile.status === 200) {
            this.$router.push('/patient-dashboard');
          }
        } catch (error) {
          console.error('Error creating user:', error);
        }
      },
    },
  };
  </script>
  
  
  
  <style>
  .regresar-button {
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: auto;
    margin-right: 20px; /* Ajusta según sea necesario */
    border: 2px solid #0D80FF;
    border-radius: 10px;
    background-color: #0D80FF; /* Color de fondo azul */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transición suave opcional */
  }
  
  .regresar-button:hover {
    background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
  }
  
  body {
    background: #38b6ff;
  }

  .header {
    margin-top: -5px;
    position: relative;
    width: 100%;
    height: auto;
    background: #36b5ff;
    z-index: 1;
  }

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}
  </style>
  
  
  
  
           
  