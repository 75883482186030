<template>
  <div>
    <!-- Header -->
    <header class="header d-flex flex-column align-items-start justify-content-between p-3 w-100">
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%; cursor: pointer;"
        @click="navigateToDashboard"
      >
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;" />
      </div>
    </header>

    <!-- Main Container -->
    <div class="container d-flex align-items-center flex-column" style="min-height: 100vh; width: 100vw;">
      <div class="card-container w-100">
        <div class="card mb-3">
          <!-- Dynamic Title Based on Step -->
          <h5 v-if="step === 0" class="text-center mb-2 mt-4">Consulta con dermatólogos<br>por sólo 180 MXN</h5>
          <h5 v-else-if="step === 1" class="text-center mb-2 mt-4">Obtén un tratamiento en<br>menos de 24 horas</h5>
          <h5 v-else-if="step === 2" class="text-center mb-2 mt-4">Ahorra hasta 50% con fórmulas<br>magistrales en tu tratamiento</h5>

          <div class="card-body">
            <!-- Loading Indicator -->
            <div v-if="isUploading" class="loading-indicator">
              <p>Subiendo archivos, por favor espera...</p>
            </div>

            <!-- Form -->
            <form @submit.prevent="nextStep">
              <div class="text-left">

                <!-- Step 0: Personal Information -->
                <div v-if="step === 0">
                  <div class="stepper">
                    <span class="step" :class="{ active: step === 0 }">1</span>
                    <span class="step" :class="{ active: step === 2 }">2</span>
                    <span class="step" :class="{ active: step === 2 }">3</span>
                  </div>
                  <h5 class="text-center mb-4">1. Información Personal</h5>

                  <div class="mb-3">
                    <label>Nombre</label>
                    <input type="text" class="form-control" v-model="name" placeholder="Ingresa tu nombre" required />
                  </div>

                  <div class="mb-3">
                    <label>Apellido</label>
                    <input type="text" class="form-control" v-model="last_name" placeholder="Ingresa tu apellido" required />
                  </div>

                  <div class="mb-3">
                    <label>Email</label>
                    <input type="email" class="form-control" v-model="email" placeholder="Ingresa tu correo" required />
                  </div>

                  <div class="mb-3">
                    <label>Whatsapp</label>
                    <vue-tel-input ref="phoneInput" v-model="phone" mode="international" @input="handleUpdate" required></vue-tel-input>
                    <p v-if="!isPhoneValid && phone.length > 0" style="color:red">Por favor ingrese un número de teléfono válido</p>
                  </div>

                  <div class="mb-3">
                    <label>Fecha de nacimiento</label>
                    <input type="date" class="form-control" v-model="birthday" required />
                  </div>

                  <div class="mb-3">
                    <label>Peso (kg)</label>
                    <input type="text" class="form-control" v-model="weight" placeholder="Ingresa tu peso" required />
                  </div>

                  <div class="mb-3">
                    <label>Altura (m)</label>
                    <input type="text" class="form-control" v-model="height" placeholder="Ingresa tu altura" required />
                  </div>

                  <div class="mb-3">
                    <label>Sexo</label>
                    <select class="form-control" v-model="sex" required>
                      <option value="" disabled selected>Selecciona tu sexo</option>
                      <option value="Male">Masculino</option>
                      <option value="Female">Femenino</option>
                    </select>
                  </div>

                  <!-- Disease History -->
                  <div class="mb-3">
                    <label>¿Tienes alguna enfermedad?</label>
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="disease" placeholder="Ingresa tu padecimiento" />
                      <button class="btn btn-primary" type="button" @click="addDisease">Agregar</button>
                    </div>
                    <ul class="list-group mt-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(dis, index) in diseasesHistory" :key="index">
                        {{ dis }}
                        <button type="button" class="btn-close" @click="removeDisease(index)"></button>
                      </li>
                    </ul>
                  </div>
        

                  <!-- Allergies -->
                  <div class="mb-3">
                    <label>¿Tienes alergia a algún medicamento?</label>
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="allergy" placeholder="Ingresa tu alergia" />
                      <button class="btn btn-primary" type="button" @click="addAllergy">Agregar</button>
                    </div>
                    <ul class="list-group mt-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(allerg, index) in allergies" :key="index">
                        {{ allerg }}
                        <button type="button" class="btn-close" @click="removeAllergy(index)"></button>
                      </li>
                    </ul>
                  </div>

                  <div class="mb-4 d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary mt-4">Continuar</button>
                  </div>

                  <div class="mb-4 d-flex justify-content-center">
                    <p class="mb-0">¿Ya tienes una cuenta? <router-link to="/signin"><br>Iniciar sesión</router-link></p>
                  </div>

                </div>

                <!-- Step 1: Clinical Information -->
                <div v-if="step === 1">
                  <div class="stepper">
                    <span class="step" :class="{ active: step === 1 }">1</span>
                    <span class="step" :class="{ active: step === 1 }">2</span>
                    <span class="step" :class="{ active: step === 2 }">3</span>
                  </div>
                  <h5 class="text-center mb-4">2. Información Clínica</h5>

                  <div class="mb-3">
                    <label for="reason">¿Cuál es el motivo de la consulta?</label>
                    <textarea required class="form-control" v-model="assessment.consultation_reason" placeholder="Ingresa sólo un problema por consulta"></textarea>
                  </div>

                  <!-- Locations -->
                  <div class="mb-3">
                    <label for="location">¿Cuáles son las zonas afectadas?</label>
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="location" placeholder="Ingresa una zona" />
                      <button class="btn btn-primary" type="button" @click="addLocation">Agregar</button>
                    </div>
                    <ul class="list-group mt-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center"
                          v-for="(loc, index) in assessment.locations"
                          :key="index">
                        {{ loc }}
                        <button type="button" class="btn-close" @click="removeLocation(index)"></button>
                      </li>
                    </ul>
                  </div>

                  <!-- Symptoms -->
                  <div class="mb-3">
                    <label for="symptom">¿Qué síntomas tienes?</label>
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="symptom" placeholder="Ingresa un síntoma" />
                      <button class="btn btn-primary" type="button" @click="addSymptom">Agregar</button>
                    </div>
                    <ul class="list-group mt-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center"
                          v-for="(sym, index) in assessment.symptoms"
                          :key="index">
                        {{ sym }}
                        <button type="button" class="btn-close" @click="removeSymptom(index)"></button>
                      </li>
                    </ul>
                  </div>

                  <div class="mb-3">
                    <label>¿Cuánto tiempo tienes con el problema?</label>
                    <select class="form-control" v-model="assessment.problem_duration">
                      <option value="">Selecciona la duración aproximada</option>
                      <option value="Days">Días</option>
                      <option value="Weeks">Semanas</option>
                      <option value="Months">Meses</option>
                      <option value="Years">Años</option>
                    </select>
                  </div>

                  <!-- Pregnancy Status for Female -->
                  <div class="mb-3" v-if="sex === 'Female'">
                    <label>¿Estás embarazada, lactando o planeas embarazarte en menos de 6 meses?</label>
                    <select class="form-control" v-model="assessment.pregnancy_status">
                      <option value="">Selecciona una opción</option>
                      <option value="Pregnancy">Embarazada</option>
                      <option value="Lactancy">Lactando</option>
                      <option value="Plans">Planes de embarazo</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  <!-- Problem Evolution -->
                  <div class="mb-3">
                    <label>¿Has notado cambios con el tiempo?</label>
                    <div>
                      <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="changesYes" value="Yes" v-model="changes">
                      <label style="margin-left: 10px;" for="changesYes">Si</label>
                      <input style="margin-left: 80px;" type="radio" id="changesNo" value="No" v-model="changes">
                      <label style="margin-left: 10px;" for="changesNo">No</label>
                    </div>
                  </div>

                  <div class="mb-3" v-if="changes === 'Yes'">
                    <label for="evolution">¿Qué cambios has notado?</label>
                    <textarea class="form-control" v-model="assessment.problem_evolution"></textarea>
                  </div>

                  <!-- Previous Treatments -->
                  <div class="mb-3">
                    <label>¿Has probado algún tratamiento?</label>
                    <div>
                      <input style="margin-top: 20px; margin-left: 20px;" type="radio" id="prevYes" value="Yes" v-model="previous">
                      <label style="margin-left: 10px;" for="prevYes">Si</label>
                      <input style="margin-left: 80px;" type="radio" id="prevNo" value="No" v-model="previous">
                      <label style="margin-left: 10px;" for="prevNo">No</label>
                    </div>
                  </div>

                  <div class="mb-3" v-if="previous === 'Yes'">
                    <label>¿Qué tratamientos has utilizado?</label>
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="previousTreatment" placeholder="Ingresa un tratamiento" />
                      <button class="btn btn-primary" type="button" @click="addPreviousTreatment">Agregar</button>
                    </div>
                    <ul class="list-group mt-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center"
                          v-for="(treatment, index) in assessment.treatment_history"
                          :key="index">
                        {{ treatment }}
                        <button type="button" class="btn-close" @click="removePreviousTreatment(index)"></button>
                      </li>
                    </ul>
                  </div>

                  <!-- Current Products -->
                  <div class="mb-3">
                    <label>¿Utilizas productos actualmente?</label>
                    <div>
                      <input style="margin-top: 20px; margin-left: 20px;" type="radio" value="Yes" v-model="current">
                      <label style="margin-left: 10px;">Si</label>
                      <input style="margin-left: 80px;" type="radio" value="No" v-model="current">
                      <label style="margin-left: 10px;">No</label>
                    </div>
                  </div>

                  <div class="mb-3" v-if="current === 'Yes'">
                    <label>¿Qué productos utilizas actualmente?</label>
                    <div class="mb-3">
                      <div class="input-group">
                        <input type="text" class="form-control" v-model="searchQuery" @input="performSearch" placeholder="Ingresa un producto" />
                        <button class="btn btn-primary" type="button" @click="addTreatment(addProduct)">Agregar</button>
                      </div>
                      <div class="dropdown" v-if="searchResults.length > 0 && searchQuery!=''">
                        <div class="dropdown-item" v-for="(drug, index) in searchResults" :key="index" @click="selectProduct(drug)">
                          {{ drug.name }}
                        </div>
                      </div>
                      <ul class="list-group mt-3" v-if="treatment_item!=''">
                        <div class="mb-3 ">
                          <li v-for="(treatment, index) in assessment.current_treatment" :key="index" class="list-group-item d-flex justify-content-between align-items-center rounded">
                            {{ treatment.name }}
                            <button type="button" class="btn-close" @click="removeCurrentTreatment(index)"></button>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>

                  <!-- Budget -->
                  <div class="mb-3">
                    <label>¿Tienes un límite en tu presupuesto?</label>
                    <div>
                      <input style="margin-top: 20px; margin-left: 20px;" type="radio" value="Yes" v-model="limit">
                      <label style="margin-left: 10px;">Si</label>
                      <input style="margin-left: 80px;" type="radio" value="No" v-model="limit">
                      <label style="margin-left: 10px;">No</label>
                    </div>
                  </div>

                  <div class="mb-3" v-if="limit === 'Yes'">
                    <label for="budget">¿Cuál es tu presupuesto?</label>
                    <select class="form-control" v-model="assessment.budget">
                      <option value="">Selecciona un rango</option>
                      <option value="0 - 1500">0 - 1,500 MXN</option>
                      <option value="1500 - 3000">1,500 - 3,000 MXN</option>
                      <option value="3000 or more">3,000 o más</option>
                    </select>
                    <p style="font-size: 10px;">
                      <br> Se recomienda un presupuesto mínimo de entre 1250 y 2000 MXN, pero haremos lo posible por ajustarlo a tus necesidades.
                    </p>
                  </div>

                  <div class="mb-3 d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary">Continuar</button>
                  </div>
                </div>

                <!-- Step 2: Clinical Images -->
                <div v-else-if="step === 2">
                  <h5 class="text-center mb-4">3. Imágenes Clínicas</h5>
                  <div class="stepper">
                    <span class="step" :class="{ active: step === 2 }">1</span>
                    <span class="step" :class="{ active: step === 2 }">2</span>
                    <span class="step" :class="{ active: step === 2 }">3</span>
                  </div>

                  <div class="form-group">
                    <p class="mb-3">Necesitamos al menos dos imágenes de la zona afectada:</p>
                    <p class="mb-3" style="font-size:14px">
                      1. Una imagen cercana (aprox 10 cm)<br>
                      2. Una imagen lejana (aprox 30 cm)
                    </p>
                    <p class="mb-3">Instrucciones y recomendaciones para toma de fotografías:</p>
                    <p class="mb-3" style="font-size:14px">
                      1. Incluye imágenes de todas las zonas afectadas<br>
                      2. Utiliza la cámara trasera de tu smartphone<br>
                      3. De ser necesario pide ayuda a un tercero<br>
                      4. No edites las imágenes<br>
                      5. Deben estar bien iluminadas y enfocadas<br>
                      6. Quita la ropa, los accesorios y el maquillaje de las zonas afectadas<br>
                    </p>

                    <div class="text-center" v-if="imageFiles.length > 0">
                      <label for="images" class="btn btn-primary" style="width:80%">Agregar más imágenes</label>
                      <input class="d-none" type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="handleFileChange('images/')">
                    </div>
                    <div class="text-center" v-else>
                      <label for="images" class="btn btn-primary" style="width:80%">Seleccionar Imágenes</label>
                      <input class="d-none" type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="handleFileChange('images/')">
                    </div>

                    <div class="carousel">
                      <div v-for="(file, index) in imageFiles" :key="index">
                        <img :src="file.thumbnail" v-if="file.thumbnail" />
                        <div class="progress" v-if="file.progress !== undefined">
                          <div class="progress-bar" role="progressbar" :style="{ width: file.progress + '%' }" :aria-valuenow="file.progress" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>

                    <p class="mb-3">Si tienes estudios recientes compártelos (opcional)</p>
                    <div class="text-center" v-if="pdfFiles.length>0">
                      <label for="pdfs" class="btn btn-primary" style="width:80%">Agregar más Exámenes</label>
                      <input class="d-none" type="file" id="pdfs" name="pdfs" accept="application/pdf" multiple ref="pdfsRef" @change="handleFileChange('exams/')">
                    </div>
                    <div class="text-center" v-else>
                      <label for="pdfs" class="btn btn-primary" style="width:80%">Seleccionar Exámenes</label>
                      <input class="d-none" type="file" id="pdfs" name="pdfs" accept="application/pdf" multiple ref="pdfsRef" @change="handleFileChange('exams/')">
                    </div>

                    <ul class="mt-3">
                      <li v-for="(file,index) in pdfFiles" :key="index">{{ file.file.name }} - {{ file.status }}</li>
                    </ul>
                  </div>

                  <div class="mb-3 form-check">
                    <input required class="form-check-input" type="radio" id="TermsYes" value="Yes" v-model="terms" style="margin-top: 20px;">
                    <label style="margin-top: 17px" class="form-check-label" for="TermsYes">
                      Acepto los
                      <a href="https://www.dermapp.com/terminos-y-condiciones" target="_blank">términos y condiciones</a>
                    </label>
                  </div>

                  <div class="mb-3 d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary" :disabled="isUploading">Continuar</button>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Chat Icon -->
    <div class="chat-icon" @click="toggleChat">
      <img src="@/assets/assistant-icon.png" alt="Chat Icon" />
    </div>

    <!-- Chat Window -->
    <div v-if="isChatVisible" class="chat-window">
      <div class="chat-header">
        <h5>Asistente Virtual</h5>
        <button @click="toggleChat" class="close-button">&times;</button>
      </div>
      <div class="chat-body">
        <div v-for="(message, index) in messages" :key="index" :class="message.role">
          <p>{{ message.content }}</p>
        </div>
        <!-- Typing Animation -->
        <div v-if="isTyping" class="assistant typing">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>

      <!-- Chat Footer -->
      <div class="chat-footer" v-if="!isTyping">
        <input type="text" v-model="newMessage" @keyup.enter="sendMessage" placeholder="Escribe tu mensaje..." />
        <button @click="sendMessage">Enviar</button>
      </div>
      <div class="chat-footer disabled" v-else>
        <input type="text" v-model="newMessage" placeholder="Esperando respuesta..." disabled />
        <button disabled>Enviar</button>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import {  mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import algoliasearch from "algoliasearch/lite";
import aa from 'search-insights';
import { ref, onMounted } from "vue";
import { VueTelInput } from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import useFacebookPixel from '@/composables/useFacebookPixel';
import heic2any from 'heic2any';
import Swal from 'sweetalert2';

aa('init', {
  appId: 'Y0B0JKM7OD',
  apiKey: 'b814ff419be43c1b27c93ed61b23b41a',
});

const client = algoliasearch("Y0B0JKM7OD", "b814ff419be43c1b27c93ed61b23b41a");
const index = client.initIndex("Omicron_pharmacy");

export default {
  components: {
    'vue-tel-input': VueTelInput,
  },

  setup() {
    const { track } = useFacebookPixel();
    onMounted(() => {
      track('Lead', { content_name: 'Start Guest Assessment' });
      track('ViewContent', { content_name: 'Create Guest Assessment' });
    });

    const searchQuery = ref("");
    const item = ref("");
    const searchResults = ref([]);
    const treatments = ref([]);
    const treatment_item = ref({
      name: '',
      sku: '',
      sku_magistral: '',
      price: null,
      priceIVA: null,
      indications: [],
      is_minimal: 1,
    });

    const performSearch = async () => {
      if (!searchQuery.value) return;
      try {
        const response = await index.search(searchQuery.value, { clickAnalytics: true });
        searchResults.value = response.hits;
      } catch (error) {
        console.error(error);
      }
    };

    const selectProduct = (product) => {
      item.value = product;
      searchResults.value = [];
      searchQuery.value = product.name;
    };

    const addTreatment = (addProduct) => {
      if (searchQuery.value && item.value!='') {
        treatments.value.push(item.value);
        treatment_item.value.name = item.value.name;
        treatment_item.value.sku = item.value.ean;
        treatment_item.value.sku_magistral = item.value.code;
        treatment_item.value.price = item.value.price;
        treatment_item.value.priceIVA = item.value.price_iva;
      } else {
        treatments.value.push(searchQuery.value);
        treatment_item.value.name = searchQuery.value;
        treatment_item.value.sku = '0000000000';
        treatment_item.value.sku_magistral = '0000000000';
        treatment_item.value.price = 0;
        treatment_item.value.priceIVA = 0;
      }

      addProduct(treatment_item.value);
      searchQuery.value = '';
      item.value = '';
    };

    return {
      searchQuery,
      searchResults,
      performSearch,
      selectProduct,
      item,
      addTreatment,
      treatments,
      treatment_item,
    };
  },

  data() {
    return {
      // Form steps and states
      step: 0,
      isUploading: false,
      isPhoneValid: false,
      terms: 'Yes',
      imageFail: false,
      phoneRetrieved: false,

      // User and Profile Data
      email: '',
      password: 'bienvenido',
      phone: '',
      countryCode: '',
      referral_code: '',
      name:'',
      current_name:'',
      last_name:'',
      current_last_name:'',
      weight:'',
      height:'',
      sex:'',
      birthday: '',
      diseasesHistory:[],
      disease:'',
      allergies:[],
      allergy:'',
      userId: null,
      profileId: null,

      // Assessment Data
      assessmentId: null,
      assessment: {
        profile_id: 0,
        consultation_reason: '',
        symptoms: [],
        locations: [],
        problem_duration: '',
        problem_evolution: '',
        treatment_history: [],
        current_treatment: [],
        budget: '',
        pregnancy_status: '',
      },
      symptom: '',
      location:'',
      changes: '',
      previous: '',
      previousTreatment: '',
      current: '',
      limit: '',

      // Files
      imageFiles: [],
      pdfFiles: [],

      // Chat
      isChatVisible: false,
      thread_id: "new_thread",
      messages: [
        {
          role: 'assistant',
          content: `¡Hola! Soy tu asistente virtual de Dermapp.🩵
          Si tienes una duda sobre el servicio o cómo consultar, no dudes en preguntarme.✨`
        }
      ],
      newMessage: '',
      isTyping: false,

      HeaderLogo: require('@/assets/dermapp-white.png'),
    };
  },

  methods: {

    navigateToDashboard() {
        this.$router
          .push({
            path: '/',
          })
          .catch((err) => {
            // Handle the error if navigation fails (optional)
            if (err.name !== 'NavigationDuplicated') {
              console.error(err);
            }
          });
    },

    ...mapActions([
      'updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 
      'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileLastName', 
      'updateProfileSex', 'updateProfileSubscription', 'updateProfileBirthday'
    ]),

    saveStateToLocalStorage() {
      const state = {
        step: 0,
        email: this.email,
        current_name: this.name,
        name: this.name,
        current_last_name: this.last_name,
        last_name: this.last_name,
        weight: this.weight,
        height: this.height,
        sex: this.sex,
        birthday: this.birthday,
        diseasesHistory: this.diseasesHistory,
        allergies: this.allergies,
        assessment: this.assessment,
        phone: this.phone,
        countryCode: this.countryCode,
        current: this.current,
        previous: this.previous,
        changes: this.changes,
        limit: this.limit,
        userId: this.userId,
        profileId: this.assessment.profile_id,
        assessmentId: this.assessmentId,
        imageFiles: this.imageFiles,
        pdfFiles: this.pdfFiles,
      };
      localStorage.setItem('dermappGuestAssessmentState', JSON.stringify(state));
    },

    restoreStateFromLocalStorage() {
      const savedState = localStorage.getItem('dermappGuestAssessmentState');
      if (savedState) {
        const state = JSON.parse(savedState);
        Object.assign(this, state);
      }
      if(this.phone!=''){
        this.phoneRetrieved = true;
      }
    },

    handleUpdate(details) {
      if (typeof details === 'string') {
        this.phone = details.replace(/\s+/g, '');
        let phoneNumber = parsePhoneNumberFromString(this.phone);
        this.isPhoneValid = phoneNumber && phoneNumber.isValid();
        if (phoneNumber && phoneNumber.isValid()) {
          this.countryCode = phoneNumber.country;
        }
      }
    },

    addDisease() {
      if (this.disease) {
        this.diseasesHistory.push(this.disease);
        this.disease = '';
      }
    },

    removeDisease(index) {
      this.diseasesHistory.splice(index, 1);
    },

    addAllergy() {
      if (this.allergy) {
        this.allergies.push(this.allergy);
        this.allergy = '';
      }
    },

    removeAllergy(index) {
      this.allergies.splice(index, 1);
    },

    addSymptom() {
      if (this.symptom) {
        this.assessment.symptoms.push(this.symptom);
        this.symptom = '';
      }
    },

    removeSymptom(index) {
      this.assessment.symptoms.splice(index, 1);
    },

    addLocation() {
      if (this.location) {
        this.assessment.locations.push(this.location);
        this.location = '';
      }
    },

    removeLocation(index) {
      this.assessment.locations.splice(index, 1);
    },

    addPreviousTreatment() {
      if (this.previousTreatment) {
        this.assessment.treatment_history.push(this.previousTreatment);
        this.previousTreatment = '';
      }
    },

    removePreviousTreatment(index) {
      this.assessment.treatment_history.splice(index, 1);
    },

    addProduct(item){
      let temp_treatment_item = {
        name: item.name,
        sku: item.sku,
        sku_magistral: item.sku_magistral,
        price: item.price,
        priceIVA: item.priceIVA,
        indications: item.indications,
        is_minimal: item.is_minimal,
      }
      this.assessment.current_treatment.push(temp_treatment_item);
      // Reset
      item.name = '';
      item.sku = '';
      item.sku_magistral = '';
      item.price = '';
      item.priceIVA = '';
      item.indications = [];
      item.is_minimal = 1;
    },

    removeCurrentTreatment(index) {
      this.assessment.current_treatment.splice(index, 1);
    },

    async nextStep() {
      if (this.step < 2) {
        if (this.step === 0) {
          if (this.phoneRetrieved) {
            console.log('continue')
          }
          else if(!this.isPhoneValid ){
            Swal.fire('Error', 'Por favor, ingresa un número de teléfono válido', 'error');
            return;
          }
          this.addDisease();
          this.addAllergy();
          // Only create profile if not already created
          if (this.userId == null || this.userId == 0 || this.profileId == null || this.profileId == 0 || this.current_name != this.name || this.current_last_name != this.last_name) {
            await this.createProfile();
          } else {
            this.step++;
          }
          this.saveStateToLocalStorage();
        } else if (this.step === 1) {
          this.addLocation();
          this.addSymptom();
          this.addPreviousTreatment();
          // Only create assessment if not already created
          if (this.assessmentId == null || this.assessmentId === 0) {
            await this.createAssessment();
          } else {
            this.step++;
          }
          this.saveStateToLocalStorage();
        }
      } else {
        // Step 2 done, proceed if not uploading
        if (this.isUploading) {
          alert('Cargando imágenes, por favor espera');
          return;
        }
        this.$router.push({ name: 'GuestPaymentMethod', params: { id: this.assessmentId, password: this.password } });
      }
    },

    generateReferralCode() {
      let datePart = Date.now().toString(36);
      let randomPart = Math.random().toString(36).substr(2, 5);
      return datePart + randomPart;
    },

    async createProfile() {

      try {

        if (this.userId == null || this.userId == 0) {

        this.password = this.name.toLowerCase().split(" ", 1) + "123";
        const user = {
          email: this.email,
          password: this.password,
          phone: this.phone,
          country: this.countryCode,
          referral_code: this.generateReferralCode(),
        };

        const responseUser = await axios.post(`${this.$baseURL}/api/create-guest-user`, user);
        this.userId = responseUser.data.user_id;

        if (responseUser.data.status === 200) {
          const profile = {
            user_id: this.userId,
            name: this.name + ' ' + this.last_name,
            birthday: this.birthday,
            weight: this.weight,
            height: this.height,
            sex: this.sex,
            diseases_history: this.diseasesHistory,
            allergies: this.allergies,
          };

          const responseProfile = await axios.post(`${this.$baseURL}/api/create-profile`, profile);

          if (responseProfile.data.status === 200) {
            this.profileId = responseProfile.data.profile_id;
            this.assessment.profile_id = this.profileId;
            this.step++;

            Swal.fire({
              title: `<h3>¡Hola ${this.name.split(" ", 1)}!<br>Bienvenido a Dermapp</h3>`,
              html: `<div style="text-align: left;">
              <small>usuario: <br>${this.email}<br><br>
              contraseña: <br>${this.password}<br><br>
              Guarda estos datos para ingresar a la plataforma más adelante.</small>
              </div>`,
              icon: 'success',
              confirmButtonText: 'Continuar',
              confirmButtonColor: '#0d6efd',
              showCloseButton: true,
            });

            // Send welcome email
            try {
              let automated_email = {
                to: this.email,
                subject: "Bienvenido a Dermapp!",
                template_name: "https://dermapp-documents.s3.amazonaws.com/templates/account_created.png",
                targetURL: `${this.$baseURLPacientes}/signin`,
              };
              await axios.post(`${this.$baseURL}/api/send-email`, automated_email);
            } catch (emailError) {
              console.error("Error sending welcome email:", emailError);
            }

            await this.signUp();
          }
        } else {
          Swal.fire({
            title: `<h3>¡Hola ${this.name.split(" ", 1)}!<br> Parece que ya tienes una cuenta</h3>`,
            html: '<small style="text-align: center;">Por favor da clic en el botón para ingresar a la plataforma</small>',
            icon: 'error',
            confirmButtonText: 'Iniciar sesión',
            confirmButtonColor: '#0d6efd',
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'SignIn' });
            }
          });
        }  
      } else if (this.profileId == null || this.profileId == 0 || this.current_name != this.name || this.current_last_name != this.last_name) { 
        const profile = {
            user_id: this.userId,
            name: this.name + ' ' + this.last_name,
            birthday: this.birthday,
            weight: this.weight,
            height: this.height,
            sex: this.sex,
            diseases_history: this.diseasesHistory,
            allergies: this.allergies,
          };

          const responseProfile = await axios.post(`${this.$baseURL}/api/create-profile`, profile);

          if (responseProfile.data.status === 200) {
            this.profileId = responseProfile.data.profile_id;
            this.assessmentId = null;
            this.assessment.profile_id = this.profileId;
            this.step++;
          }
      }


      } catch (error) {
        console.error('Error creating user/profile:', error);
      }
    },

    async signUp() {
      try {
        const auth = getAuth();
        const result = await createUserWithEmailAndPassword(auth, this.email, this.password);

        await sendEmailVerification(result.user);
        const user = result.user;
        const idToken = await user.getIdToken();
        localStorage.setItem('Token', idToken);
        localStorage.setItem('Email', this.email);
      } catch (error) {
        console.error('Firebase SignUp Error:', error.message);
      }
    },

    async createAssessment() {
      this.updateEmail(this.email);
      this.updatePhone(this.phone);
      this.updateCountry(this.country);
      this.updateUserId(this.userId);
      this.updateProfileName(this.name.split(' ')[0]);
      this.updateProfileLastName(this.last_name.split(' ')[0]);
      this.updateProfileBirthday(this.birthday);
      this.updateProfileSex(this.sex);

      const updatedAssessment = {
        profile_id: this.profileId,
        consultation_reason: this.assessment.consultation_reason,
        symptoms: this.assessment.symptoms,
        locations: this.assessment.locations,
        problem_evolution: this.assessment.problem_evolution,
        treatment_history: this.assessment.treatment_history,
        current_treatment: this.assessment.current_treatment,
        budget: this.assessment.budget,
        problem_duration: this.assessment.problem_duration,
        pregnancy_status: this.assessment.pregnancy_status,
      };

      try {
        const responseAssessment = await axios.post(`${this.$baseURL}/api/create-assessment`, updatedAssessment);
        if (responseAssessment.status === 200) {
          this.step++;
          this.assessmentId = responseAssessment.data.assessment_id;

          Swal.fire({
            title: `<h3>¡Felicidades ${this.name.split(" ",1)}!<br>Has creado tu primera consulta</h3>`,
            html: `<div style="text-align: left;">
                    <small>Envía las imágenes de tu problema y completa el pago para recibir tu diagnóstico y tu plan de tratamiento.
                    Puedes ingresar a la plataforma con tu usuario y contraseña para editar tu consulta o completar el proceso en cualquier momento.</small>
                   </div>`,
            icon: 'success',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#0d6efd',
            showCloseButton: true,
          });

          // Send case email
          try {
            let automated_email = {
              to: this.email,
              subject: "Recibimos tu consulta dermatológica!",
              template_name: "https://dermapp-documents.s3.amazonaws.com/templates/case_sent.png",
              targetURL: `${this.$baseURLPacientes}/signin`,
            };
            axios.post(`${this.$baseURL}/api/send-email`, automated_email);
          } catch (e) {
            console.error("Error sending case sent email", e);
          }

        } else {
          console.error('Error creating assessment:', responseAssessment);
        }
      } catch (error) {
        console.error('Error creating assessment:', error);
      }
    },

    async handleFileChange(folder) {
      let selectedFiles = [...event.target.files];
      let new_total = folder === 'images/' ? this.imageFiles.length + selectedFiles.length : this.pdfFiles.length + selectedFiles.length;
      if (selectedFiles.length > 0) {
        this.isUploading = true;
      }

      for (let i = 0; i < selectedFiles.length; i++) {
        let file = selectedFiles[i];
        file = await this.checkAndConvertHEIC(file);
        const thumbnail = await this.createThumbnail(file);

        let tempFile = {
          file: file,
          status: 'Subiendo...',
          thumbnail: thumbnail,
          progress: 0
        };

        if (folder === 'images/') {
          this.imageFiles.push(tempFile);
          await this.uploadToS3(this.imageFiles[this.imageFiles.length - 1].file, folder, this.imageFiles.length - 1, new_total);
        } else {
          this.pdfFiles.push(tempFile);
          await this.uploadToS3(this.pdfFiles[this.pdfFiles.length - 1].file, folder, this.pdfFiles.length - 1, new_total);
        }
      }

      this.saveStateToLocalStorage();
    },

    async checkAndConvertHEIC(file) {
      const heicTypes = ['image/heic', 'image/heif', 'image/heic-sequence', 'image/heif-sequence', 'application/octet-stream'];
      const isHeic = heicTypes.includes(file.type) || file.name.toLowerCase().endsWith('.heic') || file.name.toLowerCase().endsWith('.heif');

      if (!isHeic) return file;

      try {
        const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
        return new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: 'image/jpeg' });
      } catch (error) {
        return file;
      }
    },

    async createThumbnail(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = document.createElement('img');
          img.src = e.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = 100;
            canvas.height = 100;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            resolve(canvas.toDataURL('image/jpeg'));
          };
        };
        reader.onerror = (e) => reject(e);
        reader.readAsDataURL(file);
      });
    },

    async uploadToS3(file, folder, position, total) {
      const fileExtension = file.name.split('.').pop();
      const newFileName = `${uuidv4()}.${fileExtension}`;
      const fileName = `${folder}${newFileName}`;
      const destination = 'dermapp-documents';
      const availability = 'public-read';

      const formData = new FormData();
      formData.append('destination', destination);
      formData.append('fileName', fileName);
      formData.append('file', file);
      formData.append('availability', availability);

      try {
        await axios.post(`${this.$baseURL}/api/upload-to-s3`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (folder === 'images/') {
              this.imageFiles[position].progress = percentCompleted;
            } else {
              this.pdfFiles[position].progress = percentCompleted;
            }
          },
        });

        await this.uploadToDB(newFileName, fileExtension, folder, position, total);
      } catch (error) {
        this.imageFail = true;
        this.isUploading = false;
        if (folder === 'images/') {
          this.imageFiles[position].status = 'Cancelado';
        } else {
          this.pdfFiles[position].status = 'Cancelado';
        }
        console.error('Error uploading files to s3:', error);
      }
    },

    async uploadToDB(fileName, fileExtension, folder, position, total) {
      let fileType = folder === 'images/' ? 0 : 1;
      try {
        const files_data = {
          assessment_id: this.assessmentId,
          file_name: fileName,
          file_extension: fileExtension,
          file_type: fileType,
        };
        await axios.post(`${this.$baseURL}/api/assessment-files`, files_data);

        if (folder === 'images/') {
          this.imageFiles[position].status = 'Completado';
        } else {
          this.pdfFiles[position].status = 'Completado';
        }

        if (position + 1 === total) {
          this.isUploading = false;
        }
        this.saveStateToLocalStorage();

      } catch (error) {
        console.error('Error saving file to DB:', error);
      }
    },

    openWhatsapp() {
      let url = 'https://api.whatsapp.com/send?phone=528140052978&text=Hola%2C%20necesito%20ayuda%20para%20enviar%20las%20imagenes%20de%20mi%20consulta';
      window.open(url, '_blank');
    },

    toggleChat() {
      this.isChatVisible = !this.isChatVisible;
    },

    async sendMessage() {
      if (this.newMessage.trim() === '') return;

      this.messages.push({ role: 'user', content: this.newMessage.trim() });
      this.newMessage = '';

      this.$nextTick(() => {
        const chatBody = this.$el.querySelector('.chat-body');
        if (chatBody) chatBody.scrollTop = chatBody.scrollHeight;
      });

      this.isTyping = true;

      try {
        const response = await axios.get(`${this.$baseURL}/api/guest-assessment-assistant`, {
          params: {
            messages: JSON.stringify(this.messages),
            thread_id: this.thread_id,
          },
        });

        if (response.data.success) {
          this.thread_id = response.data.response.thread_id;
          this.messages.push({
            role: "assistant",
            content: response.data.response.message
          });

          let temp_message_thread = {
            thread_id: this.thread_id,
            messages: this.messages
          };
          await axios.post(`${this.$baseURL}/api/create-message-thread`, temp_message_thread);

        } else {
          this.messages.push({ role: "assistant", content: "Lo siento, ocurrió un error al procesar tu solicitud." });
        }
      } catch (error) {
        console.error('Error fetching assistant response:', error);
        this.messages.push({
          role: "assistant",
          content: "Lo siento, ocurrió un error al procesar tu solicitud."
        });
      } finally {
        this.isTyping = false;
        this.$nextTick(() => {
          const chatBody = this.$el.querySelector('.chat-body');
          if (chatBody) chatBody.scrollTop = chatBody.scrollHeight;
        });
      }
    },

    handleInitialChat() {
      setTimeout(() => {
        this.isChatVisible = true;
        setTimeout(() => {
          this.isChatVisible = false;
        }, 3000);
      }, 1000);
    },

  },
  created() {
      this.restoreStateFromLocalStorage();
      this.handleInitialChat();
    },
};
</script>

<style scoped>
/* Position labels on the left (by default labels are stacked, 
   so just ensure proper spacing and a left alignment) */
.mb-3 > label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left !important;
}

/* Chat and stepper styling remain the same from previous code */
/* The rest of your CSS code remains unchanged. */

body {
  background: #38b6ff;
}
.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  background: #36b5ff;
}

.my-confirm-button {
  background-color: #0d6efd !important;
  color: white;
}

.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  width: 20px;
  height: 20px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #E0E0E0;
  display: inline-block;
  font-size: 14px;
  color: #777;
  line-height: 20px;
  text-align: center;
}

.step.active {
  background-color: #3498db;
  color: #fff;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 10px;
  padding: 10px;
}

.carousel img {
  scroll-snap-align: start;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10%;
  border: 2px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.carousel .progress {
  width: 100px;
  height: 5px;
  margin-top: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
  overflow: hidden;
}

.carousel .progress-bar {
  height: 5px;
  background-color: #007bff;
  transition: width 0.4s;
}

/* Chat Styles as above */

.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .6);
}

.chat-icon img {
  width: 60px;
  height: 60px;
}

.chat-window {
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 300px;
  max-height: 400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.chat-header {
  background-color: #0d6efd;
  color: #fff;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header h5 {
  margin: 0;
  font-size: 16px;
}

.close-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f1f1f1;
}

.chat-body .assistant p {
  background-color: #e0e0e0;
  padding: 8px 12px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  align-self: flex-start;
  text-align: left;
}

.chat-body .user p {
  background-color: #0d6efd;
  color: #fff;
  padding: 8px 12px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left:20px;
  align-self: flex-end;
  text-align: left;
}

.chat-body .typing {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 10px;
}

.chat-body .typing .dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #0d6efd;
  border-radius: 50%;
  opacity: 0.4;
  animation: typing 1.4s infinite both;
}

@keyframes typing {
  0% {
    opacity: 0.4;
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(-5px);
  }
  40% {
    opacity: 0.4;
    transform: translateY(0);
  }
  100% {
    opacity: 0.4;
    transform: translateY(0);
  }
}

.chat-footer {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 10px;
}

.chat-footer input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.chat-footer button {
  margin-left: 5px;
  padding: 8px 12px;
  background-color: #0d6efd;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-footer button:hover {
  background-color: #0b5ed7;
}

.chat-footer.disabled input {
  background-color: #e9ecef;
}

.chat-footer.disabled button {
  background-color: #6c757d;
  cursor: not-allowed;
}
</style>
