import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    email: '',
    isSubscribed: 0,
    userId: '',
    credits: 0, 
    country: '',
    phone: '',
    referralCode: '',
    profileId: '',
    profileSex: '',
    profileName: '',
    profileLastName: '',
    profileSubscription: '',
    primaryName: '',
    primaryBirthday: '',
    profileBirthday: '',
  },
  mutations: {
    setPrimaryName(state, primaryName) {
      state.primaryName = primaryName;
    },
    setPrimaryBirthday(state, primaryBirthday) {
      state.primaryBirthday = primaryBirthday;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setSubscriptionStatus(state, subscriptionStatus) {
      state.isSubscribed = subscriptionStatus;
    },
    setCredits(state, credits) {
      state.credits = credits;
    },
    setCountry(state, country) {
      state.country = country;
    },
    setPhone(state, phone) {
      state.phone = phone;
    },
    setReferralCode(state, referralCode) {
      state.referralCode = referralCode;
    },
    setProfileId(state, profileId) {
      state.profileId = profileId;
    },
    setProfileName(state, profileName) {
      state.profileName = profileName;
    },
    setProfileLastName(state, profileLastName) {
      state.profileLastName = profileLastName;
    },
    setProfileSex(state, profileSex) {
      state.profileSex = profileSex;
    },
    setProfileSubscription(state, profileSubscription) {
      state.profileSubscription = profileSubscription;
    },
    setProfileBirthday(state, profileBirthday) {
      state.profileBirthday = profileBirthday;
    },
  },
  actions: {
    updatePrimaryBirthday({ commit }, primaryBirthday) {
      commit('setPrimaryBirthday', primaryBirthday);
    },
    updateprimaryName({ commit }, primaryName) {
      commit('setPrimaryName', primaryName);
    },
    updateEmail({ commit }, email) {
      commit('setEmail', email);
    },
    updateUserId({ commit }, userId) {
      commit('setUserId', userId);
    },
    updateSubscription({ commit }, subscriptionStatus) {
      commit('setSubscriptionStatus', subscriptionStatus);
    },
    updateCredits({ commit }, credits) {
      commit('setCredits', credits);
    },
    updateCountry({ commit }, country) {
      commit('setCountry', country);
    },
    updatePhone({ commit }, phone, country) {
      let digitsOnly = '';

      // Check if phone starts with a '+', remove it
      if (phone.startsWith('+')) {
          digitsOnly = phone.substring(1);
      }
  
      // Check if phone has 10 digits, prefix with '52'
      if (digitsOnly.length === 10) {
              switch(country) {
                  case 'US': digitsOnly = '1' + digitsOnly; break;
                  case 'MX': digitsOnly = '52' + digitsOnly; break;
                  case 'AR': digitsOnly = '54' + digitsOnly; break;
                  case 'BO': digitsOnly = '591' + digitsOnly; break;
                  case 'BR': digitsOnly = '55' + digitsOnly; break;
                  case 'CL': digitsOnly = '56' + digitsOnly; break;
                  case 'CO': digitsOnly = '57' + digitsOnly; break;
                  case 'CR': digitsOnly = '506' + digitsOnly; break;
                  case 'CU': digitsOnly = '53' + digitsOnly; break;
                  case 'DO': digitsOnly = '1' + digitsOnly; break;
                  case 'EC': digitsOnly = '593' + digitsOnly; break;
                  case 'SV': digitsOnly = '503' + digitsOnly; break;
                  case 'GT': digitsOnly = '502' + digitsOnly; break;
                  case 'HN': digitsOnly = '504' + digitsOnly; break;
                  case 'NI': digitsOnly = '505' + digitsOnly; break;
                  case 'PA': digitsOnly = '507' + digitsOnly; break;
                  case 'PY': digitsOnly = '595' + digitsOnly; break;
                  case 'PE': digitsOnly = '51' + digitsOnly; break;
                  case 'PR': digitsOnly = '1' + digitsOnly; break;
                  case 'UY': digitsOnly = '598' + digitsOnly; break;
                  case 'VE': digitsOnly = '58' + digitsOnly; break;
                  default: break;
              }
          } else {
            digitsOnly = phone.slice(-10);
            switch(country) {
              case 'US': digitsOnly = '1' + digitsOnly; break;
              case 'MX': digitsOnly = '52' + digitsOnly; break;
              case 'AR': digitsOnly = '54' + digitsOnly; break;
              case 'BO': digitsOnly = '591' + digitsOnly; break;
              case 'BR': digitsOnly = '55' + digitsOnly; break;
              case 'CL': digitsOnly = '56' + digitsOnly; break;
              case 'CO': digitsOnly = '57' + digitsOnly; break;
              case 'CR': digitsOnly = '506' + digitsOnly; break;
              case 'CU': digitsOnly = '53' + digitsOnly; break;
              case 'DO': digitsOnly = '1' + digitsOnly; break;
              case 'EC': digitsOnly = '593' + digitsOnly; break;
              case 'SV': digitsOnly = '503' + digitsOnly; break;
              case 'GT': digitsOnly = '502' + digitsOnly; break;
              case 'HN': digitsOnly = '504' + digitsOnly; break;
              case 'NI': digitsOnly = '505' + digitsOnly; break;
              case 'PA': digitsOnly = '507' + digitsOnly; break;
              case 'PY': digitsOnly = '595' + digitsOnly; break;
              case 'PE': digitsOnly = '51' + digitsOnly; break;
              case 'PR': digitsOnly = '1' + digitsOnly; break;
              case 'UY': digitsOnly = '598' + digitsOnly; break;
              case 'VE': digitsOnly = '58' + digitsOnly; break;
              default: break;
          }
          }
      commit('setPhone', digitsOnly);
    },
    updateReferralCode({ commit }, referralCode) {
      commit('setReferralCode', referralCode);
    },
    updateProfileId({ commit }, profileId) {
      commit('setProfileId', profileId);
    },
    updateProfileName({ commit }, profileName) {
      commit('setProfileName', profileName);
    },
    updateProfileLastName({ commit }, profileLastName) {
      commit('setProfileLastName', profileLastName);
    },
    updateProfileSex({ commit }, profileSex) {
      commit('setProfileSex', profileSex);
    },
    updateProfileSubscription({ commit }, profileSubscription) {
      commit('setProfileSubscription', profileSubscription);
    },
    updateProfileBirthday({ commit }, profileBirthday) {
      commit('setProfileBirthday', profileBirthday);
    },
    updateSubscriptionStatus({ commit, state }) {
      axios
        .get(`${this.$baseURL}/api/get-user-subscriptions`, {
          params: {
            user_id: state.userId,
          },
        })
        .then((response) => {
          const subscriptionData = response.data.data[0];
          commit('setSubscriptionStatus', subscriptionData.subscription);
        })
        .catch((error) => {
          console.error('Error fetching subscription details:', error);
        });
    },
  },
  getters: {
    getEmail: (state) => state.email,
    getUserId: (state) => state.userId,
    getSubscriptionStatus: (state) => state.isSubscribed,
    getCredits: (state) => state.credits,
    getCountry: (state) => state.country,
    getPhone: (state) => state.phone,
    getReferralCode: (state) => state.referralCode,
    getProfileId: (state) => state.profileId,
    getProfileName: (state) => state.profileName,
    getProfileLastName: (state) => state.profileLastName,
    getProfileSex: (state) => state.profileSex,
    getProfileSubscription: (state) => state.profileSubscription,
    getProfileBirthday: (state) => state.profileBirthday,
    getPrimaryName: (state) => state.primaryName,
    getprimaryBirthday: (state) => state.primaryBirthday,
  },
});
