<!-- src/components/InfoModal.vue -->
<template>
    <div class="modal-overlay" v-if="visible">
      <div class="modal-content">
        <!-- Close Icon -->
        <img
          src="@/assets/exit-icon.webp"
          alt="Cerrar"
          class="close-icon"
          @click="close"
        />
  
        <!-- Modal Header -->
        <h2>{{ title }}</h2>
  
        <!-- Modal Message with v-html -->
        <p v-html="message"></p>
      
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InfoModal',
    props: {
      visible: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        default: 'Información'
      },
      message: {
        type: String,
        required: true
      },
      linkUrl: {
        type: String,
        default: ''
      }
    },
    emits: ['close'],
    methods: {
      close() {
        this.$emit('close');
      },
      openLink() {
        if (this.linkUrl) {
          window.open(this.linkUrl, '_blank', 'noopener,noreferrer');
        }
      }
    }
  };
  </script>
  
  <style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.modal-content {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  text-align: left;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Close Icon Styles */
.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.close-icon:hover {
  opacity: 0.7;
}

h2 {
  margin-bottom: 15px;
  font-size: 1.2em;
  color: #0d80ff;
  text-align: center;
  font-family: 'Comfortaa', sans-serif;
  font-weight: 900;
}

p {
  font-size: 0.9em;
  margin-bottom: 20px;
  color: #333;
}


/* 1. Phones: Max 600px */
@media (max-width: 600px) {
  .modal-content {
    padding: 15px 20px;
    max-width: 90%;
  }

  h2 {
    font-size: 1.1em;
  }

  p {
    font-size: 0.85em;
  }

  .close-icon {
    width: 18px;
    height: 18px;
  }

  .arrow-icon {
    width: 14px;
    height: 14px;
  }
}

/* 2. Tablets: 601px to 1024px */
@media (min-width: 601px) and (max-width: 1024px) {
  .modal-content {
    padding: 18px 25px;
    max-width: 60%;
  }

  h2 {
    font-size: 1.15em;
  }

  p {
    font-size: 0.88em;
  }

  .close-icon {
    width: 19px;
    height: 19px;
  }

  .arrow-icon {
    width: 15px;
    height: 15px;
  }
}

/* 3. Computers: 1025px or more */
@media (min-width: 1025px) {
  .modal-content {
    padding: 25px 35px;
    max-width: 50%;
  }

  h2 {
    font-size: 1.3em;
  }

  p {
    font-size: 1em;
  }

  .close-icon {
    width: 22px;
    height: 22px;
  }

  .arrow-icon {
    width: 18px;
    height: 18px;
  }
}
</style>
