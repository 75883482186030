<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div 
        class="d-flex align-items-center justify-content-between" 
        style="width: 100%; cursor: pointer;"
        @click="navigateToDashboard"
      >
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
      </div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button 
          class="regresar-button" 
          style="margin-left:20px;"
          @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
          Regresar
        </button>
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
        <router-link v-if="isDataLoaded" :to="{ name: 'EditProfile', params: { id: profile.id }}">
          <img :src="Profile" alt="Profile" class="profile" style="height:40px;width:auto;"/>
        </router-link>
      </nav>
    </header>
    <div class="container">
      <div class="row justify-content-center">
        <div v-if="isDataLoaded" class="col-md-8">
          <div class="card mb-3" style="height:88vh">
            <h5 class="text-center mb-2 mt-4">Consultas de {{ profile.name.split(' ')[0] }}</h5>
            <div class="card-body" style="height: 70vh; overflow-y: auto;">
              <div class="card mb-2" v-for="assessment in assessments" :key="assessment.id">
                <div class="card-body">
                  <div>
                    <p v-if="assessment.is_diagnosed == 0" class="card-text">
                      Diagnostico pendiente
                    </p>
                    <p v-else class="card-text">
                      {{ assessment.diagnosis.diagnosis }} 
                    </p>
                  </div> 
                  <div class="row">
                    <div style="text-align: center; width: 100%;">
                      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item" v-for="(image, index) in assessment.images" :key="image.id" :class="{active: index === 0}">
                            <img class="d-block assessment-image rounded" :src="`https://dermapp-documents.s3.amazonaws.com/images/${image.file_name}`" alt="Assessment image">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p v-if="assessment.is_diagnosed == 1 && assessment.diagnosis.followup_date" class="card-text mb-2" style="margin-top:-20px">
                      <br>Próxima consulta<br>
                      <strong style="font-size:18px">{{ formatFollowupDate(assessment) }}</strong>  
                    </p>
                  </div>
                  <div class="stepper mb-2" style="width:80%; margin-left:10%" v-if="assessment.status < 1 && assessment.is_diagnosed == 0">
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 0 && assessment.is_diagnosed == 0 }"></span>
                      <label class="step-label">Consulta<br>creada</label>
                    </div>
                    <span class="step-line" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 0 }"></span>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 0 }"></span>
                      <label class="step-label">Consulta<br>pagada</label>
                    </div>
                    <div class="step-line" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 1 }"></div>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 1 }"></span>
                      <label class="step-label">Esperando<br>diagnostico</label>
                    </div>
                  </div>
                  <div class="stepper mb-2" style="width:80%; margin-left:10%" v-else-if="assessment.status < 2 && assessment.is_diagnosed == 0">
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 0 }"></span>
                      <label class="step-label">Consulta<br>pagada</label>
                    </div>
                    <span class="step-line" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 0 }"></span>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 0 }"></span>
                      <label class="step-label">Esperando<br>diagnostico</label>
                    </div>
                    <div class="step-line" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 1 }"></div>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 1 }"></span>
                      <label class="step-label">Resultado<br>disponible</label>
                    </div>
                  </div>
                  <div class="stepper mb-2" style="width:80%; margin-left:10%" v-else-if="assessment.status < 2 && assessment.is_diagnosed == 1">
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 1 }"></span>
                      <label class="step-label">Esperando<br>diagnostico</label>
                    </div>
                    <span class="step-line" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 1 }"></span>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 1 && assessment.is_diagnosed == 1 }"></span>
                      <label class="step-label">Resultado<br>disponible</label>
                    </div>
                    <div class="step-line" :class="{ active: assessment.status >= 2 && assessment.is_diagnosed == 1 }"></div>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 2 && assessment.is_diagnosed == 1 }"></span>
                      <label class="step-label">Seguimiento<br>creado</label>
                    </div>
                  </div>
                  <div class="stepper mb-2" style="width:80%; margin-left:10%" v-else-if="assessment.status < 3">
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 2 }"></span>
                      <label class="step-label">Seguimiento<br>creado</label>
                    </div>
                    <span class="step-line" :class="{ active: assessment.status >= 2 }"></span>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 2 }"></span>
                      <label class="step-label">Esperando<br>seguimiento</label>
                    </div>
                    <div class="step-line" :class="{ active: assessment.status >= 4 }"></div>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 4 }"></span>
                      <label class="step-label">Seguimiento<br>disponible</label>
                    </div>
                  </div>
                  <div class="stepper mb-2" style="width:80%; margin-left:10%" v-else-if="assessment.status < 5">
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 2 }"></span>
                      <label class="step-label">Seguimiento<br>creado</label>
                    </div>
                    <span class="step-line" :class="{ active: assessment.status >= 3 }"></span>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 3 }"></span>
                      <label class="step-label">Esperando<br>seguimiento</label>
                    </div>
                    <div class="step-line" :class="{ active: assessment.status >= 5 }"></div>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 5 }"></span>
                      <label class="step-label">Seguimiento<br>disponible</label>
                    </div>
                  </div>
                  <div class="stepper mb-2" style="width:80%; margin-left:10%" v-else>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 2 }"></span>
                      <label class="step-label">Seguimiento<br>creado</label>
                    </div>
                    <span class="step-line" :class="{ active: assessment.status >= 3 }"></span>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status >= 3 }"></span>
                      <label class="step-label">Esperando<br>pago</label>
                    </div>
                    <div class="step-line" :class="{ active: assessment.status > 9 }"></div>
                    <div class="step-container">
                      <span class="step" :class="{ active: assessment.status > 9 }"></span>
                      <label class="step-label">Seguimiento<br>disponible</label>
                    </div>
                  </div>
                  <div class="mb-2" style="display: flex; justify-content: space-between; align-items: center; width:80%; margin-left: 10%">
                    <div class="step-container" v-if="(assessment.new_message != 1) || assessment.status == 2 || assessment.status == 4">
                      <router-link :to="{ name: 'AssessmentMessages', params: { id: assessment.id }}">
                        <img :src="Messages" alt="Messages" class="messages" style="height:40px;width:auto;"/>
                      </router-link>                
                      <label class="step-label">Mensajes</label>
                    </div>
                    <div class="step-container" v-else>
                      <router-link :to="{ name: 'AssessmentMessages', params: { id: assessment.id }}">
                        <img :src="NewMessages" alt="NewMessages" class="new-messages" style="height:40px;width:auto;"/>
                      </router-link>               
                      <label class="step-label">Mensajes</label>
                    </div>

                    <div class="step-container" v-if="assessment.is_diagnosed == 1 && assessment.status <= 1 || assessment.is_diagnosed == 1 && assessment.status == 2">
                      <router-link :to="{ name: 'AssessmentDiagnosisNew', params: { id: assessment.id }}">
                        <img :src="Diagnosis" alt="Diagnosis" class="diagnosis" style="height:40px;width:auto;"/>
                      </router-link>              
                      <label class="step-label">Diagnóstico</label>
                    </div>
                    <div class="step-container" v-else-if="assessment.is_diagnosed == 1 && assessment.status == 3 || assessment.is_diagnosed == 1 && assessment.status == 4">
                      <router-link :to="{ name: 'FollowupAssessmentDiagnosisNew', params: { id: assessment.id }}">
                        <img :src="Diagnosis" alt="Diagnosis" class="diagnosis" style="height:40px;width:auto;"/>
                      </router-link>              
                      <label class="step-label">Diagnóstico</label>
                    </div>
                    <div class="step-container" v-else>
                      <img :src="InactiveDiagnosis" alt="InactiveDiagnosis" class="inactive-diagnosis" style="height:40px;width:auto;"/>       
                      <label class="step-label">Diagnóstico</label>
                    </div>

                    <div class="step-container" v-if="assessment.is_diagnosed == 0">
                      <router-link :to="{ name: 'EditAssessment', params: { id: assessment.id }}">
                        <img :src="EditAssessment" alt="EditAssessment" class="edit-assessment" style="height:40px;width:auto;"/>
                      </router-link>            
                      <label class="step-label">Editar</label>
                    </div>
                    <div class="step-container" v-else-if="assessment.is_diagnosed == 1 && (assessment.status == 2 || assessment.status == 4)">
                      <router-link :to="{ name: 'EditFollowupAssessment', params: { id: assessment.id }}">
                        <img :src="EditAssessment" alt="EditAssessment" class="edit-assessment" style="height:40px;width:auto;"/>
                      </router-link>              
                      <label class="step-label">Editar</label>
                    </div>
                    <div class="step-container" v-else-if="assessment.is_diagnosed == 1 && (assessment.status == 6)">
                      <router-link :to="{ name: 'EditFollowupAssessment', params: { id: assessment.id }}">
                        <img :src="EditAssessment" alt="EditAssessment" class="edit-assessment" style="height:40px;width:auto;"/>
                      </router-link>              
                      <label class="step-label">Editar</label>
                    </div>
                    <div class="step-container" v-else-if="assessment.is_diagnosed == 1 && profileSubscription == 0">
                      <router-link :to="{ name: 'PaymentPlan', params: { id: assessment.id }}">
                        <img :src="FollowupAssessment" alt="FollowupAssessment" class="followup-assessment" style="height:40px;width:auto;"/>
                      </router-link>            
                      <label class="step-label">Seguimiento</label>
                    </div>
                    <div class="step-container" v-else-if="assessment.is_diagnosed == 1 && profileSubscription == 1">
                      <router-link :to="{ name: 'CreateFollowupAssessment', params: { id: assessment.id }}">
                        <img :src="FollowupAssessment" alt="FollowupAssessment" class="followup-assessment" style="height:40px;width:auto;"/>
                      </router-link>             
                      <label class="step-label">Seguimiento</label>
                    </div>          
                  </div>
                  <div>
                    <router-link v-if="assessment.status == 0 || assessment.status == 6 || assessment.status == 8" :to="{ name: 'PaymentMethod', params: { id: assessment.id }}" class="btn btn-primary" style="width: 80%;">Pagar consulta</router-link>
                  </div>
                  <div>
                    <p class="mt-2 mb-2" style="font-size:10px">
                      Consulta creada: {{ formatDate(assessment.created_at) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <img @click="addNewAssessment()" :src="AddAssessment" alt="AddAssessment" class="add-assessment" style="height:40px;width:auto;" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import useFacebookPixel from '@/composables/useFacebookPixel';

import { onMounted } from 'vue';

import Swal from 'sweetalert2';

export default {
setup() {
  const { track } = useFacebookPixel();

  onMounted(() => {
    track('ViewContent', { content_name: 'Patient profile' });
  });

  return {};
},
data() {
  return {
    profile_id: null,
    profile: {}, // singular as it's one profile
    assessments: [], // assessments related to this profile
    isDataLoaded: false,
    HeaderLogo: require('@/assets/dermapp-white.png'),
    AddAssessment: require('@/assets/add-icon-blue.png'),
    Profile: require('@/assets/profile-icon.png'),
    Messages: require('@/assets/message-icon.png'),
    NewMessages: require('@/assets/new-message-icon.png'),
    EditAssessment: require('@/assets/edit-assessment-icon.png'),
    FollowupAssessment: require('@/assets/followup-assessment-icon.png'),
    Diagnosis: require('@/assets/diagnosis-icon.png'),
    InactiveDiagnosis: require('@/assets/inactive-diagnosis-icon.png'),
    BackIcon: require('@/assets/arrow-back.png'),
  };
},
computed: {
  ...mapState(['email', 'userId', 'credits', 'profileName', 'profileSubscription']),
},
async mounted() {
  const auth = getAuth();

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      try {
        // Refrescar el token
        const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

        // Actualizar localStorage con el nuevo token
        localStorage.setItem('Token', idToken);
        localStorage.setItem('Email', user.email);
        this.profile_id = this.$route.params.id;
        const token = localStorage.getItem('Token');
        axios.get(`${this.$baseURL}/api/profile-data`, {
          params: {
            profile_id: this.profile_id,
          },
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => {
          this.profile = response.data.data;
          this.updateProfileId(this.profile.id);
          this.updateProfileName(this.profile.name.split(' ')[0]);
          this.updateProfileSex(this.profile.sex);
          this.updateProfileSubscription(this.profile.subscription);
          this.getAssessments();

          this.isDataLoaded = true;
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
      } catch (error) {
        console.error('Error al renovar el token:', error);

        // Si el token está expirado o hay un error de autenticación
        if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
          localStorage.removeItem('Token'); // Limpiar el token expirado
          this.$router.push('/SignIn'); // Redirigir al login
        }
      }
    } else {
      // Si no hay un usuario autenticado, redirigir al login
      this.$router.push('/SignIn'); // Redirigir al login
    }
  });
  // you can now use this id to fetch the specific patient's data
},
methods: {
  navigateToDashboard() {
    if (this.email) {
      this.$router.push({ 
        path: '/patient-dashboard', 
        query: { email: this.email } 
      }).catch(err => {
        // Handle the error if navigation fails (optional)
        if (err.name !== 'NavigationDuplicated') {
          console.error(err);
        }
      });
    } else {
      // Handle the case where email is not available
      console.warn('Email is not defined.');
    }
  },

  formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  },

  formatFollowupDate(assessment) {
    const createdDate = new Date(assessment.diagnosis.created_at);
    let followup_date = JSON.parse(assessment.diagnosis.followup_date); // ensure this is a JSON object
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    if (followup_date.suffix === "Months") {
      createdDate.setMonth(createdDate.getMonth() + parseInt(followup_date.amount));
    } else if (followup_date.suffix === "Weeks") {
      createdDate.setDate(createdDate.getDate() + (parseInt(followup_date.amount) * 7));
    } 

    return createdDate.toLocaleDateString('es-ES', options);
  },

  ...mapActions(['updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription']),

  async getAssessments() {
      const token = localStorage.getItem('Token');
      try {
        const response = await axios.get(`${this.$baseURL}/api/get-assessments`, {
          params: { profile_id: this.profile_id },
          headers: { 'Authorization': `Bearer ${token}` }
        });

        // Initialize assessments with diagnosis
        this.assessments = response.data.data.map(assessment => ({
          ...assessment,
          diagnosis: {}, // Initialize diagnosis
          images: [] // Initialize images
        }));

        if (this.assessments.length == 0) {
          Swal.fire({
            title: `<h3>¡Hola ${this.profileName}!</h3>`,
            html: '<small style="text-align: center;">' +
                  'Envia tu primera consulta por sólo 180 MXN y comienza a mejorar la salud de tu piel con Dermapp' +
                  '</small>',
            confirmButtonText: 'Consultar ahora',
            confirmButtonColor: '#0d6efd',
            showCloseButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.addNewAssessment();
            }
          });
        }

        // Fetch images and diagnosis for each assessment
        for (let assessment of this.assessments) {
          // Fetch images
          try {
            const imageResponse = await axios.get(`${this.$baseURL}/api/get-files`, {
              params: { assessment_id: assessment.id },
              headers: { 'Authorization': `Bearer ${token}` }
            });

            const imageFiles = imageResponse.data.data.filter(file => file.file_type === 0);
            assessment.images = imageFiles;
          } catch (imageError) {
            console.error(`Error fetching images for assessment ID: ${assessment.id}`, imageError);
            // Optionally, handle missing images
          }

          // Fetch diagnosis if diagnosed
          if (assessment.is_diagnosed == 1) {
            try {
              const diagnosisResponse = await axios.get(`${this.$baseURL}/api/diagnosis-data`, {
                params: { assessment_id: assessment.id },
                headers: { 'Authorization': `Bearer ${token}` }
              });

              if (diagnosisResponse.data.data) {
                assessment.diagnosis = diagnosisResponse.data.data;
              } else {
                console.warn(`Diagnosis data missing for assessment ID: ${assessment.id}`);
                assessment.diagnosis = { diagnosis: 'No diagnosis disponible' };
              }

              // Fetch follow-up assessments if applicable
              if (assessment.status > 2) {
                const followupResponse = await axios.get(`${this.$baseURL}/api/get-latest-followup-assessment-v2`, {
                  params: {
                    assessment_id: assessment.id,
                    mode: assessment.status,
                  },
                  headers: { 'Authorization': `Bearer ${token}` }
                });

                assessment.followup_assessment = followupResponse.data.data;

                if (assessment.followup_assessment) {
                  const followupDiagnosisResponse = await axios.get(`${this.$baseURL}/api/followup-diagnosis-data`, {
                    params: {
                      followup_assessment_id: assessment.followup_assessment.id,
                    },      
                    headers: { 'Authorization': `Bearer ${token}` }
                  });

                  if (followupDiagnosisResponse.data.data) {
                    assessment.followup_diagnosis = followupDiagnosisResponse.data.data;
                    assessment.diagnosis.created_at = assessment.followup_diagnosis.created_at;
                    assessment.diagnosis.followup_date = assessment.followup_diagnosis.followup_date;
                  } else {
                    console.warn(`Follow-up diagnosis data missing for assessment ID: ${assessment.id}`);
                  }
                } else {
                  console.warn(`Follow-up assessment missing for assessment ID: ${assessment.id}`);
                }
              }
            } catch (diagnosisError) {
              console.error(`Error fetching diagnosis for assessment ID: ${assessment.id}`, diagnosisError);
              assessment.diagnosis = { diagnosis: 'No diagnosis disponible' };
            }
          }
        }
      } catch (error) {
        console.error('Error fetching assessments:', error);
      }
    },
  // methods to get the profile and assessments
  addNewAssessment() {
    this.$router.push('../create-assessment');
  },
},
};
</script>

<style scoped>
.regresar-button {
padding-left: 10px;
padding-right: 10px;
height: 40px;
width: auto;
margin-right: 20px; /* Ajusta según sea necesario */
border: 2px solid #0D80FF;
border-radius: 10px;
background-color: #0D80FF; /* Color de fondo azul */
color: white;
cursor: pointer;
transition: background-color 0.3s ease; /* Transición suave opcional */
}

.regresar-button:hover {
background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
}

.assessment-image {
width: 80px;    /* Set the width of the image to 40% of the container */
height: 80px;  /* Maintain the aspect ratio */
display: block;
object-fit: cover; /* This will ensure that your images are always centered and cover the element. It might crop some parts of the image if the aspect ratios do not match */
max-width: 100px;
margin: 0 auto; /* Center the image if necessary */
}

.header {
margin-top: -5px;
position: relative;
width: 100%;
height: auto;
background: #36b5ff;
z-index: 1;
}

.nav-item {
margin-right: 20px;
background: none;
border: none;
color: white;
cursor: pointer;
}

.stepper {
display: flex;
justify-content: center;
align-items: center;
}

.step {
width: 20px;
height: 20px;
margin: 0 0px;
border-radius: 50%;
background-color: #E0E0E0; /* gray color for inactive steps */
border-color: #E0E0E0;
display: inline-block;
font-size: 14px;           /* font size for the step number */
color: #777;               /* font color for inactive steps */
line-height: 20px;         /* centers the number vertically */
text-align: center;        /* centers the number horizontally */
z-index: 1;
}

.step.active {
background-color: #36b5ff; /* blue color for the active step */
color: #fff;               /* font color for the active step */
border-color: #36b5ff;
}

.step-label {
margin-top: 5px;
font-size: 10px;
}

.step-line {
flex-grow: 1;
height: 4px;
margin-bottom: 35px;
margin-left: -20px;
margin-right: -20px;
background-color: #E0E0E0;
border-color: #E0E0E0;
}

.step-line.active {
background-color: #36b5ff; /* blue color for the active step */
color: #fff;               /* font color for the active step */
border-color: #36b5ff;
}

.step-container {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

::-webkit-scrollbar {
width: 10px;
}

::-webkit-scrollbar-track {
background: #f1f1f1;
border-radius: 10px;
}

::-webkit-scrollbar-thumb {
background: #888;
border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
background: #555;
}
</style>
