<template>
  <div>
    
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
      </div>
    </header>
    <div class="container d-flex align-items-center" style=" height: 100vh; width: 100vw; flex-direction: column;">
      <div class="card-container" style="max-width: 100%;">
        <div class="card mb-3"> <!-- Add the mb-4 class here for bottom margin -->
          <h5 class="text-center mb-2 mt-4">Crear Usuario</h5>
        <div class="card-body">
          <form @submit.prevent="createProfile()" style="text-align: left;">
            <div class="mb-3" >
              Nombre
                <input type="text" class="form-control" id="name" v-model="name" placeholder="Ingresa tu nombre" required>
              </div>
            <div class="mb-3">
              Email
              <input type="email" class="form-control" id="email" v-model="email" placeholder="Ingresa tu correo" required>
            </div>
            <div class="mb-3">
              Whatsapp
              <vue-tel-input v-model="phone" mode="international" @input="handleUpdate" required></vue-tel-input>
            </div>
            <div class="mb-3">
              Fecha de nacimiento
              <div class="input-group">
                <input type="date" class="form-control" v-model="birthday" required>
              </div>
            </div>
              <div class="mb-3">
                Peso
                <input type="text" class="form-control" id="weight" v-model="weight" placeholder="Ingresa tu peso (kilogramos)" required>
              </div>
              <div class="mb-3">
                Altura
                <input type="text" class="form-control" id="height" v-model="height" placeholder="Ingresa tu altura (metros)" required>
              </div>
              <div class="mb-3">
                Sexo
                <select class="form-control" id="sex" v-model="sex" required>
                  <option value="" disabled selected>Selecciona tu sexo</option>
                  <option value="Male">Masculino</option>
                  <option value="Female">Femenino</option>
                </select>
              </div>
              <div class="mb-3">
        Tienes alguna enfermedad?
        </div>
              <div class="mb-3">
       <div class="input-group">
        <input type="text" class="form-control" id="disease" v-model="disease" placeholder="Ingresa tu padecimiento">
        <button class="btn btn-primary" type="button" @click="addDisease">Agregar</button>
       </div>
        <ul class="list-group mt-3">
        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(disease, index) in diseasesHistory" :key="index">
          {{ disease }}
          <button type="button" class="btn-close" @click="removeDisease(index)"></button>
        </li>
        </ul>
        </div>
        <div class="mb-3">
        Tienes alergia a algun medicamento?
       </div>
          <div class="mb-3">
            <div class="input-group">
              <input type="text" class="form-control" id="allergies" v-model="allergy" placeholder="Ingresa tu alergia">
              <button class="btn btn-primary" type="button" @click="addAllergy">Agregar</button>
            </div>
            <ul class="list-group mt-3">
              <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(allergy, index) in allergies" :key="index">
                {{ allergy }}
                <button type="button" class="btn-close" @click="removeAllergy(index)"></button>
              </li>
            </ul>
          </div>
          <div class="text-center"> <!-- Add this line -->
            <button type="submit" class="btn btn-primary mb-3" style="width: 80%;">Crear usuario</button>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';

import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import { VueTelInput } from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';


export default {
  setup() {
    const { track } = useFacebookPixel();


    onMounted(() => {
      track('CompleteRegistration', { content_name: 'Create User' });
      track('ViewContent', { content_name: 'Create User' });

    });

    return {};
  },
  
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName']),
  },

  data() {
    return {
      email: '',
      phone: '',
      countryCode: '',
      referral_code: '',
      profile_id: '',
      name:'',
      weight:'',
      height:'',
      sex:'',
      birthday: '',
      year:'',
      month:'',
      day:'',
      diseasesHistory:["Diabetes", "Sobrepeso"],
      disease:'',
      allergies:["Ninguna"],
      allergy:'',
      HeaderLogo: require('@/assets/dermapp-white.png')
    };
  },

  components: {
    VueTelInput,
  },

  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });


},
  
  created() {
    // Get email from route parameters and assign it to the email data property
    this.email = this.$route.params.email;
  },
  methods: {
    
    ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),


    addDisease() {
      if (this.disease) {
        this.diseasesHistory.push(this.disease);
        this.disease = '';
      }
    },

    addAllergy() {
      if (this.allergy) {
        this.allergies.push(this.allergy);
        this.allergy = '';
      }
    },

    handleUpdate(details) {
  if (typeof details === 'string') {
    this.phone = details.replace(/\s+/g, '');
    let phoneNumber = parsePhoneNumberFromString(details.replace(/\s+/g, ''));
    if (!phoneNumber) {
    return null;
  }
    this.countryCode = phoneNumber.country;

  }
},

    removeDisease(index) {
      this.diseasesHistory.splice(index, 1);
    },

    removeAllergy(index) {
      this.allergies.splice(index, 1);
    },

    generateReferralCode() {
      let datePart = Date.now().toString(36);  // unique part based on current time
      let randomPart = Math.random().toString(36).substr(2, 5); // 5 random characters
      return datePart + randomPart;
    },

    formatYear() {
        if (this.year >= 0 && this.year <= 99) {
            if (this.year < 33) {  // Assuming years < 33 belong to 2000s. Adjust based on your use case.
                return '20' + this.padZero(this.year);
            } else {
                return '19' + this.padZero(this.year);
            }
        }
        return this.year;  // Assumes year is already in YYYY format.
    },

    padZero(num) {
        return num.toString().padStart(2, '0');
    },

    async createProfile() {

      
      try {
        const user = {
          email: this.email,
          phone: this.phone,
          country: this.countryCode,
          referral_code: this.generateReferralCode()  // use the new function here
          
        };

        const responseUser = await axios.post(`${this.$baseURL}/api/create-user`, user);
        
        const profile = {
          user_id: responseUser.data.user_id,
          name: this.name,
          birthday: this.birthday,
          weight: this.weight,
          height: this.height,
          sex: this.sex,
          diseases_history: this.diseasesHistory,
          allergies: this.allergies,
        }


        const responseProfile = await axios.post(`${this.$baseURL}/api/create-profile`, profile);

        this.profile_id = responseProfile.data.profile_id;

        this.updateEmail(this.email);
        this.updatePhone(this.phone);
        this.updateUserId(responseUser.data.user_id);
        this.updateProfileId(this.profile_id);
        this.updateProfileName(this.name.split(' ')[0]);

        const userSubs = {
            user_id: responseUser.data.user_id,
            subscription: 0,
            subscription_tier: null,
            stripe_customer_id: null,
            subscribed_profiles: null,
            profiles_limit: null,
            consultation_credits: null,
            next_charge_date: null,
          };

          
          
        const responseSubs = await axios.post(`${this.$baseURL}/api/user-subscription`, userSubs);


        if(responseUser.status === 200 && responseProfile.status === 200 && responseSubs.status === 200) {
          try{
            let whatsapp = {
              phone: this.phone,
              template_name: 'account_created',
              components: [
                {
                    "type": "body",
                    "parameters": [{
                        "type": "text",
                        "text": this.name.split(' ')[0]
                    },
                    ]
                }
              ],
              }
              axios.post(`${this.$baseURL}/api/send-whatsapp`, whatsapp);
            }catch{
              console.error("Error sending purchase success whatsapp");
            }
            try{
            let automated_email = {
              to: this.email,
              subject: "Bienvenido a Dermapp!",
              template_name: "https://dermapp-documents.s3.amazonaws.com/templates/account_created.png",
              targetURL: `${this.$baseURLPacientes}/signin`,
              }
              axios.post(`${this.$baseURL}/api/send-email`, automated_email);
            }catch{
              console.error("Error sending case sent email");
            }
          this.$router.push('/patient-dashboard');
        }
      } catch (error) {
        console.error('Error creating user:', error);
      }
    },
  },
};
</script>



<style>
body {
  background: #38b6ff;
}

.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}
</style>




         
