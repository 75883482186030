<template>
    <div>
      <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
        <div 
  class="d-flex align-items-center justify-content-between" 
  style="width: 100%; cursor: pointer;"
  @click="navigateToDashboard"
>
  <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
</div>
        <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
          <button 
            class="regresar-button" 
            style="margin-left:20px;"
            @click="$router.back();">
            <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
           Regresar
          </button>
          <button 
            class="nav-item" 
            style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
            @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
            Créditos: {{ credits }}
          </button>
        </nav>
      </header>
      <div class="container d-flex align-items-center" style="min-height: 100vh; width: 100vw; flex-direction: column;">
        <div class="card-container" style="width: 400px; max-width: 100%;">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="text-center mb-2 mt-4">Editar Perfil</h5>
            <form @submit.prevent="editProfile">
            <div style="text-align: left;">
                <div class="mb-3">
                <label for="name">Nombre y apellido</label>
                <input type="text" class="form-control" id="name" v-model="profile.name">
              </div>
              <div class="mb-3">
                Fecha de nacimiento
                <div class="input-group">
                  <input type="day" class="form-control" id="day" v-model="profile.day" placeholder="DD" required>
                  <input type="month " class="form-control" id="month" v-model="profile.month" placeholder="MM" required>
                  <input type="year" class="form-control" id="year" v-model="profile.year" placeholder="AAAA" required>
                </div>              
              </div>
              <div class="mb-3">
                <label for="weight">Peso (kilogramos)</label>
                <input type="text" class="form-control" id="weight" v-model="profile.weight">
              </div>
              <div class="mb-3">
                <label for="height">Altura (metros)</label>
                <input type="text" class="form-control" id="height" v-model="profile.height">
              </div>
              <div class="mb-3">
                <label for="sex">Sexo</label>
                <select class="form-control" id="sex" v-model="profile.sex">
                  <option value="">Seleccionar sexo</option>
                  <option value="Male">Masculino</option>
                  <option value="Female">Femenino</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="disease">Padecimientos</label>

        <div class="input-group">
          <input type="text" class="form-control" id="disease" v-model="disease" placeholder="Ingresa tu padecimiento">
          <button class="btn btn-primary" type="button" @click="addDisease">Agregar</button>
        </div>
        <ul class="list-group mt-3">
          <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(disease, index) in profile.diseases_history" :key="index">
            {{ disease }}
            <button type="button" class="btn-close" @click="removeDisease(index)"></button>
          </li>
        </ul>
      </div>
      <div class="mb-3">
        <label for="allergy">Alergias</label>

        <div class="input-group">
          <input type="text" class="form-control" id="allergy" v-model="allergy" placeholder="Ingresa tu alergia">
          <button class="btn btn-primary" type="button" @click="addAllergy">Agregar</button>
        </div>
        <ul class="list-group mt-3">
          <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(allergy, index) in profile.allergies" :key="index">
            {{ allergy }}
            <button type="button" class="btn-close" @click="removeAllergy(index)"></button>
          </li>
        </ul>
      </div>
            </div>
              <div>
                <button type="submit" class="btn btn-primary" style="width: 80%;">Actualizar perfil</button>
              </div>
              
              <!-- Rest of the fields similar to the 'CreateProfile' form fields -->
            </form>
            <div>
              <button v-if="this.profileSubscription==1" @click="cancelSubscription" class="btn btn-danger" style="width: 80%; margin-top:10px">Cancelar suscripcion</button>
              <router-link v-if="this.profileSubscription==0" :to="{ name: 'PaymentPlans', params: { id: profileId }}" class="btn btn-primary" style="width: 80%; margin-top:10px">Suscribirme</router-link>
           
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
  import axios from 'axios';
  import { mapState, mapActions } from 'vuex';
  import Swal from 'sweetalert2';

  
  export default {
    data() {
      // Initial state
      return {
        // Add initial profile object to fill the form
        profile: {
          referral_code: '',
          name:'',
          weight:'',
          height:'',
          sex:'',
          birthday: '',
          diseases_history:[],
          allergies:[],
          year: '',
        month: '',
        day: '',
        },
        disease:'',
        allergy:'',
        HeaderLogo: require('@/assets/dermapp-white.png'),
        BackIcon: require('@/assets/arrow-back.png'),

      };
    },
    computed: {
      ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName','profileSubscription' ,'primaryName', 'primaryBirthday']),
    },
    methods: {
      navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },

      ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),

      async cancelSubscription() {
  Swal.fire({
    title: '¿Estás seguro?',
    text: "¿Realmente deseas cancelar tu suscripción?",
    icon: 'warning',
    showCancelButton: true,
  confirmButtonText: 'Cancelar suscripción',
  cancelButtonText: 'Mantener suscripción',
  cancelButtonColor: '#0d6efd',
  confirmButtonColor: '#0d6efd',
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        let updateSubscription = {
          stripeCustomerId: this.profile.stripe_customer_id
        };
        const responseProfile = await axios.put(`${this.$baseURL}/api/cancel-stripe-subscription`, updateSubscription);

        if (responseProfile.status === 200) {
          this.updateProfileSubscription(0);
          this.profileSubscription = 0;
          
          Swal.fire({
            title: '¡Cancelado!',
            text: 'La suscripción ha sido cancelada exitosamente.',
            icon: 'success',
            confirmButtonColor: '#0d6efd',
            confirmButtonText: 'Aceptar'
          });
        }
      } catch (error) {
        console.error('Error cancelando la suscripción:', error);
        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al cancelar la suscripción. Por favor, inténtalo de nuevo más tarde.',
          icon: 'error',
          confirmButtonColor: '#0d6efd',
          confirmButtonText: 'Aceptar'
        });
      }
    }
  });
},


      formatYear() {
        if (this.profile.year >= 0 && this.profile.year <= 99) {
            if (this.year < 33) {  // Assuming years < 33 belong to 2000s. Adjust based on your use case.
                return '20' + this.padZero(this.profile.year);
            } else {
                return '19' + this.padZero(this.profile.year);
            }
        }
        return this.profile.year;  // Assumes year is already in YYYY format.
    },

    padZero(num) {
        return num.toString().padStart(2, '0');
    },

          // More methods
          addDisease() {
        if (this.disease) {
          this.profile.diseases_history.push(this.disease);
          this.disease = '';
        }
      },
      removeDisease(index) {
        this.profile.diseases_history.splice(index, 1);
      },
      addAllergy() {
        if (this.allergy) {
          this.profile.allergies.push(this.allergy);
          this.allergy = '';
        }
      },
      removeAllergy(index) {
        this.profile.allergies.splice(index, 1);
      },
      async editProfile() {
        try {

          let formattedDate = `${this.formatYear()}-${this.padZero(this.profile.month)}-${this.padZero(this.profile.day)}`;  

          const updatedProfile = {
            profile_id: this.$route.params.id,
            user_id: this.userId,
            name: this.profile.name,
            birthday: formattedDate,
            weight: this.profile.weight,
            height: this.profile.height,
            sex: this.profile.sex,
            diseases_history: this.profile.diseases_history,
            allergies: this.profile.allergies,
          }

          const responseProfile = await axios.put(`${this.$baseURL}/api/update-profile`, updatedProfile);

          if(responseProfile.status === 200) {
            this.$router.push({ name: 'PatientProfile', params: { id: updatedProfile.profile_id } });
          }
        } catch (error) {
          console.error('Error updating profile:', error);
        }
      },
    },
    mounted() {

    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
          this.profile_id = this.$route.params.id;
  const token = localStorage.getItem('Token');
  axios.get(`${this.$baseURL}/api/profile-data`, {
      params: {
        profile_id: this.profile_id,
        user_id: this.userId,
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      this.profile = response.data.data;

      if (this.profile.diseases_history!=null) {
    this.profile.diseases_history = JSON.parse(this.profile.diseases_history);
} else {
    this.profile.diseases_histroy = [];
}

if (this.profile.allergies!=null) {
    this.profile.allergies = JSON.parse(this.profile.allergies);
} else {
    this.profile.allergies = [];
}

const date = new Date(this.profile.birthday);
this.profile.year = date.getFullYear(); // Extracts the year
this.profile.month = date.getMonth() + 1; // Extracts the month (getMonth() returns 0-11, so add 1)
this.profile.day = date.getDate() + 1; // Extracts the day

      this.isDataLoaded = true;
      })
    .catch((error) => {
      console.error("Error fetching profile data:", error);
    });
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });

  
  // you can now use this id to fetch the specific patient's data
},
    components: {
    },
  }
</script>

<style scoped>
.regresar-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: auto;
  margin-right: 20px; /* Ajusta según sea necesario */
  border: 2px solid #0D80FF;
  border-radius: 10px;
  background-color: #0D80FF; /* Color de fondo azul */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave opcional */
}

.regresar-button:hover {
  background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
}


body {
  background: #38b6ff;
}
.header {
    margin-top: -5px;
    position: relative;
    width: 100%;
    height: auto;
    background: #36b5ff;
    z-index: 1;
  }

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

  </style>

