<template>
  <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
    <div 
class="d-flex align-items-center justify-content-between" 
style="width: 100%; cursor: pointer;"
@click="navigateToDashboard"
>
<img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
</div>
    <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
      <button 
        class="regresar-button" 
        style="margin-left:20px;"
        @click="$router.back();">
        <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
       Regresar
      </button>
      <button 
        class="nav-item" 
        style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
        @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
        Créditos: {{ credits }}
      </button>
    </nav>
  </header>
  <div>
       
    <div class="container">
      <div class="row justify-content-center">
        <div v-if="isDataLoaded" class="col-md-8">
          <div class="card mb-3" style="height: 91vh;">
            <h5 class="text-center mb-2 mt-4">Mis Resultados</h5>
            <div class="card-body" style="overflow-y: auto; overflow-x:hidden; margin-top:10px">

              <div style="display: flex; justify-content: center; flex-wrap: wrap;">
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-right:3%" @click="showExplanation">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/explanation-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Explicación<br>del diagnóstico</div>
                  </div>
                </div>
                <div class="card mb-2" style="height: 20%; max-width: 46%; cursor: pointer; margin-left:3%" @click="showPrognosis">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/prognosis-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px ">¿Qué cambios <br> veremos?</div>
                  </div>                  
                </div>
              </div>

              <div style="display: flex; justify-content: center; flex-wrap: wrap; margin-top:5px;">
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-right:3%" @click="showRecommendations">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/recommendations-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Tips<br> generales</div>
                  </div>
                </div>
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-left:3%" @click="showFollowup">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/next-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Próximas <br> consultas</div>
                  </div>                  
                </div>
              </div>

              <div style="display: flex; justify-content: center; flex-wrap: wrap; margin-top:5px;">
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-right:3%" @click="showTreatment">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/plan-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Plan de<br> tratamiento</div>
                  </div>
                </div>
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-left:3%" @click="showPurchase">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/purchase-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Cotiza tu<br> tratamiento</div>
                  </div>                  
                </div>
              </div>

              <div style="display: flex; justify-content: center; flex-wrap: wrap; margin-top:5px;">
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-right:3%" @click="showExams">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/exams-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Exámenes<br> médicos</div>                  
                  </div>
                </div>
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-left:3%" @click="showRating">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/rate-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">¡Ayudanos<br>a mejorar!</div>
                  </div>                  
                </div>
              </div>

              <div style="display: flex; justify-content: center; flex-wrap: wrap; margin-top:5px;">
                <div v-if="assessment.new_message != 1" class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-right:3%" @click="toAssessmentMessages">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/comments-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Contacta a<br>tu dermatólogo</div>                  
                  </div>
                </div>
                <div v-else class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-right:3%" @click="toAssessmentMessages">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/comments-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Contacta a<br>tu dermatólogo</div>                  
                  </div>
                </div>
                <div v-if="new_user_message != 1" class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-left:3%" @click="toUserMessages">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/message-icon-flat.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Contacta a<br> soporte</div>
                  </div>                  
                </div>
                <div v-else class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-left:3%" @click="toUserMessages">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/new-message-icon-flat.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Contacta a<br> soporte</div>
                  </div>                  
                </div>
              </div>
              <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                <div style="text-align: left; margin-left:10px">
                  <p style="font-size:12px; margin-bottom:0">Hora y fecha de emisión<br>{{ getDiagnosisTime(0) }} {{ getDiagnosisTime(1) }}</p>
                  
                </div>
                <div style="text-align: right; margin-right:10px; font-size:12px;">
                  {{ getDermatologist(0) }}<br>
                  {{ getDermatologist(1) }}<br>
                  {{ getDermatologist(2) }}<br>
                </div>
              </div>
              
                
            </div>
          </div>
                      
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import Swal from 'sweetalert2';
import { createApp, h } from 'vue';
import useFacebookPixel from '@/composables/useFacebookPixel';
import { onMounted } from 'vue';

export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('InitiateCheckout', { content_name: 'First Treatment' });
      track('ViewContent', { content_name: 'Assessment' });
    });

    return {};
  }, 

  data() {
    return {
      has_shipping: [{has_shipping:0}],
      custom_total: null,
      pharmacy_total: null,
      formulas_total: null,
      new_user_message: null,

      rating: 0,
      review_comment: '',
      diagnosisId: null,
      assessment: {},
      diagnosis: {},
      treatment_plan: [],
      extended_treatment_plan: [],

      isDataLoaded: false,

      HeaderLogo: require('@/assets/dermapp-white.png'),
      BackIcon: require('@/assets/arrow-back.png'),

    };
  },
  
  computed: {
    ...mapState([
      'email', 
      'userId', 
      'isSubscribed', 
      'credits', 
      'country', 
      'phone', 
      'referralCode', 
      'profileId', 
      'profileSex', 
      'primaryName', 
      'profileName',
      'primaryBirthday', 
      'profileSubscription'
    ]),
  },

  mounted() {
    /* const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refresh the token
          const idToken = await user.getIdToken(true);
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
          // Fetch the assessment data */
          try{
          axios.get(`${this.$baseURL}/api/assessment-data-free`, {
            params: { assessment_id: this.$route.params.id }
          }).then((assessmentResponse) => {
            this.assessment = assessmentResponse.data.data;

            // Fetch the diagnosis data
            axios.get(`${this.$baseURL}/api/diagnosis-data-free`, {
              params: { assessment_id: this.$route.params.id }
            }).then((diagnosisResponse) => {
              this.diagnosis = diagnosisResponse.data.data;
            this.getDiagnosisTreatment();
            });
          });       
        } catch (error) {
          console.error('Error:', error);

          /* // Handle token expiration or authentication errors
          if (
            error.code === 'auth/id-token-expired' || 
            error.code === 'auth/user-token-expired' || 
            (error.message && error.message.includes('Token'))
          ) {
            localStorage.removeItem('Token');
            this.$router.push('/SignIn');
          } */
        }
/*       } else {
        // Redirect to login if not authenticated
        this.$router.push('/SignIn');
      }
    }); */

    // Fetch user data related to the assessment
    axios.get(`${this.$baseURL}/api/user-by-assessment`, {
      params: { assessment_id: this.$route.params.id },
    })
    .then((response) => {
      const data = response.data.data;
      this.updateUserId(data.user.id);
      this.updateEmail(data.user.email);
      this.updatePhone(data.user.phone, data.user.country);
      this.updateCountry(data.user.country);
      this.updateCredits(data.user.credits);
      this.updateReferralCode(data.user.referral_code);
      this.updateProfileId(data.profile.id);
      this.updateProfileName(data.profile.name.split(' ')[0]);
      this.updateProfileSex(data.profile.sex);
      this.updateProfileSubscription(data.profile.subscription);

      this.new_user_message = data.user.new_message;
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
  },

  methods: {
    getDiagnosisTime(mode) {
             // Step 1: Format the Creation Date and Time
// Assuming this code is within a Vue component or similar context where `this` is accessible

// Parse the created_at datetime
const createdAt = new Date(this.diagnosis.created_at);

// Format the date (e.g., "24/11/2024")
const formattedDate = createdAt.toLocaleDateString('es-ES');

// Format the time to "2:11 AM" or "2:11 PM"
const formattedTime = createdAt.toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  hour12: true
});

switch(mode) {
  case 0: return formattedDate;
  case 1: return formattedTime;
}
    },

    getDermatologist(mode) {
      let dermatologist = {
  name: '',
  code: '',
  title: '',
};

switch(this.diagnosis.assigned_admin_id) {
  case 16: dermatologist.name = 'Dra Dulce de la Torre Trueba'; dermatologist.title='Médica Cirujana Dermatóloga'; dermatologist.code='3509343'; break;
  case 24: dermatologist.name = 'Dra Monica Davalos Tanaka'; dermatologist.title='Médica Cirujana Dermatóloga Pediátrica'; dermatologist.code='3785407'; break;
  default: dermatologist.name = '';
}

switch(mode) {
  case 0: return dermatologist.name;
  case 1: return dermatologist.title;
  case 2: return dermatologist.code;
} 
    },
    navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },
    ...mapActions([
      'updateUserId', 
      'updateEmail', 
      'updatePhone', 
      'updateCountry', 
      'updateCredits', 
      'updateReferralCode', 
      'updateProfileId', 
      'updateProfileName', 
      'updateProfileSex', 
      'updateProfileSubscription'
    ]),

// Display Explanation of Diagnosis
showExplanation() {
  try {
    // Parse the diagnosis comments from JSON
    let diagnosisComments = JSON.parse(this.diagnosis.comments);

    // Start constructing the HTML content
    let htmlContent = `
      <!-- Diagnóstico -->
      <div style="text-align: center; margin-bottom: 15px;">
        <p>Diagnóstico</p>
        <strong>
          ${this.diagnosis.diagnosis}
        </strong>
      </div>
    `;

    // Conditionally add the "Nombre del Paciente" section if 'signs' has content
    if (diagnosisComments.signs && diagnosisComments.signs.trim().length > 100) {
      htmlContent += `
        <!-- Nombre del Paciente (Aligned Left) -->
        <div style="text-align: left; margin-bottom: 15px;">
          <div>
            Hola ${this.profileName},
          </div>
          <div>
            ${diagnosisComments.signs}
          </div>
        </div>
      `;
    }

    // Conditionally add the "Factores Provocadores" section if 'cause' has content
    if (diagnosisComments.cause && diagnosisComments.cause.trim().length > 0) {
      htmlContent += `
        <!-- Factores Provocadores -->
        <div style="text-align: left; margin-bottom: 15px;">
          <p style="background-color: #38B6FF; border-radius: 8px; color: white; padding: 5px 10px; display: inline-block;">¿Qué pudo provocarlo?</p>
          <div>
            ${diagnosisComments.cause}
          </div>
        </div>
      `;
    }

    // Conditionally add the "Plan de Acción" section if 'plan' has content
    if (diagnosisComments.plan && diagnosisComments.plan.trim().length > 0) {
      htmlContent += `
        <!-- Plan de Acción -->
        <div style="text-align: left; margin-bottom: 15px;">
          <p style="background-color: #38B6FF; border-radius: 8px; color: white; padding: 5px 10px; display: inline-block;">Plan de acción</p>
          <div>
            ${diagnosisComments.plan}
          </div>
        </div>
      `;
    }

    // Display the Swal.fire alert with the constructed HTML content
    Swal.fire({
      html: htmlContent,
      confirmButtonText: 'Regresar',
      confirmButtonColor: '#0d6efd',
      showCloseButton: true,
      width: '600px', // Optional: Adjust the width as needed
      // Optional: Add custom styling or other Swal.fire options here
    });

  } catch (error) {
    console.error('Error parsing diagnosis comments:', error);
    // Fallback Swal.fire alert in case of an error
    Swal.fire({
      title: this.diagnosis.diagnosis,
      html: `
        <div style="text-align: left; max-height: 75vh; overflow-y: auto; overflow-x: hidden;">
          ${this.diagnosis.comments}<br><br>${this.diagnosis.explanation}
        </div>
      `,
      confirmButtonText: 'Regresar',
      confirmButtonColor: '#0d6efd',
      showCloseButton: true,
    });
  }
},


    // Display Prognosis
    showPrognosis() {

      try{
        let diagnosisComments = JSON.parse(this.diagnosis.comments);
        diagnosisComments = "";
        console.log(diagnosisComments);
// Step 2: Construct the Swal.fire Alert with Styles
Swal.fire({
  
  html: `
   
    <!-- Observaciones en las Fotografías -->
    <div style="text-align: left; margin-bottom: 15px;">
      <p style="background-color: #38B6FF; border-radius: 8px; color: white; padding: 5px 10px; display: inline-block;">Sobre tu diagnóstico</p>
      <div>
        ${this.diagnosis.explanation}
      </div>
    </div>
    
    <!-- Afección Temporal o Crónica -->
    <div style="text-align: left; margin-bottom: 15px;">
      <p style="background-color: #38B6FF; border-radius: 8px; color: white; padding: 5px 10px; display: inline-block;">¿Qué cambios veremos?</p>
      <div>
        ${this.diagnosis.prognosis}
      </div>
    </div>
    
  `,
  confirmButtonText: 'Regresar',
  confirmButtonColor: '#0d6efd',
  showCloseButton: true,
});

      } catch {
        Swal.fire({
        title: '¿Qué cambios veremos?',
        html: `<div style="text-align: left; max-height: 75vh; overflow-y: auto; overflow-x:hidden">
                ${this.diagnosis.prognosis}
              </div>`,
        confirmButtonText: 'Regresar',
        confirmButtonColor: '#0d6efd',
        showCloseButton: true,
      });
      }

      
    },

    // Display Follow-up Information
    showFollowup() {
      Swal.fire({
        title: '¿Cómo daremos seguimiento a tu problema?',
        html: `<div style="text-align: left; max-height: 75vh; overflow-y: auto; overflow-x:hidden">
                ${this.diagnosis.followup}
              </div>
              <strong style="font-size:24px;"> Próxima consulta:<br>${this.formatFollowupDate()}</strong><br><br>
              <p style="text-align:left; font-size:10px">Se considera un plazo de 2 semanas para adquirir el tratamiento por tiempos de produccion y envio. En caso de que adquiera su tratamiento antes puede adelantar su consutla. </p>`,
        showCloseButton: true,
        showDenyButton: true,
        showConfirmButton: true,
        denyButtonText: 'Regresar',
        denyButtonColor: '#0d6efd',
        confirmButtonText: 'Consulta de seguimiento',
        confirmButtonColor: '#0d6efd',
        didOpen: () => {
          const confirmButton = document.querySelector('.swal2-confirm');
          const denyButton = document.querySelector('.swal2-deny');
          confirmButton.parentNode.insertBefore(denyButton, confirmButton);
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.createFollowupAssessment();
        }
      });
    },

    // Navigate to Create Follow-up Assessment
    createFollowupAssessment() {
      this.$router.push(`/create-followup-assessment/${this.assessment.id}`);
    },

    // Navigate to Assessment Messages
    toAssessmentMessages() {
      this.$router.push(`/assessment-messages/${this.assessment.id}`);
    },

    // Navigate to User Messages
    toUserMessages() {
      this.$router.push(`/user-messages/${this.userId}`);
    },

    // Display Treatment Plan
    async showTreatment() {
      const { default: TreatmentPlan } = await import('./TreatmentPlan.vue');

      Swal.fire({
        title: 'Plan de tratamiento',
        html: '<div id="treatment-plan-container"></div>',
        width: '100%',
        showCloseButton: true,
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: 'Regresar',
        denyButtonColor: '#0d6efd',
        confirmButtonText: 'Cotizar Tratamiento',
        confirmButtonColor: '#0d6efd',
        didOpen: () => {
          const confirmButton = document.querySelector('.swal2-confirm');
          const denyButton = document.querySelector('.swal2-deny');
          confirmButton.parentNode.insertBefore(denyButton, confirmButton);
          
          const app = createApp({
            render: () => 
              h(TreatmentPlan, {
                treatment_plan: this.treatment_plan,
                extended_treatment_plan: this.extended_treatment_plan,
              }),
          });
          app.mount('#treatment-plan-container');
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.showPurchase();
        }
  });
},

async showPurchase() {

  const { default: QuotePlan } = await import('./QuotePlan.vue');

  Swal.fire({
    html: '<div id="quote-plan-container"></div>',
    width: '100%',  // Set width to 95%
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    showDenyButton: false,
    showConfirmButton: false,

    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    customClass: {
      popup: 'custom-swal-popup',
      htmlContainer: 'custom-swal-html-container',
    },
    backdrop: '#0d80ff', 

    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);
      
      const app = createApp({
        render: () =>
          h(QuotePlan, {
            treatment_plan: this.treatment_plan,
            extended_treatment_plan: this.extended_treatment_plan,
            assessmentId: this.assessment.id,
            has_shipping: this.has_shipping,
            onCheckout: this.createPharmacyCheckout,
          }),
      });
      app.mount('#quote-plan-container');
    },
   });
  },

    // Display Exams
    async showExams() {
      const { default: MedicalExams } = await import('./MedicalExams.vue');

      Swal.fire({
        title: 'Exámenes Médicos',
        html: '<div id="exams-container"></div>',
        width: '100%',
        showCloseButton: true,
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: 'Regresar',
        denyButtonColor: '#0d6efd',
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#0d6efd',
        didOpen: () => {
          const confirmButton = document.querySelector('.swal2-confirm');
          const denyButton = document.querySelector('.swal2-deny');
          confirmButton.parentNode.insertBefore(denyButton, confirmButton);
          
          const app = createApp({
            render: () => 
              h(MedicalExams, {
                exams: JSON.parse(this.diagnosis.exams) || [],
              }),
          });
          app.mount('#exams-container');
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.openExamedi();
        }
      });
    },

    // Display General Recommendations
    async showRecommendations() {
      const { default: GeneralRecommendations } = await import('./GeneralRecommendations.vue');

      Swal.fire({
        title: 'Recomendaciones Generales',
        html: '<div id="recommendations-container"></div>',
        width: '100%',
        showCloseButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: 'Regresar',
        denyButtonColor: '#0d6efd',
        didOpen: () => {
          const app = createApp({
            render: () => 
              h(GeneralRecommendations, {
                recommendations: JSON.parse(this.diagnosis.recommendations) || [],
              }),
          });
          app.mount('#recommendations-container');
        },
      });
    },

    async showRating() {
  const self = this;
  const { default: AssessmentRate } = await import('./AssessmentRate.vue');

  Swal.fire({
    title: 'Ayúdanos a mejorar',
    html: '<div id="rate-container"></div>',
    width: '100%',
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    showDenyButton: true,
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);

      const app = createApp({
        data() {
          return {
            rating: self.rating,
            review_comment: self.review_comment
          };
        },
        render() {
          return h(AssessmentRate, {
            rating: self.rating,
            review_comment: self.review_comment,
            'onUpdate:rating': (newRating) => {
              self.rating = newRating;
            },
            'onUpdate:review_comment': (newComment) => {
              self.review_comment = newComment;
            }
          });
        }
      });

      app.mount('#rate-container');
    }
  }).then((result) => {
    if (result.isConfirmed) {
      self.submitReview();
      self.showRecommendationPrompt();
    }
  });
},

    // Show Recommendation Prompt After Rating
    async showRecommendationPrompt() {
      const { default: RecommendationPrompt } = await import('./RecommendationPrompt.vue');

      Swal.fire({
        title: '¡Gracias por tu retroalimentación!',
        html: '<div id="discount-container"></div>',
        width: '100%',
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: this.rating >= 4,
        focusConfirm: false,
        showDenyButton: true,
        denyButtonText: 'Regresar',
        denyButtonColor: '#0d6efd',
        confirmButtonText: 'Ir a Facebook',
        confirmButtonColor: '#0d6efd',
        cancelButtonText: 'Ir a Google',
        cancelButtonColor: '#dc3545',
        didOpen: () => {
          const confirmButton = document.querySelector('.swal2-confirm');
          const denyButton = document.querySelector('.swal2-deny');
          confirmButton.parentNode.insertBefore(denyButton, confirmButton);
          const app = createApp({
            render: () => 
              h(RecommendationPrompt, {
                rating: this.rating,
              })
          });
          app.mount('#discount-container');
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.openFacebook();
        } else if (result.isDenied) {
          // Do nothing or handle denial if needed
        } else {
          this.openGoogle();
        }
      });
    },

    // Open Facebook Reviews
    openFacebook() {
      const url = 'https://www.facebook.com/dermappmx/reviews';
      window.open(url, '_blank');
    },

    // Open Google Reviews
    openGoogle() {
      const url = 'https://g.page/r/CSj9tKdBmJflEB0/review';
      window.open(url, '_blank');
    },

    // Submit Review to API
    submitReview() {
      const updatedReview = {
        diagnosis_id: this.diagnosis.id,
        rating: this.rating,
        review_comment: this.review_comment
      };

      axios.put(`${this.$baseURL}/api/update-review`, updatedReview)
    },

    // Format Follow-up Date
    formatFollowupDate() {
      const createdDate = new Date(this.diagnosis.created_at);
      const followupDate = JSON.parse(this.diagnosis.followup_date); // Ensure this is a JSON object
      const options = { year: 'numeric', month: 'long', day: 'numeric' };

      if(followupDate.suffix === "Months") {
        createdDate.setMonth(createdDate.getMonth() + parseInt(followupDate.amount));
      } else if(followupDate.suffix === "Weeks") {
        createdDate.setDate(createdDate.getDate() + (parseInt(followupDate.amount) * 7));
      } 

      createdDate.setDate(createdDate.getDate() + 14);

      return createdDate.toLocaleDateString('es-ES', options);
    },

    // Open Examedi in New Tab
    openExamedi(){
      window.open(`https://sdk.examedi.com/dermapp`, '_blank');
    },

    // Format Doses
    formatDoses(doses) {
      if (!doses) {
        return ['tamaño único'];
      }

      if (typeof doses === 'string') {
        doses = doses.slice(1, -1).split(',').map(item => item.trim());
      }

      if (doses.length === 0) {
        return ['tamaño único'];
      }

      return doses;
    },

    // Create Pharmacy Checkout
    async createPharmacyCheckout() {
      let checkout = { products: [], total: 0, assessment_id: this.assessment.id, has_shipping: this.has_shipping[0].has_shipping };
      let products = [];
      
      // Process treatment_plan
      this.treatment_plan.forEach(product => {
        if(product.quantity > 0){
          if(product.has_omicron == 1 && product.omicron_selected == 1){
            products.push({
              name: product.omicron.name,
              sku: product.omicron.sku,
              sku_magistral: product.omicron.sku,
              quantity: product.quantity,
              price: JSON.parse(product.omicron.price)[product.omicron.index].toString(),
              prices: JSON.parse(product.omicron.price),
              priceIVA: JSON.parse(product.omicron.price_iva)[product.omicron.index].toString(),
              pricesIVA: JSON.parse(product.omicron.price_iva),
              dose: this.formatDoses(product.omicron.dose)[product.omicron.index],
              doses: this.formatDoses(product.omicron.dose),
              index: product.omicron.index,
            });
          } else if (product.sku !== '0000000000' && product.sku !== '0') {
            products.push({
              name: product.name,
              sku: product.sku,
              sku_magistral: product.sku_magistral,
              quantity: product.quantity,
              price: product.price,
              priceIVA: product.priceIVA.toString(), 
              dose: '',
            });
          }
        }
      });

      // Process extended_treatment_plan
      this.extended_treatment_plan.forEach(product => {
        if(product.quantity > 0){
          if(product.has_omicron == 1 && product.omicron_selected == 1){
            products.push({
              name: product.omicron.name,
              sku: product.omicron.sku,
              sku_magistral: product.omicron.sku,
              quantity: product.quantity,
              price: JSON.parse(product.omicron.price)[product.omicron.index].toString(),
              prices: JSON.parse(product.omicron.price),
              priceIVA: JSON.parse(product.omicron.price_iva)[product.omicron.index].toString(),
              pricesIVA: JSON.parse(product.omicron.price_iva),
              dose: this.formatDoses(product.omicron.dose)[product.omicron.index],
              doses: this.formatDoses(product.omicron.dose),
              index: product.omicron.index,
            });
          } else if (product.sku !== '0000000000' && product.sku !== '0') {
            products.push({
              name: product.name,
              sku: product.sku,
              sku_magistral: product.sku_magistral,
              quantity: product.quantity,
              price: product.price,
              priceIVA: product.priceIVA.toString(), 
              dose: '',
            });
          }
        } 
      });

      if(this.has_shipping[0].has_shipping==0){
        // Add delivery fee
      products.push({
        name: 'Envio nacional',
        sku: 'DHL',
        sku_magistral: 'DHL',
        quantity: 1,
        price: "0.00",
        priceIVA: "0.00", 
        dose: '',
      });
      } else{
        // Add delivery fee
      products.push({
        name: 'Envio nacional',
        sku: 'DHL',
        sku_magistral: 'DHL',
        quantity: 1,
        price: "150.00",
        priceIVA: "150.00", 
        dose: '',
      });
      }

      checkout.products = products;

      checkout.total = this.calculateCustomTotal();

      try {
        const response = await axios.post(`${this.$baseURL}/api/create-pharmacy-order-v2`, checkout);
        if (response.status === 200) {
          //this.$router.push(`/order-cart/${response.data.order_id[0].order_id}`);
          //this.$router.push(`/delivery-address/${this.assessment.id}`);
          this.$router.push(`/pharmacy-payment-method-new/${this.assessment.id}`);
        } else {
          console.error('Error creating order:', response);
        }
      } catch (error) {
        console.error('Error creating order:', error);
      }
    },

    // Calculate Custom Total
    calculateCustomTotal(){
      let total = 0;
      this.treatment_plan.forEach(treatment => {
        if(treatment.has_omicron == 1 && treatment.omicron_selected == 1){
          total += treatment.quantity * JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index] / 100;
        } else {
          total += treatment.quantity * treatment.priceIVA / 100;
        }
      });
      this.extended_treatment_plan.forEach(treatment => { 
        if(treatment.has_omicron == 1 && treatment.omicron_selected == 1){
          total += treatment.quantity * JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index] / 100;
        } else {
          total += treatment.quantity * treatment.priceIVA / 100;
        }
      });
      if(this.has_shipping[0].has_shipping==1){
        total += 1.50; // Assuming this is a fixed fee
      }
      this.custom_total = (total * 100).toFixed(2);
      return this.custom_total;
    },

    // Fetch Diagnosis Treatment Data
    async getDiagnosisTreatment(){   
      try {
        const response = await axios.get(`${this.$baseURL}/api/get-treatments-free`, {
          params: { diagnosis_id: this.diagnosis.id }
        });

        this.treatment_plan = [];
        this.extended_treatment_plan = [];

        const treatments = response.data.data;
        treatments.forEach(product => {
          product.quantity = 0;
          product.omicron_selected = 0;
          if(product.has_omicron == 1){
            product.omicron = JSON.parse(product.omicron);
          }
          if(product.is_minimal == 0) {
            this.extended_treatment_plan.push(product);
          } else {
            this.treatment_plan.push(product);
          }
        });
        this.isDataLoaded = true

      } catch (error) {
        console.error('Error fetching treatment data:', error);
      }
    },   
  }
   }
</script>

<style scoped>
.regresar-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: auto;
  margin-right: 20px; /* Ajusta según sea necesario */
  border: 2px solid #0D80FF;
  border-radius: 10px;
  background-color: #0D80FF; /* Color de fondo azul */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave opcional */
}

.regresar-button:hover {
  background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
}

.assessment-image {
  width: auto;
  max-height: 60vh;
  max-width: 100%;
  display: block;
  object-fit: cover;
  margin: 0 auto;
}

.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.fa-star {
  color: gray;
  cursor: pointer;
}

.fa-star.active {
  color: gold;
}

textarea {
  width: 100%;
  margin-top: 10px;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M7 10l5 5 5-5z'/></svg>") no-repeat right center;
  background-size: 20px;
  padding-right: 30px;
}

.icon-container {
  max-width: 170px;
  max-height: 170px;
  height:140px;
  width: 140px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
