<template>
  <div style="background-color:white; margin-top:-10px; padding-top:10px; padding-bottom:10px">
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px; background-color: white">
      <div
  class="d-flex align-items-center justify-content-between"
  style="width: 100%; cursor: pointer;"
  @click="navigateToDashboard"
>
  <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
</div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button
          class="regresar-button"
          style="margin-left:20px;"
          @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
         Regresar
        </button>
        <button
          class="nav-item"
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid #38b6ff; border-radius: 10px; background: none; color: #38b6ff; cursor: pointer;"
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>
    <div class="container">
      <div class="row justify-content-center">
        <div v-if="isDataLoaded" class="col-md-8">
          <h1 style="margin-left: 10px; font-weight: bold; color: black; margin-top:20px; margin-bottom:20px; text-align:left">
            Mi carrito
          </h1>            
          <div class="card-body" style="height: auto;">
            <!-- Products List -->
            <div v-for="(product, index) in this.products" :key="index" class="mb-4">
              <div
                class="card"
                :class="{ selected: product.selected_option === 'commercial' }"
              >
                <div class="card-content">
                  <div class="card-header" style="border-bottom:white">
                    <p style="font-size: 14px; width:65%; font-weight: bold; text-align:left">{{ product.name.toUpperCase() }}</p>
                    <p style="font-size: 14px; width:35%; text-align:right; font-weight: bold;">
                      MX${{ product.priceIVA }}
                    </p>
                  </div>
                  <div class="card-footer" style="border-top:white">
                    <div style="margin:0px">
                      <!-- Bootstrap Split Button Dose Selector -->
                    <div class="btn-group" v-if="product.doses && product.doses[0]!=''">
                      <button
                        type="button"
                        class="btn btn-white text-black border">
                        {{ product.doses[product.index || 0] }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="background-color: #0D80FF;">
                        <span class="visually-hidden">Toggle Dropdown</span>
                      </button>
                      <ul class="dropdown-menu">
                        <li v-for="(dose, idx) in product.doses" :key="idx">
                          <a
                            class="dropdown-item"
                            href="#"
                            @click.prevent="updateDose(idx, product)">
                            {{ dose }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    </div>
                    <div class="card-actions">
                      <img
                        :src="lessIcon"
                        @click="decreaseQuantity(product)"
                        class="icon"
                      />
                      <span style="margin: 0 5px;">{{ product.quantity }}</span>
                      <img
                        :src="addIcon"
                        @click="increaseQuantity(product)"
                        class="icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <!-- Summary -->
              <div class="subtotal-container">
                <p>Subtotal</p>  
                <p>MX${{ calculateSubtotal.toFixed(2) }}</p>
              </div>
              <div class="shippment-container">
                <p class="shippment-text">
                  Envío
                  <button @click="showShippingInfo" class="info-button" type="button" >
                    <img :src="infoBlackIcon" alt="Info" class="info-icon" />
                  </button>                                          
                </p>  
                <p>MX${{ shippingCost.toFixed(2) }}</p>
              </div>
              <div
              :class="['cashback-container', { 'no-border': appliedDiscount && calculateCouponDiscount > 0 }]"
            >
              <p>Cashback</p>  
              <p>MX$-{{ calculateCashback.toFixed(2) }}</p>
            </div>
              <!-- Display Coupon Discount if Applied -->
              <div v-if="appliedDiscount && calculateCouponDiscount > 0" class="coupon-container">
                <p>Cupón</p>
                <p style="color: #FF38B5;">MX$-{{ calculateCouponDiscount.toFixed(2) }}</p>
              </div>
              <div class="subtotal-container" style="font-size:20px">
                <p>Total</p>  
                <p>MX${{ calculateTotal }}</p>
              </div>
 
              <!-- Insert DiscountCodeBox here -->
                <DiscountCodeBox
                :appliedDiscount="currentAppliedDiscount"
                @apply-coupon="handleApplyCoupon"
                @remove-coupon="handleRemoveCoupon"
                @show-alert="showAlert"
                />
                <!-- <p style="font-size:10px; width: 80%; text-align:left; margin-left:25px; margin-top:-20px">¡Aprovecha los ofertones! Aplica el código ENVIOFF para envío gratis en compras de 1500</p> -->

 
 
            <div style="color:white; text-align:left; margin-left: 30px">
              <input required class="form-check-input" type="checkbox" id="TermsYes" v-model="terms" style="margin-top: 20px; margin-right:10px; margin-bottom: 20px">
              <label style="margin-top: 17px; color: black" class="form-check-label" for="TermsYes">
                  Acepto los
                  <a href="https://www.dermapp.com/terminos-y-condiciones" target="_blank" style="color: black; text-decoration: underline;">términos y condiciones</a>
              </label>
            </div>
 
            <div class="text-center">
              <button @click="toCart()" class="btn-blue">Pagar tratamiento</button>
            </div>
            <div class="text-center">
              <button @click="toAssessment()" class="btn-transparent">Seguir comprando</button>
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- Integrar el ShippingModal -->
     <ShippingModal
     :visible="isShippingModalVisible"
     title="Información de Envío"
     message="Los envíos suelen tardar entre 7 y 10 días hábiles en llegar a tu domicilio. Esto puede variar según tu ubicación y disponibilidad del producto. Podrás consultar el estado de tu pedido en la sección <strong>'Mis pedidos'</strong> una vez realizada la compra."
     linkText="AQUÍ"
     linkUrl="https://www.o-lab.mx/"
     @close="isShippingModalVisible = false"
   />
  </div>
</template>
 
<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapState } from 'vuex';
import axios from 'axios';
import useFacebookPixel from '@/composables/useFacebookPixel';
import { onMounted } from 'vue';
import Swal from 'sweetalert2';
import ShippingModal from '@/views/ShippingModal.vue';
//import InfoMessage from '@/components/InfoMessage.vue'; // Adjust the path as needed
import DiscountCodeBox from '@/components/DiscountCodeBox.vue'; // Adjust the path if necessary
 
export default {
  components: {
    ShippingModal,
    //InfoMessage,
    DiscountCodeBox,
  },
  setup() {
    const { track } = useFacebookPixel();
 
    onMounted(() => {
      track('ViewContent', { content_name: 'Patient dashboard' });
    });
 
    return {};
  },
  data() {
    return {
      orders: [],
      products: [],
      shipping: null,
      isDataLoaded: false,
      isShippingModalVisible: false,
      HeaderLogo: require('@/assets/dermapp-sky.png'),
      addIcon: require('@/assets/shop-add-icon.webp'),
      lessIcon: require('@/assets/shop-less-icon.webp'),
      BackIcon: require('@/assets/arrow-back.png'),
      infoBlackIcon: require('@/assets/info-black-icon.webp'),
      exclamationIcon: require('@/assets/exclamation.png'),
      terms: false,
      appliedDiscount: null, // To store the applied discount details
    };
  },
  computed: {
    ...mapState(['userId', 'credits']),
   
    // Calculate Subtotal
    calculateSubtotal(){
      let temp_subtotal = 0;
      this.products.forEach(product => {
        temp_subtotal += product.priceIVA * product.quantity;
      });
      return parseFloat(temp_subtotal.toFixed(2));
    },
   
    // Calculate Cashback based on credits
    calculateCashback(){
      return Math.min(this.credits, this.calculateSubtotal);
    },
   
    // Dynamic Applied Discount
    currentAppliedDiscount() {
      if (!this.appliedDiscount) return null;
      const discountType = this.appliedDiscount.discount_type;
      const discountValue = parseFloat(this.appliedDiscount.discount_value);
      let coupon_discount = 0;
 
      if (discountType === 'percentage') {
        coupon_discount = (this.calculateSubtotal * discountValue) / 100;
      } else if (discountType === 'fixed') {
        coupon_discount = discountValue;
      } else if (discountType === 'shipping') {
        coupon_discount = 150; // Fixed discount for shipping
      }
 
      // Ensure the discount does not exceed the subtotal
      coupon_discount = Math.min(coupon_discount, this.calculateSubtotal);

      return {
        ...this.appliedDiscount,
        coupon_discount: parseFloat(coupon_discount.toFixed(2)),
      };
    },
   
    // Calculate Coupon Discount based on dynamic appliedDiscount
    calculateCouponDiscount(){
      if (!this.currentAppliedDiscount) {
          return 0;
      }
      return this.currentAppliedDiscount.coupon_discount;
    },
   
    // Total Discount is the sum of Cashback and Coupon Discount
    totalDiscount(){
      return this.calculateCouponDiscount +  this.calculateCashback;
    },

    // Determine Shipping Cost based on coupon type
    shippingCost(){
      return 150.00; // Always display MX$150.00
    },
   
    // Calculate Total
    calculateTotal(){
      return (this.calculateSubtotal + this.shippingCost - this.totalDiscount).toFixed(2);
    }
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refresh the token
          const idToken = await user.getIdToken(true);
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
 
          // Fetch the order data
          const orders_response = await axios.get(`${this.$baseURL}/api/order-by-id`, {
            params: { order_id: this.$route.params.id },
          });
          this.orders.push(orders_response.data.order);
          this.products = JSON.parse(this.orders[0].products).slice(0,-1);
          this.shipping = JSON.parse(this.orders[0].products)[JSON.parse(this.orders[0].products).length-1];
 
          // Initially set cashback based on credits and order total
          // Handled by computed properties
          this.isDataLoaded = true;
        } catch (error) {
          console.error('Error al renovar el token:', error);
          if (
            error.code === 'auth/id-token-expired' ||
            error.code === 'auth/user-token-expired' ||
            error.message.includes('Token')
          ) {
            localStorage.removeItem('Token');
            this.$router.push('/SignIn');
          }
        }
      } else {
        this.$router.push('/SignIn');
      }
    });
  },
  methods: {
    navigateToDashboard() {
      if (this.email) {
        this.$router.push({
          path: '/patient-dashboard',
          query: { email: this.email }
        }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        console.warn('Email is not defined.');
      }
    },
 
    async toCart() {
      if (!this.terms) {
        Swal.fire({
          icon: "warning",
          text: "Por favor acepta los términos y condiciones para continuar.",
          confirmButtonText: "Entendido",
          confirmButtonColor: "#0d6efd",
        });
      } else {
        // Calculate total based on computed properties
        let total = parseFloat(this.calculateSubtotal) - parseFloat(this.calculateCouponDiscount);
       
        if (this.appliedDiscount) {
          let discount_product= {dose: "", name: this.appliedDiscount.code_name, price: String(this.appliedDiscount.discount_value), priceIVA: String(this.appliedDiscount.discount_value), quantity: 1, sku: "Coupon", sku_magistral: "Coupon"};
          this.products.push(discount_product)
        }

        // Prepare checkout object
        let checkout = {
          order_id: this.orders[0].id,
          products: this.products,
          total: total,
          discount_code: this.appliedDiscount ? this.appliedDiscount.code_name : null,
        };
        try {
          const response = await axios.put(`${this.$baseURL}/api/update-order`, checkout);
          if (response.status === 200) {
            this.$router.push(`/delivery-address/${this.orders[0].assessment_id}`);
          } else {
            console.error('Error creando la orden:', response);
          }
        } catch (error) {
          console.error('Error al actualizar la orden:', error);
        }
      }
    },
 
    async handleApplyCoupon(couponCode) {
      if (!couponCode) {
        this.showAlert('Por favor, ingresa un cupón válido.', 'warning');
        return;
      }
 
      try {
        // Send data via query parameters
        const response = await axios.post(`${this.$baseURL}/api/validate-discount`, null, {
          params: {
            code_name: couponCode,
            product: 'pharmacy_purchase'
          }
        });
 
        if (response.status === 200) {
          const { discount_type, discount_value, minimal_purchase, message } = response.data;
 
          // Check if subtotal meets the minimal_purchase requirement
          if (this.calculateSubtotal >= minimal_purchase) {
            this.appliedDiscount = {
              code_name: couponCode,
              discount_type,
              discount_value,
            };
 
            // Emit a success alert
            this.showAlert(message, 'success');
 
            // Increment usage_count via query parameters
            await axios.post(`${this.$baseURL}/api/increment-discount-usage`, null, {
              params: {
                code_name: couponCode
              }
            });
          } else {
            // Show alert about minimal_purchase requirement
            this.showAlert(`¡Para utilizar tu cupón de descuento la compra mínima es de MX$${minimal_purchase.toFixed(2)}!`, 'warning');
          }
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.showAlert(error.response.data.message, 'error');
        } else {
          this.showAlert('Error al aplicar el cupón.', 'error');
        }
      }
    },
 
    handleRemoveCoupon() {
      // Reset the appliedDiscount
      this.appliedDiscount = null;
      // Optionally, notify the server to decrement usage_count
      // This depends on your backend implementation
      this.showAlert('Cupón eliminado.', 'success');
    },
 
    // Show Alert using SweetAlert2 or any other method
    showAlert(message, type = 'warning') {
      Swal.fire({
        icon: type,
        text: message,
        confirmButtonText: "Entendido",
        confirmButtonColor: "#0d6efd",
      });
    },
 
    // Existing methods (increaseQuantity, decreaseQuantity, updateDose, etc.)
    increaseQuantity(product) {
      product.quantity += 1;
    },
    decreaseQuantity(product) {
      if (product.quantity > 1) {
        product.quantity -= 1;
      }
    },
    updateDose(index, product) {
      product.index = index;
      product.dose = product.doses[product.index];
      product.price = product.prices[product.index].toString();
      product.priceIVA = product.pricesIVA[product.index].toString();
    },
    showShippingInfo(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isShippingModalVisible = true;
    },
    toAssessment() {
      this.$router.push(`/assessment-diagnosis-new/${this.orders[0].assessment_id}`);    
    },
  },
};
</script>
 
 
 
<style scoped>
/* Existing styles */
 
.btn-transparent {
  background: transparent;
  border: 2px solid black;
  color: black;
  border-radius: 15px;
  font-family: 'Comfortaa', cursive;
  cursor: pointer;
  width: 80%;
  margin-bottom: 20px;
  padding: 10px 0;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}
 
.btn-transparent:hover {
  background-color: black;
  color: white;
}
 
.regresar-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: auto;
  margin-right: 20px;
  border: 2px solid #0D80FF;
  border-radius: 10px;
  background-color: #0D80FF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
 
.regresar-button:hover {
  background-color: #0B6CD8;
}
 
.dose-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
}
 
.subtotal-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: #000;
  font-size: 14px;
  margin-left:20px;
  margin-right:20px;
}
 
.shippment-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  color: #000;
  font-size: 14px;
  margin-left:20px;
  margin-right:20px;
}
 
.cashback-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px dashed #ccc;
  font-weight: normal;
  color: orange;
  font-size: 14px;
  margin-left:20px;
  margin-right:20px;
}
 
.no-border {
  border-bottom: none;
}
 
.coupon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px dashed #ccc;
  font-weight: normal;
  color: #FF38B5;
  font-size: 14px;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px; /* Added padding for spacing */
}
 
.discount-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: green;
  font-size: 14px;
  margin-left:20px;
  margin-right:20px;
}
 
.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
 
.btn-blue {
  background: blue;
  border: 2px solid blue;
  color: white;
  border-radius: 15px;
  font-family: 'Comfortaa', cursive;
  cursor: pointer;
  width: 80%;
  margin-bottom: 20px;
  padding: 10px 0;
  text-align: center;
}
 
.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}
 
.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: #38b6ff;
  cursor: pointer;
}
 
/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}
 
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
 
/* Card Styles */
.card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
}
 
.card-content {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
 
.card-header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 10px 0;
}
 
.card-footer {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding: 0;
  margin: 0;
}
 
.card-actions {
  display: flex;
  align-items: center;
}
 
/* Estilos para el ícono de información */
.info-icon {
  width: 12px;
  height: 12px;
  margin-left: 0.5px;
  margin-bottom: 90%;
  vertical-align: middle;
  cursor: pointer;
}
 
.info-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
 
.btn-group .dropdown-toggle-split {
  background-image: url('@/assets/drop-arrow.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px 15px;
  padding-right: 30px;
}
 
.btn-group .dropdown-toggle-split::after {
  display: none;
}
 
.btn-group .dropdown-toggle-split,
.btn-group .btn {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  line-height: 1.2;
  height: auto;
}
 
.btn-group .dropdown-menu {
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
 
.btn-group .dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 20px;
  color: #333;
  background-color: #f8f9fa;
  transition: background-color 0.2s ease;
}
 
.discount-container p,
.coupon-container p {
  margin: 0;
}
 
/* Import Comfortaa font */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap');
</style>