<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div 
  class="d-flex align-items-center justify-content-between" 
  style="width: 100%; cursor: pointer;"
  @click="navigateToDashboard"
>
  <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
</div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button 
          class="regresar-button" 
          style="margin-left:20px;"
          @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
         Regresar
        </button>
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>
        
    <div class="container">
      <div class="row justify-content-center" >
        <div  v-if='this.isDataLoaded' class="col-md-8">
          <div  class="card mb-3" style="height: 91vh; ">
            <h5 class="text-center mb-2 mt-4">Mi Seguimiento</h5>
            <h5 class="text-center mb-2 mt-4">{{formatDate(this.followup.created_at)}}</h5>

            <div class="card-body" style="overflow-y: auto; overflow-x:hidden; margin-top:10px">

              <div style="display: flex; justify-content: center; flex-wrap: wrap;">
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-right:3%" @click="showExplanation">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/comments-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Comentarios<br>del dermatólogo</div>
                  </div>
                </div>
                <div class="card mb-2" style="height: 20%; max-width: 46%; cursor: pointer; margin-left:3%" @click="showRecommendations">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/recommendations-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px ">Tips <br>generales</div>
                  </div>                  
                </div>
              </div>

              <div style="display: flex; justify-content: center; flex-wrap: wrap; margin-top:5px;">
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-right:3%" @click="showFollowup">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/next-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Próxima <br> consulta</div>
                  </div>
                </div>
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-left:3%"  @click="showExams">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/plan-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Exámenes<br> médicos</div>                  
                  </div>
                  </div>
              </div>

              <div style="display: flex; justify-content: center; flex-wrap: wrap; margin-top:5px;">
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-right:3%" @click="showTreatment">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/plan-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Plan de<br> tratamiento</div>
                  </div>
                </div>
                <div class="card mb-2" style="height:20%; max-width: 46%; cursor: pointer; margin-left:3%" @click="showPurchase">
                  <div class="icon-container" :style="{ backgroundImage: `url(${require('@/assets/purchase-icon.png')})` }">
                    <div style="margin-top:80px; font-size: 16px">Cotiza tu<br> tratamiento</div>
                  </div>                  
                </div>
              </div>
        
              <p style="color: red;">
                Tu experiencia es importante para nosotros<br>
                Por favor contactanos si tienes dudas<br>
                <button class="btn btn-primary mb-2 mt-3" type="button" @click="openWhatsapp(1)">Contactar Soporte</button>  
              </p>
            </div>
          </div>
        </div>
      </div>           
      </div>
    </div>

  </template>
  
  <script>
    import { getAuth, onAuthStateChanged } from 'firebase/auth';
  import { mapActions, mapState } from 'vuex';
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { createApp, h } from 'vue';

  import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';

  
  export default {
    setup() {
    const { track } = useFacebookPixel(); 


    onMounted(() => {
      //track('InitiateCheckout', {content_name: 'Followup Treatment' });
      track('ViewContent', {content_name: 'Previous Assessment' });
      
    });

    return {};
  },

    
    data() {
      return {
        custom_total: null,
        patent_total: null,
        compound_total:null ,
        pharmacy_total: null,
        formulas_total: null,
        isReviewCollapsed: true,
        rating: 0,
        review_comment: '',
        diagnosisId: null,
        followupId: null,
        drugs: [],
        assessment: [],
        followup_assessment:[],
        diagnosis: [],
        followup: [],
        treatment: [],
        followup_treatment: [],
        current_treatment:[],
        diagnosis_struct: {
          assessment_id: null,
          diagnosis: '',
          explanation: '',
          prognosis: '',
          followup: '',
          recommendations: [],
          exams:[],
          comments: '',
          followup_date: {
            amount: '1',
            suffix: 'Months'
          },
      },
      followup_struct: {
          followup_assessment_id: null,
          followup: '',
          recommendations: [],
          exams:[],
          comments: '',
          followup_date: {
            amount: '1',
            suffix: 'Months'
          },
          deleted_products:[],
      },
      recommendation: '',
      exam: '',
      treatment_plan: [],
      extended_treatment_plan: [],
      followup_treatment_plan: [],
      followup_extended_treatment_plan: [],
        cycle: {
          indication: '',
          day: 'Monday',
          time: {
            hour: '8',
            suffix: 'am',
          },
          frequency: {
            amount: '12',
            suffix: 'Hours',
          },
          duration: {
            amount: '1',
            suffix: 'Months'
          },
        },
        num_cycles: 1,
        edit_mode: 0,
        edit_index: null,
        isDataLoaded: false,
        assessmentsLoaded: false,
        activeIndex: 0,
        HeaderLogo: require('@/assets/dermapp-white.png'),
        BackIcon: require('@/assets/arrow-back.png'),

        isExplanationCollapsed: true,
        isPrognosisCollpased: true,
        isImagesDataCollapsed: true,
        isRecommendationsCollapsed: true,
        isCommentsCollapsed: true,
        isExamsCollapsed: true,
        isTreatmentCollapsed: true,
        isFollowupCollapsed:true,
        isPharmacyCollapsed:true,
        isCustomCartCollapsed: true,
        isPharmacyCartCollapsed: true,
        isFormulasCartCollapsed: true,
        isAssessmentsDataCollapsed:true,



      collapseIconUrl: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M7 10l5 5 5-5z'/></svg>",
    
      };
    },
    computed: {
      ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'primaryName', 'primaryBirthday', 'profileSubscription']),
    },

    mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
          // Fetch the user data
      
  const token = localStorage.getItem('Token');
      axios.get(`${this.$baseURL}/api/get-followup-by-id`, {
      params: {
        followup_assessment_id: this.$route.params.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        },
    })
    .then((followup_response) => {
      
      this.followup_assessment = followup_response.data.data[0];
      axios.get(`${this.$baseURL}/api/assessment-data`, {
      params: {
        assessment_id: this.followup_assessment.assessment_id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((response) => {
      this.assessment = response.data.data;
      this.getAssessmentFiles();

      axios.get(`${this.$baseURL}/api/diagnosis-data`, {
      params: {
        assessment_id: this.followup_assessment.assessment_id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((diagnosis_response) => {
      this.diagnosis = diagnosis_response.data.data;
      this.getDiagnosisTreatment();
      axios.get(`${this.$baseURL}/api/followup-diagnosis-data`, {
      params: {
        followup_assessment_id: this.followup_assessment.id,
      },      
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((followup_data_response) => {
      this.followup = followup_data_response.data.data;
      this.getFollowupDiagnosisTreatment();
      this.isDataLoaded = true;
    })
    .catch((error) => {
      console.error("Error fetching followup diagnosis data:", error);
    }); 
    })
    .catch((error) => {
      console.error("Error fetching followup data:", error);
    }); 
    })
    .catch((error) => {
      console.error("Error fetching diagnosis data:", error);
    });
    })
    .catch((error) => {
      console.error("Error fetching assessment data:", error);
    });

        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });

  
    
},
    methods: {
      navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },

      showExplanation() {
        const self = this;
    Swal.fire({
      title: self.diagnosis.diagnosis,
      html: '<div style="text-align: left; max-height: 75vh; overflow-y: auto; overflow-x:hidden">' + 
              self.followup.comments + "<br><br>" + 
            '</div>',
      confirmButtonText: 'Regresar',
      confirmButtonColor: '#0d6efd',
      showCloseButton: true,
    });
  },

  showPrognosis() {
    const self = this;
    Swal.fire({
      title: '¿Qué cambios veremos?',
      html: '<div style="text-align: left; max-height: 75vh; overflow-y: auto; overflow-x:hidden">' + self.diagnosis.prognosis + '</div>',
      confirmButtonText: 'Regresar',
      confirmButtonColor: '#0d6efd',
      showCloseButton: true,
    });
  },


  showFollowup() {
    const self = this;
    Swal.fire({
      title: 'Próxima consulta',
      html: '<div style="text-align: center; max-height: 75vh; overflow-y: auto; overflow-x:hidden">' +
        '<strong style="font-size:24px;" >' + self.formatFollowupDate() + '</strong>'+

            '</div>', 
      confirmButtonText: 'Regresar',
      confirmButtonColor: '#0d6efd',
      showCloseButton: true,
    });
  },
  

  async showTreatment() {
      const { default: TreatmentPlan } = await import('./TreatmentPlan.vue');

      Swal.fire({
        title: 'Plan de tratamiento',
        html: '<div id="treatment-plan-container"></div>',
        width: '100%',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        showDenyButton: true,
        showConfirmButton: false,
        denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',

        didOpen: () => {
          const confirmButton = document.querySelector('.swal2-confirm');
  const denyButton = document.querySelector('.swal2-deny');
  confirmButton.parentNode.insertBefore(denyButton, confirmButton);
          const app = createApp({
            render: () =>
              h(TreatmentPlan, {
                treatment_plan: this.followup_treatment_plan,
                extended_treatment_plan: this.followup_extended_treatment_plan,
              }),
          });
          app.mount('#treatment-plan-container');
        },
      });
},

  async showPurchase() {
    const self = this;
      const { default: QuotePlan } = await import('./QuotePlan.vue');

      Swal.fire({
        title: 'Cotiza tu tratamiento',
        html: '<div id="quote-plan-container"></div>',
        width: '100%',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        showDenyButton: true,
        denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
        didOpen: () => {
          const confirmButton = document.querySelector('.swal2-confirm');
  const denyButton = document.querySelector('.swal2-deny');
  confirmButton.parentNode.insertBefore(denyButton, confirmButton);
          const app = createApp({
            render: () =>
              h(QuotePlan, {
                treatment_plan: this.followup_treatment_plan,
                extended_treatment_plan: this.followup_extended_treatment_plan,
                assessmentId: this.assessment.id,
                onUpdateTreatmentPlan: (newPlan) => {
                  this.followup_treatment_plan = newPlan;
                },
                onUpdateExtendedTreatmentPlan: (newPlan) => {
                  this.followup_extended_treatment_plan = newPlan;
                },
              }),
          });
          app.mount('#quote-plan-container');
        },
      }).then((result) => {
    if (result.isConfirmed) {
      self.createPharmacyCheckout()
        }
  });
},

async showExams() {
    const self = this;
      const { default: MedicalExams } = await import('./MedicalExams.vue');

      Swal.fire({
        title: 'Exámenes Médicos',
        html: '<div id="exams-container"></div>',
        width: '100%',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        showDenyButton: true,
        denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
        didOpen: () => {
          const confirmButton = document.querySelector('.swal2-confirm');
  const denyButton = document.querySelector('.swal2-deny');
  confirmButton.parentNode.insertBefore(denyButton, confirmButton);
          const app = createApp({
            render: () =>
              h(MedicalExams, {
                exams: JSON.parse(this.followup.exams) || [],
              }),
          });
          app.mount('#exams-container');
        },
      }).then((result) => {
    if (result.isConfirmed) {
      self.openExamedi()
        }
  });
},

async showRecommendations() {
      const { default: GeneralRecommendations } = await import('./GeneralRecommendations.vue');

      Swal.fire({
        title: 'Recomendaciones Generales',
        html: '<div id="recommendations-container"></div>',
        width: '100%',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        showDenyButton: true,
        denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
        didOpen: () => {
          const app = createApp({
            render: () =>
              h(GeneralRecommendations, {
                recommendations: JSON.parse(this.diagnosis.recommendations) || [],
              }),
          });
          app.mount('#recommendations-container');
        },
      });
},

async showRating() {
  const self = this;
  const { default: AssessmentRate } = await import('./AssessmentRate.vue');

  Swal.fire({
    title: 'Ayúdanos a mejorar',
    html: '<div id="rate-container"></div>',
    width: '100%',
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    showDenyButton: true,
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);

      const app = createApp({
        data() {
          return {
            rating: self.rating,
            review_comment: self.review_comment
          };
        },
        render() {
          return h(AssessmentRate, {
            rating: this.rating,
            review_comment: this.review_comment,
            'onUpdate:rating': (newRating) => {
              this.rating = newRating;
              self.rating = newRating;
            },
            'onUpdate:review_comment': (newComment) => {
              this.review_comment = newComment;
              self.review_comment = newComment;
            }
          });
        }
      });

      app.mount('#rate-container');
    }
  }).then((result) => {
    if (result.isConfirmed) {
      self.submitReview();
      self.showRecommendationPrompt();
    }
  });
},


async  showRecommendationPrompt() {
  const self = this;
  const { default: RecommendationPrompt } = await import('./RecommendationPrompt.vue');

  Swal.fire({
    title: '¡Gracias por tu retroalimentación!',
    html: '<div id="discount-container"></div>',
    width: '100%',
    showCloseButton: true,
    showConfirmButton: this.rating >= 4,
    showCancelButton: this.rating >= 4,
    focusConfirm: false,
    showDenyButton: true,
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Ir a Facebook',
    confirmButtonColor: '#0d6efd',
    cancelButtonText: 'Ir a Google',
    cancelButtonColor: '#dc3545',
    
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);
      const app = createApp({
        render: () =>
          h(RecommendationPrompt, {
            rating: self.rating,
          })
      });
      app.mount('#discount-container');
    }
  }).then((result) => {
    if (result.isConfirmed) {
      self.openFacebook();
    } else if (result.isDenied) {
      console.log('continue');
    } else {
      self.openGoogle();
    }
  });
},

openFacebook() {
        
          let url = 'https://www.facebook.com/dermappmx/reviews'
            window.open(url, '_blank');
            
        },

        openGoogle() {
        
        let url = 'https://g.page/r/CSj9tKdBmJflEB0/review'
          window.open(url, '_blank');
          
      },


      getSavings() {
    let savings = 0;
    let response = "";
    savings = this.calculatePharmacyTotal() - this.calculateFormulasTotal();
    if (savings > 0) {
        response = "¡Ahorra $" + savings.toFixed(2) + " en tu tratamiento con formulas magistrales!";
        return response;
    } else {
        response = "Parece que en esta ocasion la mejor opcion son los productos comerciales";
    }
    return response;
},
      
      toggleReview() {
            this.isReviewCollapsed = !this.isReviewCollapsed;
        },
        setRating(n) {
            this.rating = n;
        },
        submitReview() {
          const updatedReview = {
            followup_diagnosis_id: this.followup.id,
            rating: this.rating,
            review_comment: this.review_comment
          }

            // Make a POST request to your API endpoint to create the assessment
            axios.put(`${this.$baseURL}/api/update-followup-review`, updatedReview);
        },

      openAssessment(id,isFollowup) {
    if(isFollowup==0){
      let url = `${this.$baseURLPacientes}/assessment-diagnosis/${id}`;
    window.open(url, '_blank');
    } else {
      let url = `${this.$baseURLPacientes}/followup-assessment-diagnosis/${id}`;
    window.open(url, '_blank');
    }
    
  },

  openWhatsapp(num) {
        if(num==0){
          let url = 'https://api.whatsapp.com/send?phone=528140052978&text=Hola%2C%20me%20gustaria%20cotizar%20formulas%20magistrales%20para%20mi%20tratamiento';
            window.open(url, '_blank');
        } else {
          let url = 'https://api.whatsapp.com/send?phone=528140052978&text=Hola%2C%20tengo%20una%20duda%20o%20comentario%20sobre%20mi%20diagnostico';
            window.open(url, '_blank');
        }
            
        },

      formatFollowupDate() {
    const createdDate = new Date(this.followup.created_at);
    const followupDate = JSON.parse(this.followup.followup_date); // ensure this is a JSON object
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    if(followupDate.suffix === "Months") {
        createdDate.setMonth(createdDate.getMonth() + parseInt(followupDate.amount));
    } else if(followupDate.suffix === "Weeks") {
        createdDate.setDate(createdDate.getDate() + (parseInt(followupDate.amount) * 7));
    } 

    return createdDate.toLocaleDateString('es-ES', options);
},

      openExamedi(){
        window.open(`https://sdk.examedi.com/dermapp`, '_blank');
      },

      increaseQuantity(index){
        this.followup_treatment_plan[index].quantity ++;
      },

      decreaseQuantity(index){
        if(this.followup_treatment_plan[index].quantity != 0){
          this.followup_treatment_plan[index].quantity --;
        }
      },

      increaseExtendedQuantity(index){
        this.followup_extended_treatment_plan[index].quantity ++;
      },

      decreaseExtendedQuantity(index){
        if(this.followup_extended_treatment_plan[index].quantity != 0){
          this.followup_extended_treatment_plan[index].quantity --;
        }
      },

      
      selectOption(product, option) {
        // Set product.omicron_selected based on which card was clicked
        product.omicron_selected = option;
    },


    calculateCustomTotal(){
        let total = 0;
        this.followup_treatment_plan.forEach(treatment => {
          if(treatment.has_omicron==1 && treatment.omicron_selected==1){

            total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;
          
          } else {

            total = total + treatment.quantity*treatment.priceIVA/100;
          
          }
          
        });
        this.followup_extended_treatment_plan.forEach(treatment => { 
          if(treatment.has_omicron==1 && treatment.omicron_selected==1){

            total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;
          
          } else {

            total = total + treatment.quantity*treatment.priceIVA/100;
          
          }
        });
        total = total + 1.50;
        this.custom_total = (total*100).toFixed(2);
        return this.custom_total
      },

      calculatePharmacyTotal(){
        let total = 0;

        
        this.followup_treatment_plan.forEach(treatment => {

          if(treatment.sku!='0000000000' && treatment.sku!='0'){

          total = total + treatment.quantity*treatment.priceIVA/100;

          } else if (treatment.has_omicron==1) {

          total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;

          }
          
        });
        this.followup_extended_treatment_plan.forEach(treatment => { 
          
          if(treatment.sku!='0000000000' && treatment.sku!='0'){

            total = total + treatment.quantity*treatment.priceIVA/100;

          } else if (treatment.has_omicron==1) {

            total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;

          }

        });
        total = total + 1.50;
        this.pharmacy_total = (total*100).toFixed(2);
        return this.pharmacy_total
      },

      calculateFormulasTotal(){
        let total = 0;
        this.followup_treatment_plan.forEach(treatment => {
          

          if(treatment.has_omicron==1){

            total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;
          
           } else if (treatment.sku!='0000000000' && treatment.sku!='0') {

            total = total + treatment.quantity*treatment.priceIVA/100;
          
          }
          
        });
        this.followup_extended_treatment_plan.forEach(treatment => { 
          
          if(treatment.has_omicron==1){

            total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;

           } else if (treatment.sku!='0000000000' && treatment.sku!='0') {

            total = total + treatment.quantity*treatment.priceIVA/100;

            }
         
        });
        total = total + 1.50;
        this.formulas_total = (total*100).toFixed(2);
        return this.formulas_total
      },

      formatDate(dateString) {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    },

    pharmacyButtonText(total){
      if(total>=1100){
        return "Envio incluido"
      } else {
        return "Envio incluido"
      }
    },

    formatDoses(doses) {
  
  // Check if doses is undefined and set it to an empty array
  if (doses === undefined) {
    doses = [];
  }

  // If doses is a string, parse it
  if (typeof doses === 'string') {
    // Remove the first and last characters (the square brackets)
    doses = doses.slice(1, -1);
    // Split the string by commas and trim any extra spaces from each item
    doses = doses.split(',').map(item => item.trim());
  }

  // Check if the resulting doses array is empty
  if (doses.length === 0) {
    doses = ['tamaño único'];
  }

  return doses;
},

async createPharmacyCheckout() {
        let checkout = {};
        let products = [];
          
          this.followup_treatment_plan.forEach(product => {
            let temp_product = {
          name: '',
          sku: '',
          sku_magistral: '',
          quantity: null,
          price: null,
          priceIVA: null, 
          dose: '',
          }
          if(product.quantity>0){
              if(product.has_omicron==1 && product.omicron_selected==1){
              temp_product.name = product.omicron.name;
              temp_product.sku = product.omicron.sku;
              temp_product.sku_magistral = product.omicron.sku;
              temp_product.quantity = product.quantity;
              temp_product.price = JSON.parse(product.omicron.price)[product.omicron.index].toString();
              temp_product.priceIVA = JSON.parse(product.omicron.price_iva)[product.omicron.index].toString(); 
              temp_product.dose = this.formatDoses(product.omicron.dose)[product.omicron.index];

              products.push(temp_product);
              } else if (product.sku!='0000000000' && product.sku!='0') {
              temp_product.name = product.name;
              temp_product.sku = product.sku;
              temp_product.sku_magistral = product.sku_magistral;
              temp_product.quantity = product.quantity;
              temp_product.price = product.price;
              temp_product.priceIVA = product.priceIVA; 
              products.push(temp_product);
              }
            }
          });

          this.followup_extended_treatment_plan.forEach(product => {
            let temp_product = {
          name: '',
          sku: '',
          sku_magistral: '',
          quantity: null,
          price: null,
          priceIVA: null, 
          dose: '',
          }
          if(product.quantity>0){
              if(product.has_omicron==1 && product.omicron_selected==1){
              temp_product.name = product.omicron.name;
              temp_product.sku = product.omicron.sku;
              temp_product.sku_magistral = product.omicron.sku;
              temp_product.quantity = product.quantity;
              temp_product.price = JSON.parse(product.omicron.price)[product.omicron.index].toString();
              temp_product.priceIVA = JSON.parse(product.omicron.price_iva)[product.omicron.index].toString(); 
              temp_product.dose = this.formatDoses(product.omicron.dose)[product.omicron.index];

              products.push(temp_product);
              } else if (product.sku!='0000000000' && product.sku!='0') {
              temp_product.name = product.name;
              temp_product.sku = product.sku;
              temp_product.sku_magistral = product.sku_magistral;
              temp_product.quantity = product.quantity;
              temp_product.price = product.price;
              temp_product.priceIVA = product.priceIVA; 
              products.push(temp_product);
              }
            } 
          });

          let delivery = {
          name: 'Envio nacional',
          sku: 'DHL',
          sku_magistral: 'DHL',
          quantity: 1,
          price: "150.00",
          priceIVA: "150.00", 
          dose: '',
        }
          products.push(delivery);
          checkout.products = products;
          checkout.total = this.calculateCustomTotal();
          checkout.assessment_id = this.assessment.id;


        try {
            // Make a POST request to your API endpoint to create the assessment
            const response = await axios.post(`${this.$baseURL}/api/create-pharmacy-order`, checkout);
            if (response.status === 200) {
                // If the request was successful, save the new assessment id

                this.$router.push(`/delivery-address/${this.assessment.id}`);
               
            } else {
                console.error('Error creating order:', response);
            }
        } catch (error) {
            console.error('Error creating order:', error);
        }
    },

    toggleCustomCart() {
      this.isCustomCartCollapsed = !this.isCustomCartCollapsed;
    },
    toggleFormulasCart() {
      this.isFormulasCartCollapsed = !this.isFormulasCartCollapsed;
    },
    togglePharmacyCart() {
      this.isPharmacyCartCollapsed = !this.isPharmacyCartCollapsed;
    },


    togglePharmacy() {
      this.isPharmacyCollapsed = !this.isPharmacyCollapsed;
    },

    toggleAssessmentsData() {
      this.isAssessmentsDataCollapsed = !this.isAssessmentsDataCollapsed;
    },

      toggleExplanation() {
      this.isExplanationCollapsed = !this.isExplanationCollapsed;
    },
    togglePrognosis() {
      this.isPrognosisCollpased = !this.isPrognosisCollpased;
    },
    toggleFollowup() {
      this.isFollowupCollapsed = !this.isFollowupCollapsed;
    },
    toggleRecommendations() {
      this.isRecommendationsCollapsed = !this.isRecommendationsCollapsed;
    },
    toggleExams() {
      this.isExamsCollapsed = !this.isExamsCollapsed;
    },
    toggleComments() {
      this.isCommentsCollapsed = !this.isCommentsCollapsed;
    },
    toggleTreatment() {
      this.isTreatmentCollapsed = !this.isTreatmentCollapsed;
    },

    ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),

      calculateAge(birthday) {
    const birthdate = new Date(birthday);
    const ageDifMs = Date.now() - birthdate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  },

      parseSex(sex){
        if(sex=='Male'){
          return 'Masculino';
        } else {
          return 'Femenina';
        }
      },

      parseDuration(duration){
        if(duration=='Years'){
          return 'Años';
        } else if(duration=='Months'){
          return 'mes(es)';
        } else if(duration=='Weeks'){
          return 'semana(s)';
        } else if(duration=='Days'){
          return 'dia(s)';
        } else if(duration=='Hours'){
          return 'hora(s)';
        }
      },

      parseDay(day){
        if(day==0){
          return 'Lunes';
        } else if(day==1){
          return 'Martes';
        } else if(day==2){
          return 'Miercoles';
        } else if(day==3){
          return 'Jueves';
        } else if(day==4){
          return 'Viernes';
        } else if(day==5){
          return 'Sabado';
        } else if(day==6){
          return 'Domingo';
        }
      },

async getAssessmentFiles(){
  const token = localStorage.getItem('Token');
  try {
    const response = await axios.get(`${this.$baseURL}/api/get-files`, {
      params: {
        assessment_id: this.assessment.id,
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    const imageFiles = response.data.data.filter(file => file.file_type === 0);
    const examFiles = response.data.data.filter(file => file.file_type === 1);

     this.assessment.images = imageFiles;
     this.assessment.exams = examFiles;
} catch (error) {
    console.error('Error fetching assessment files:', error);
  }
},

async getDiagnosisTreatment(){
  const token = localStorage.getItem('Token');
  try {
    const response = await axios.get(`${this.$baseURL}/api/get-treatments`, {
      params: {
        diagnosis_id: this.diagnosis.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    });

     this.treatment = response.data.data;

    this.treatment.forEach(product => {
      product.quantity = 1;
      if(product.is_minimal == 0) {
        this.extended_treatment_plan.push(product);
      } else {
        this.treatment_plan.push(product);
      }
      
     });
} catch (error) {
    console.error('Error fetching treatment data:', error);
  }
},   

async getFollowupDiagnosisTreatment(){
  const token = localStorage.getItem('Token');
  try {
    const response = await axios.get(`${this.$baseURL}/api/get-followup-treatments`, {
      params: {
        followup_diagnosis_id: this.followup.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    });

     this.treatment = response.data.data;
    this.treatment.forEach(product => {
      product.quantity = 1;
      product.omicron_selected = 1;
      if(product.has_omicron==1){
        product.omicron=JSON.parse(product.omicron)
      }
      if(product.is_minimal == 0) {
        this.followup_extended_treatment_plan.push(product);
      } else {
        this.followup_treatment_plan.push(product);
      }

      
     });
} catch (error) {
    console.error('Error fetching followup treatment data:', error);
  }
},  
    },

  };


  </script>
  
  <style scoped>
  .regresar-button {
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: auto;
    margin-right: 20px; /* Ajusta según sea necesario */
    border: 2px solid #0D80FF;
    border-radius: 10px;
    background-color: #0D80FF; /* Color de fondo azul */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transición suave opcional */
  }
  
  .regresar-button:hover {
    background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
  }
  

.assessment-image {
    width: auto;    /* Set the width of the image to 40% of the container */
    max-height: 60vh;  /* Maintain the aspect ratio */
    max-width: 100%;
    display: block;
    object-fit: cover; /* This will ensure that your images are always centered and cover the element. It might crop some parts of the image if the aspect ratios do not match */
    margin: 0 auto; /* Center the image if necessary */
}


.header {
    margin-top: -5px;
    position: relative;
    width: 100%;
    height: auto;
    background: #36b5ff;
    z-index: 1;
  }

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}


.fa-star {
  color: gray;
  cursor: pointer;
}

.fa-star.active {
  color: gold;
}

textarea {
  width: 100%;
  margin-top: 10px;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M7 10l5 5 5-5z'/></svg>") no-repeat right center;
  background-size: 20px; /* Adjust based on your icon size */
  padding-right: 30px; /* Adjust based on your icon size */
}

.icon-container {
  max-width: 170px;
  max-height: 170px;
  height:140px;
  width: 140px;
  background-size: contain; /* or 'cover' */
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

  </style>
  