<template>
  <div>
    <!-- Header Section -->
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%; cursor: pointer;"
        @click="navigateToDashboard"
      >
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;" />
      </div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button class="regresar-button" style="margin-left:20px;" @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
          Regresar
        </button>
        <button
          class="nav-item"
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;"
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })"
        >
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>

    <!-- Main Content -->
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="card mb-2">
            <h5 class="text-center mb-2 mt-4">Método de Pago</h5>
            <div class="card-body mb-2">

              <!-- Informational Text: Top -->
              <div class="text-center">
                <p>
                  Completa el pago y obtén tu plan <br /> de tratamiento en menos de 24 horas
                </p>
              </div>

              <!-- Price Details: Always show original price -->
              <div class="price-details mt-3">
                <p>
                  <span class="bold-price" :class="{ 'original-price': appliedDiscount }">
                    Total: MX$ {{ isNaN(originalPrice) ? '0.00' : originalPrice.toFixed(2) }}
                  </span>
                </p>
                <!-- Conditionally render discounted price -->
                <p v-if="appliedDiscount">
                  <span class="discounted-price">
                    Total con descuento: MX$ {{ isNaN(discountedPrice) ? '0.00' : discountedPrice.toFixed(2) }}
                  </span>
                </p>
              </div>

              <!-- Discount Code Box -->
              <DiscountCodeBox
                :appliedDiscount="appliedDiscount"
                @apply-coupon="applyCoupon"
                @remove-coupon="removeCoupon"
                @show-alert="handleAlert"
              />

              <!-- Informational Text: Bottom -->
              <div class="text-center mt-3">
                <p>
                  ¿Cuál es el método de pago<br /> de tu preferencia?
                </p>
              </div>

              <!-- Payment Method Cards -->
              <div class="card mb-2">
                <div class="card-body">
                  <h5 class="mb-3">Tarjeta de débito o crédito</h5>
                  <button class="btn btn-primary" style="width:80%" @click="cardPayment(`${this.$price}`)">
                    Pagar con tarjeta
                  </button>
                </div>
              </div>
              <div class="card mb-2">
                <div class="card-body">
                  <h5 class="mb-3">Transferencia interbancaria</h5>
                  <router-link
                    :to="{ name: 'WireTransfer', params: { id: this.$route.params.id } }"
                    class="btn btn-primary"
                    style="width:80%"
                  >
                    Pagar por transferencia
                  </router-link>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="mb-3">Depósito en efectivo</h5>
                  <router-link
                    :to="{ name: 'CashPayments', params: { id: this.$route.params.id } }"
                    class="btn btn-primary"
                    style="width:80%"
                  >
                    Pagar en efectivo
                  </router-link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapState, mapActions } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import useFacebookPixel from '@/composables/useFacebookPixel';
import { onMounted } from 'vue';

// Import the DiscountCodeBox component
import DiscountCodeBox from '@/components/DiscountCodeBox.vue';

// Import SweetAlert2
import Swal from 'sweetalert2';

export default {
  props: {
    price: {
      type: Number,
      required: false,
      default: 180, // Default value if price is not provided
    },
  },

  components: {
    DiscountCodeBox, // Register the component
  },
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('SubmitApplication', { content_name: 'Create Account Assessment' });
      track('ViewContent', { content_name: 'Payment Method' });
    });

    return {};
  },
  data() {
  return {
    assessment_id: null,
    HeaderLogo: require('@/assets/dermapp-white.png'),
    BackIcon: require('@/assets/arrow-back.png'),
    coupon: null, // To store applied coupon details
    originalPrice: this.price != null ? Number(this.price) : 180, // Parse as number
    discountedPrice: this.price != null ? Number(this.price) : Number(this.price), // Initialize to originalPrice
    appliedDiscount: null, // To store applied discount details
    coupon_applied: false,
    discountedPriceId30: 'price_1QSnFCJJ0oNVbEaqPhgsXPDU', // New discounted price ID for CONSULTA30
    discountedPriceId50: 'price_1QSmrfJJ0oNVbEaqYXgkz5rH', // New discounted price ID for CONSULTA50

    userAgent: '', // To store the user agent
    // Ensure $price and $baseURL are available, possibly via props or Vuex state
  };
},

  computed: {
    
    ...mapState([
      'email',
      'userId',
      'isSubscribed',
      'credits',
      'country',
      'phone',
      'referralCode',
      'profileId',
      'profileSex',
      'profileName',
      'profileLastName',
      'profileBirthday',
      'primaryName',
      'primaryBirthday',
    ]),
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refresh the token
          const idToken = await user.getIdToken(true); // 'true' forces token refresh

          // Update localStorage with the new token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
          this.assessment_id = this.$route.params.id;
          this.userAgent = navigator.userAgent; // Store the user agent

          // Fetch the user data
          const response = await axios.get(`${this.$baseURL}/api/user-by-assessment`, {
            params: {
              assessment_id: this.assessment_id || this.$route.params.id,
            },
          });

          const data = response.data.data;
          this.updateUserId(data.user.id);
          this.updateEmail(data.user.email);
          this.updatePhone(data.user.phone, data.user.country);
          this.updateCountry(data.user.country);
          this.updateCredits(data.user.credits);
          this.updateReferralCode(data.user.referral_code);
          this.updateProfileId(data.profile.id);
          this.updateProfileName(data.profile.name.split(' ')[0]);
          this.updateProfileLastName(data.profile.name.split(' ')[1]);
          this.updateProfileSex(data.profile.sex);
          this.updateProfileBirthday(data.profile.birthday);
          this.updateProfileSubscription(data.profile.subscription);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // If the token is expired or there's an authentication error
          if (
            error.code === 'auth/id-token-expired' ||
            error.code === 'auth/user-token-expired' ||
            error.message.includes('Token')
          ) {
            localStorage.removeItem('Token'); // Remove the expired token
            this.$router.push('/SignIn'); // Redirect to login
          }
        }
      } else {
        // If no user is authenticated, redirect to login
        this.$router.push('/SignIn'); // Redirect to login
      }
    });
  },
  methods: {
    navigateToDashboard() {
      if (this.email) {
        this.$router
          .push({
            path: '/patient-dashboard',
            query: { email: this.email },
          })
          .catch((err) => {
            // Handle the error if navigation fails (optional)
            if (err.name !== 'NavigationDuplicated') {
              console.error(err);
            }
          });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },

    ...mapActions([
      'updateUserId',
      'updateEmail',
      'updatePhone',
      'updateCountry',
      'updateCredits',
      'updateReferralCode',
      'updateProfileId',
      'updateProfileName',
      'updateProfileLastName',
      'updateProfileBirthday',
      'updateProfileSex',
      'updateProfileSubscription',
    ]),

    async getLocationByIp(ipAddress) {
      const response = await axios.get(`https://ipinfo.io/${ipAddress}/geo`);
      return { city: response.data.city, state: response.data.region, zipCode: response.data.postal };
    },

    async getIpAddress() {
      const response = await axios.get('https://api64.ipify.org?format=json');
      return response.data.ip;
    },

    getFbcAndFbp() {
      let fbc, fbp;

      try {
        // Extract _fbc from the cookie
        fbc = document.cookie.split('; ').find((row) => row.startsWith('_fbc')).split('=')[1];
      } catch {
        fbc = null;
      }

      try {
        // Extract _fbp from the cookie
        fbp = document.cookie.split('; ').find((row) => row.startsWith('_fbp')).split('=')[1];
      } catch {
        fbp = null;
      }

      return { fbc, fbp };
    },


    async cardPayment(price) {
      // Determine the actual price based on coupon application
      let actual_price = price;

      if (this.coupon_applied && this.appliedDiscount) {
      switch (this.appliedDiscount.code_name) {
        case 'CONSULTA30':
        case 'D3RM4PP30':
          actual_price = this.discountedPriceId30;
          break;
        case 'CONSULTA50':
        case 'DERMAPP50':
          actual_price = this.discountedPriceId50;
          break;
        default:
          actual_price = this.originalPrice; // Fallback to original price if coupon code is unrecognized
          actual_price = price; // Fallback to original price if coupon code is unrecognized
          break;
      }
    }

      let { fbp, fbc } = this.getFbcAndFbp();
      let ipAddress = await this.getIpAddress();
      let { city, state, zipCode } = await this.getLocationByIp(ipAddress);

      let userData = {
        email: this.email,
        phone: this.phone,
        name: this.profileName,
        lastName: this.profileLastName,
        sex: this.profileSex,
        userId: this.userId.toString(),
        fbp: fbp,
        fbc: fbc,
        birthday: this.profileBirthday,
        ipAddress: ipAddress,
        userAgent: this.userAgent,
        country: this.country,
        state: state,
        city: city,
        zipCode: zipCode,
      };

      try {
        // Create user tracking
        await axios.post(`${this.$baseURL}/api/create-user-tracking`, userData);

        // Load Stripe
        const stripe = await loadStripe(`${this.$loadstripepk}`);

        // Create checkout session
        const response = await axios.post(`${this.$baseURL}/api/create-checkout-session`, {
          user_id: this.userId,
          email: this.email,
          price: actual_price, // Use actual_price here
          assessment_id: this.assessment_id,
          payment_method: 'card',
          phone: this.phone,
          patient_name: this.profileName,
        });

        // Redirect to Stripe checkout

        const sessionId = response.data.sessionId;
        stripe.redirectToCheckout({ sessionId });
      } catch (error) {
        console.error('Error during payment process:', error);
      }
    },



    async applyCoupon(couponCode) {
  if (!couponCode) {
    this.showAlert('Por favor, ingresa un cupón válido.', 'warning');
    return;
  }

  try {
    const response = await axios.post(`${this.$baseURL}/api/validate-discount`, null, {
      params: {
        code_name: couponCode, // Use 'code_name' as expected by backend
        product: 'assessment',  // Ensure this matches the expected product type
      },
    });

    if (response.status === 200) {
      const discountData = response.data;

      // Calculate coupon_discount based on discount_type
      let coupon_discount = 0;
      const { discount_type, discount_value, minimal_purchase } = discountData;

      switch (discount_type) {
        case 'percentage':
          coupon_discount = (this.originalPrice * discount_value) / 100;
          break;
        case 'fixed':
          coupon_discount = discountData.coupon_discount || discount_value;
          break;
        case 'shipping':
          coupon_discount = 0; // Assuming shipping discount doesn't affect the price directly
          break;
        default:
          coupon_discount = 0;
      }

      // Construct the appliedDiscount object with all necessary properties
      this.appliedDiscount = {
        code_name: couponCode, // Using the entered coupon code as code_name
        discount_type: discount_type || '',
        discount_value: discount_value || 0,
        coupon_discount: coupon_discount,
        minimal_purchase: minimal_purchase || 0,
        message: discountData.message || 'Cupón aplicado exitosamente.',
      };

      this.calculateDiscountedPrice();
      this.handleAlert('Cupón aplicado exitosamente.', 'success');

      // **New Code: Update has_coupon and code_name in the database**
      try {
        let couponAssessmentData = {
          assessment_id: this.assessment_id,
          code_name: couponCode, // Include code_name
          product: 'assessment',  // Include product
        };

        await axios.post(`${this.$baseURL}/api/apply-coupon-to-assessment`, couponAssessmentData);

        // **Set coupon_applied to true**
        this.coupon_applied = true;
      } catch (updateError) {
        console.error('Error actualizando has_coupon y code_name:', updateError);
        this.handleAlert('Cupón aplicado, pero hubo un error al actualizar tu evaluación.', 'warning');
      }
    } else {
      this.handleAlert('Cupón inválido o expirado.', 'error');
    }
  } catch (error) {
    console.error('Error applying coupon:', error.response ? error.response.data : error);
    const errorMessage = error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : 'Error al aplicar el cupón. Por favor, intenta de nuevo.';
    this.handleAlert(errorMessage, 'error');
  }
},



    // Handle removing a coupon
    removeCoupon() {
      this.appliedDiscount = null;
      this.discountedPrice = this.originalPrice; // Reset to original price
      this.$emit('show-alert', 'Cupón eliminado.');
      // Optionally notify the backend about coupon removal
    },

    // Handle alerts emitted from DiscountCodeBox
    handleAlert(message, type = 'info') {
      // Type can be 'success', 'error', 'warning', 'info', or 'question'
      Swal.fire({
        icon: type,
        title: type === 'error' ? 'Error' : type === 'success' ? '¡Éxito!' : '',
        text: message,
        confirmButtonText: 'Aceptar',
        timer: type === 'info' ? 3000 : null, // Auto-close for info messages
      });
    },

    // Calculate discounted price based on the applied discount
    calculateDiscountedPrice() {
      if (!this.appliedDiscount) {
        this.discountedPrice = this.originalPrice;
        return;
      }

      const { discount_type, discount_value, coupon_discount, minimal_purchase } = this.appliedDiscount;

      // Handle null or undefined minimal_purchase
      const minPurchase = minimal_purchase || 0;

      if (this.originalPrice < minPurchase) {
        this.handleAlert(`El precio original debe ser al menos MX$ ${minPurchase.toFixed(2)} para aplicar este cupón.`, 'warning');
        this.removeCoupon();
        return;
      }

      switch (discount_type) {
        case 'percentage':
          this.discountedPrice = this.originalPrice - (this.originalPrice * discount_value) / 100;
          break;
        case 'fixed':
          this.discountedPrice = this.originalPrice - coupon_discount;
          break;
        case 'shipping':
          // Assuming 'shipping' type doesn't affect the price directly
          this.discountedPrice = this.originalPrice;
          break;
        default:
          this.discountedPrice = this.originalPrice;
      }

      // Ensure the discounted price doesn't go below zero
      if (this.discountedPrice < 0) {
        this.discountedPrice = 0;
      }

      // Optionally, round to two decimal places
      this.discountedPrice = parseFloat(this.discountedPrice.toFixed(2));
    },
  },
};
</script>


<style scoped>
.regresar-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: auto;
  margin-right: 20px; /* Ajusta según sea necesario */
  border: 2px solid #0D80FF;
  border-radius: 10px;
  background-color: #0D80FF; /* Color de fondo azul */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave opcional */
}

.regresar-button:hover {
  background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
}

.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

/* Price Details Styles */
.price-details {
  text-align: center;
}

.bold-price {
  font-weight: 600; /* Always bold */
}

.original-price {
  text-decoration: line-through; /* Line-through when discount is applied */
  color: black;
}

.discounted-price {
  color: green;
  font-weight: 600;
}
</style>
