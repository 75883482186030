<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div
  class="d-flex align-items-center justify-content-between"
  style="width: 100%; cursor: pointer;"
  @click="navigateToDashboard"
>
  <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
</div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button
          class="regresar-button"
          style="margin-left:20px;"
          @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
         Regresar
        </button>
        <button
          class="nav-item"
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;"
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>
    <div class="container">
      <div class="row justify-content-center" >
        <div class="col-md-8">
          <div v-if="isDataLoaded" class="card mb-2">
            <div class="card-body">
              <h5 class="text-center mb-2 mt-4">Transferencia Interbancaria</h5>
                <p class="card-text" style="text-align: left; margin-top: 20px;">
                  1. Ingresa a tu aplicación bancaria o acude al banco más cercano. <br><br>
                  2. Proporciona los siguientes datos de cuenta: <br><br>
                    Banco: BBVA <br>
                    Clabe: 012580001228631386 <br>
                    Beneficiario: MONTERREY HEALTHTECH SOLUTIONS SAPI DE CV <br>
                    Concepto: TRATAMIENTO<br><br>
 
                    Monto: ${{ (this.order.total + 150).toFixed(2) }} MXN <br><br>
 
                    3. Envía el comprobante a la plataforma dando clic en el botón.<br>
                    4. Una vez que se confirme la transaccion tu pedido sera procesada.*<br>
                  </p>
                  <p class="card-text" style="text-align: left; margin-top: 20px; font-size:10px">
                    *Las transacciones realizadas a traves de este metodo de pago pueden tardar 1 a 2 dias habiles en verse reflejadas en nuestro estado de cuenta.
                   </p>
                   <div class="text-center">
                    <label for="images" class="btn btn-primary">Enviar comprobante</label>
                    <input class="d-none" type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="updateReceiptFile">
                </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapState } from 'vuex';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // this generates a unique identifier
 
import useFacebookPixel from '@/composables/useFacebookPixel';
 
  import { onMounted } from 'vue';
 
 
 
export default {
  setup() {
    const { track } = useFacebookPixel();
 
    onMounted(() => {
      track('ViewContent', {content_name: ' Pharmacy Wire transfer' });
      track('InitiateCheckout', { content_name: 'Initiate pharmacy wire transfer payment' });
 
    });
 
   
 
    return {};
  },
  data() {
  return {
      assessment_id: null,
      order: {},
      isDataLoaded: false,
      HeaderLogo: require('@/assets/dermapp-white.png'),
      BackIcon: require('@/assets/arrow-back.png'),
 
      receiptFiles: []  // New data property
  };
},
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName', 'primaryName', 'primaryBirthday']),
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token
 
          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
          this.assessment_id = this.$route.params.id;
 
axios.get(`${this.$baseURL}/api/get-latest-pharmacy_order`, {
      params: {
        assessment_id: this.assessment_id || this.$route.params.id,
      },
    })
    .then((order_response) => {
      this.order = order_response.data.data;
      this.isDataLoaded = true;
     
    })
    .catch((error) => {
      console.error("Error fetching diagnosis data:", error);
    });
        } catch (error) {
          console.error('Error al renovar el token:', error);
 
          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });
},
  methods: {
    navigateToDashboard() {
      if (this.email) {
        this.$router.push({
          path: '/patient-dashboard',
          query: { email: this.email }
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },
      updateReceiptFile(event) {
      this.receiptFiles = [...event.target.files];
      if(this.receiptFiles.length>0){
        this.uploadReceipt();
      }
     
  },
 
  async uploadToS3(file, folder) {
  const fileExtension = file.name.split('.').pop();
  const newFileName = `${uuidv4()}.${fileExtension}`; // creates a new unique file name
  const fileName = `${folder}${newFileName}`;
  const destination = 'dermapp-documents';
  const availability = 'public-read';
 
  const formData = new FormData();
  formData.append('destination', destination);
  formData.append('fileName', fileName);
  formData.append('file', file);
  formData.append('availability', availability);
 
  try {
    await axios.post(`${this.$baseURL}/api/upload-to-s3`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
 
    await this.uploadToDB(newFileName, fileExtension);
  } catch (error) {
    console.error('Error uploading files to s3:', error);
  }
},
 
async uploadToDB(fileName, fileExtension){
let fileType = 3;
   
    try {
      const files_data = {
        assessment_id: this.$route.params.id,
        file_name: fileName,
        file_extension: fileExtension,
        file_type: fileType,
      }
 
 
await axios.post(`${this.$baseURL}/api/assessment-files`, files_data);
 
const payment_data = {
        order_id: this.order.id,
        status: 1,
        payment_method: 'wire-transfer',
        phone: this.phone,
        email: this.email,
        patient_name: this.profileName
      }
 
await axios.put(`${this.$baseURL}/api/update-order-payment`, payment_data);
 
 
              this.$router.push({ name: 'PharmacyPaymentSuccess', params: { email: this.email} });      
 
 
} catch (error) {
console.error('Error updating order payment status:', error);
}
},
 
async uploadReceipt() {
 
      try {
          // Make a POST request to your API endpoint to create the assessment
 
              // Now that we have an assessmentId, we can upload the files
              const images = this.$refs.imagesRef.files;
              for(let i=0; i<images.length; i++) {
                  await this.uploadToS3(images[i], 'receipts/');
              }
 
             
         
          }
      catch (error) {
          console.error('Error uploading receipt:', error);
      }
  },
  }
};
</script>
 
<style scoped>
.regresar-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: auto;
  margin-right: 20px; /* Ajusta según sea necesario */
  border: 2px solid #0D80FF;
  border-radius: 10px;
  background-color: #0D80FF; /* Color de fondo azul */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave opcional */
}
 
.regresar-button:hover {
  background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
}
 
 
.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}
 
.nav-item {
margin-right: 20px;
background: none;
border: none;
color: white;
cursor: pointer;
}
 
</style>