import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Import the store
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDgaw_hXh2_GMBugL5KD1oQfEyffmB7nG4",
  authDomain: "dermapp-remaster.firebaseapp.com",
  projectId: "dermapp-remaster",
  storageBucket: "dermapp-remaster.appspot.com",
  messagingSenderId: "793458562218",
  appId: "1:793458562218:web:937d5a417238f516fcacd3",
  measurementId: "G-CNH7VC7BPK"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Inicializar Analytics
const analytics = getAnalytics(app);

export { app, auth, analytics };

const vueApp = createApp(App);

vueApp.config.globalProperties.$environment = 'production';
vueApp.config.globalProperties.$analytics = analytics;

// payment dev
// vueApp.config.globalProperties.$price = 'price_1QejLAQvRKdtExRSDTa29WaU';
// vueApp.config.globalProperties.$followup_price = 'price_1QiKIWQvRKdtExRSD1E37kRi';
// payment staging
// vueApp.config.globalProperties.$price = 'price_1Qk5bjQu0uK9WN1uXH4hjVEa';
// vueApp.config.globalProperties.$followup_price = 'price_1Qk5jyQu0uK9WN1uQC2TPKLU';
// payment preprod
// vueApp.config.globalProperties.$price = 'price_1Qk6BWQpTZebIrYVAoj1t3p1';
// vueApp.config.globalProperties.$followup_price = 'price_1Qk6DqQpTZebIrYVxgoB18rM';
// payment prod
vueApp.config.globalProperties.$price = 'price_1QbnSPJJ0oNVbEaqpbxv3XXE';
vueApp.config.globalProperties.$followup_price = 'price_1PdHDJJJ0oNVbEaqfbpDalhy';


//subscriptions dev
// vueApp.config.globalProperties.$priceannual = 'price_1Qk5fiQvRKdtExRSgcAqZmk9';
// vueApp.config.globalProperties.$pricemonthly = 'price_1QhF3uQvRKdtExRS7dmLWj3B';
// vueApp.config.globalProperties.$pricefamily = 'price_1Qk5gcQvRKdtExRSJotNa4mf';

//subscriptions staging
// vueApp.config.globalProperties.$priceannual = 'price_1Qk5kpQu0uK9WN1uQiXbOYlN';
// vueApp.config.globalProperties.$pricemonthly = 'price_1Qk5rJQu0uK9WN1u0j8mIoBl';
// vueApp.config.globalProperties.$pricefamily = 'price_1Qk5kOQu0uK9WN1us3iJzl8i';

//subscriptions preprod
// vueApp.config.globalProperties.$priceannual = 'price_1Qk6CNQpTZebIrYVHHVIMkco';
// vueApp.config.globalProperties.$pricemonthly = 'price_1Qk6ByQpTZebIrYVBNX2NE3x';
// vueApp.config.globalProperties.$pricefamily = 'price_1Qk6CpQpTZebIrYVGDMOOQd8';

//subscription prod
vueApp.config.globalProperties.$priceannual = 'price_1PtDSqJJ0oNVbEaqfQkOhz4f';
vueApp.config.globalProperties.$pricemonthly = 'price_1KYd91JJ0oNVbEaqBDOSA8wJ';
vueApp.config.globalProperties.$pricefamily = 'price_1NwGOOJJ0oNVbEaqhlOuNNZy';

//loadstripe dev
// vueApp.config.globalProperties.$loadstripepk = 'pk_test_51QYEXRQvRKdtExRSm0cKW6tKyDTYdQLhM88YgBMNHdgbpEqaHogbyY07KLjnDUwxox5xWLoF7EOHvYFFZrskEddk00yCpwM8KA';

//loadstripe staging
// vueApp.config.globalProperties.$loadstripepk = 'pk_test_51QdBckQu0uK9WN1uS9OhQ2z6Qy4pYKjeKpv2qLv9uplrWG8q9dEYxFpoMoQdBcdtGgEB9r662PMVM07NOkqNZWCC00HNqUSOJR';

//loadstripe preprod
// vueApp.config.globalProperties.$loadstripepk = 'pk_test_51QdBeKQpTZebIrYVi4cRJpDDNRopGMJsssiGipS7poBVBUMYfjOUTcBhF7gC5JwgQbBHatG2StexPd3nLnmpftC1002fIxC6wA';

//load stripe prod
vueApp.config.globalProperties.$loadstripepk = 'pk_live_ZoqW5KN9L5o7WoIIdD0CEipo';

//baseurls local
// vueApp.config.globalProperties.$baseURL = 'http://localhost:3000';
// vueApp.config.globalProperties.$baseURLAdmin = 'http://localhost:8080';
// vueApp.config.globalProperties.$baseURLDerma = 'http://localhost:8082';
// vueApp.config.globalProperties.$baseURLPacientes = 'http://localhost:8081';

//baseurls development
// vueApp.config.globalProperties.$baseURL = 'https://shared.core.dermapp.com/development';
// vueApp.config.globalProperties.$baseURLPacientes = 'https://development.d2sse29elvtl2f.amplifyapp.com';
// vueApp.config.globalProperties.$baseURLDerma = 'https://development.d1hwkdpi02eeb2.amplifyapp.com';
// vueApp.config.globalProperties.$baseURLAdmin = 'https://development.d3qc5ziq02amxn.amplifyapp.com';

//base urls staging
// vueApp.config.globalProperties.$baseURL = 'https://shared.core.dermapp.com/staging';
// vueApp.config.globalProperties.$baseURLPacientes = 'https://staging.d2sse29elvtl2f.amplifyapp.com';
// vueApp.config.globalProperties.$baseURLDerma = 'https://staging.d1hwkdpi02eeb2.amplifyapp.com';
// vueApp.config.globalProperties.$baseURLAdmin = 'https://staging.d3qc5ziq02amxn.amplifyapp.com';

//base urls preprod
// vueApp.config.globalProperties.$baseURL = 'https://shared.core.dermapp.com/preprod';
// vueApp.config.globalProperties.$baseURLPacientes = 'https://preprod.d2sse29elvtl2f.amplifyapp.com';
// vueApp.config.globalProperties.$baseURLDerma = 'https://preprod.d1hwkdpi02eeb2.amplifyapp.com';
// vueApp.config.globalProperties.$baseURLAdmin = 'https://preprod.d3qc5ziq02amxn.amplifyapp.com';

//base urls prod
// vueApp.config.globalProperties.$baseURL = 'https://core.dermapp.com';
// vueApp.config.globalProperties.$baseURLPacientes = 'https://pacientes.dermapp.com';
// vueApp.config.globalProperties.$baseURLDerma = 'https://dermatologos.dermapp.com';
// vueApp.config.globalProperties.$baseURLAdmin = 'https://admin.dermapp.com';

// //base urls legacy prod
vueApp.config.globalProperties.$baseURL = 'https://api.dermapp.com';
vueApp.config.globalProperties.$baseURLPacientes = 'https://pacientes.dermapp.com';
vueApp.config.globalProperties.$baseURLDerma = 'https://dermatologos.dermapp.com';
vueApp.config.globalProperties.$baseURLAdmin = 'https://admin.dermapp.com';

// Manejador global de errores
vueApp.config.errorHandler = (err, vm, info) => {
  console.error("Vue errorHandler:", err);

  // Si New Relic está disponible, registra el error
  if (window.newrelic) {
    window.newrelic.noticeError(err, {
      component: vm ? vm.$options.name || vm.$options._componentTag : undefined,
      info,
      route: vm?.$route?.fullPath,
    });
  }
};

// Captura de errores no manejados (fuera de Vue)
window.addEventListener('error', (event) => {
  console.error("Global error:", event.error);

  if (window.newrelic) {
    window.newrelic.noticeError(event.error, {
      source: 'window',
    });
  }
});

window.addEventListener('unhandledrejection', (event) => {
  console.error("Unhandled Promise rejection:", event.reason);

  if (window.newrelic) {
    window.newrelic.noticeError(event.reason, {
      source: 'promise',
    });
  }
});

// Sobrescribe console.error para reportar automáticamente a New Relic
const originalConsoleError = console.error;

console.error = function (...args) {
  // Llama al comportamiento original de console.error
  originalConsoleError.apply(console, args);

  // Si New Relic está disponible, reporta el error
  if (window.newrelic) {
    const errorMessage = args.map(arg => (arg instanceof Error ? arg.message : String(arg))).join(' ');
    const errorDetails = args.find(arg => arg instanceof Error) || { message: errorMessage };

    window.newrelic.noticeError(errorDetails, {
      source: 'console.error',
      rawArgs: args,
    });
  }
};

vueApp.use(router).use(store).mount('#app');