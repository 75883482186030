<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div 
      class="d-flex align-items-center justify-content-between" 
      style="width: 100%; cursor: pointer;"
      @click="navigateToDashboard"
    >
      <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
    </div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button 
          class="regresar-button" 
          style="margin-left:20px;"
          @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
         Regresar
        </button>
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="mb-2">
            <h1 class="mb-4 mt-4 text-white" style="text-align:left; margin-left: 20px; font-size:35px">¡Cuida tu piel<br> durante todo el año!</h1>
            <div class="card-body mb-2">
              <p style="text-align:left; color:white; margin-left: 20px">
                <img :src="CheckIcon" alt="Check Icon" style="margin-right: 10px; height: 15px; margin-bottom:5px" />
                Beneficios exclusivos para miembros.
              </p>
              <p style="text-align:left; color:white; margin-left: 20px">
                <img :src="CheckIcon" alt="Check Icon" style="margin-right: 10px; height: 15px; margin-bottom:5px" />
                Consultas ilimitadas durante todo el año.
              </p>
              <p style="text-align:left; color:white; margin-left: 20px; margin-bottom: 40px">
                <img :src="CheckIcon" alt="Check Icon" style="margin-right: 10px; height: 15px; margin-bottom:5px" />
                Recibe 10% de CASHBACK en cada<br><span style="margin-left: 28px">compra de tratamiento.</span> 
              </p>
              <div class="card mb-2 mt-4" 
                :class="{ selected: selectedCard === 'premium' }"
                @click="selectCard('premium')">
                <div class="badge" :class="{ selectedBadge: selectedCard === 'premium' }">Recomendada</div>
                <div class="content">
                  <div class="flex-container">
                    <div class="left-content">
                      <div class="title">Membresía Premium</div>
                      <p>Consultas ilimitadas para 1 perfil</p>
                    </div>
                    <div class="right-content">
                      <div class="price">$125.00 <span>MXN</span></div>
                      <p>cada mes</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-2 mt-4" 
                :class="{ selected: selectedCard === 'familiar' }"
                @click="selectCard('familiar')">
                <div class="content">
                  <div class="flex-container">
                    <div class="left-content">
                      <div class="title">Membresía Familiar</div>
                      <p>Consultas ilimitadas para 5 perfiles</p>
                    </div>
                    <div class="right-content">
                      <div class="price">$300.00 <span>MXN</span></div>
                      <p>cada mes</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="blue-card mb-2 mt-4" 
                :class="{ selected: selectedCard === 'anual' }"
                @click="selectCard('anual')">
                <div class="blue-badge" :class="{ selectedBadge: selectedCard === 'anual' }">Mejor Oferta</div>
                <div class="content">
                  <div class="flex-container">
                    <div class="left-content">
                      <div class="title">Membresía Anual</div>
                      <p>Consultas ilimitadas para 1 perfil</p>
                    </div>
                    <div class="right-content">
                      <div class="price">$900.00 <span>MXN</span></div>
                      <p>cada año</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button class="btn-primary custom-button mt-4"
                @click="subscribe()">
                Convertirme en Miembro</button>
              </div>

              <div class="mt-4">
                <router-link 
                  :to="{ name: 'CreateFollowupAssessment', params: { id: $route.params.id }}" 
                  class="text-link"
                  style="display: block; text-align: center; margin-top: 10px; color: white">
                  O continúa con consulta individual<br> por $180 MXN
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      HeaderLogo: require('@/assets/dermapp-white.png'),
      CheckIcon: require('@/assets/green-check-icon.webp'),
      BackIcon: require('@/assets/arrow-back.png'),

      selectedCard: null, // Track the selected card
    };
  },
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName', 'primaryName', 'primaryBirthday']),
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });


},
  methods: {
    navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },
    selectCard(cardType) {
      this.selectedCard = cardType; // Save the selected card type
    },
    async subscribe() {
      if (!this.selectedCard) {
        Swal.fire({
          icon: 'warning',
          text: 'Por favor selecciona una membresía para continuar.',
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#0d6efd',
        });
      } else {
        this.$router.push({ name: 'SubscriptionDetails', params: { plan: this.selectedCard } });
      }
    },
  },
};
</script>


  
  <style scoped>
  .regresar-button {
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: auto;
    margin-right: 20px; /* Ajusta según sea necesario */
    border: 2px solid #0D80FF;
    border-radius: 10px;
    background-color: #0D80FF; /* Color de fondo azul */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transición suave opcional */
  }
  
  .regresar-button:hover {
    background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
  }
  
  .header {
    margin-top: -5px;
    position: relative;
    width: 100%;
    height: auto;
    background: #36b5ff;
    z-index: 1;
  }
  
  .nav-item {
    margin-right: 20px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .content {
    text-align: left;
  }
  
  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-content, .right-content {
    display: flex;
    flex-direction: column;
  }
  
  .title {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  p {
    font-size: 0.7em;
    color: #58595b;
  }
  
  .price {
    font-size: 1.2em;
    font-weight: bold;
    text-align: right;
  }
  
  .price span {
    font-size: 0.7em;
  }
  
  .right-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Aligns children to the right */
  }
  
  .price p {
    font-size: 0.7em;
    color: #58595b;
    margin: 0; /* Remove any margin */
    text-align: right; /* Ensure the text is aligned right */
  }
  
  .card {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 30px 20px 10px 20px;
    margin: 20px 10px;
    position: relative;
  }
  
  .selected {
    border: 2px solid #1512ff; /* Highlight border color */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45);
  }
  
  .badge, .blue-badge {
    font-size: 0.7em;
    font-weight: normal;
    color: white;
    height: 35px;
    border-radius: 20px;
    padding: 10px 20px;
    position: absolute;
    top: -15px;
    left: 20px;
  }
  
  .selectedBadge {
    position: absolute;
    overflow: visible; /* Allow the pseudo-element to overflow */
  }
  
  .selectedBadge::before {
    content: '';
    position: absolute;
    top: -2px; /* Adjust this value to control how much of the border shows on top */
    left: 0;
    right: 0;
    height: 50%; /* Half the height of the badge */
    border-top: 2px solid #1512ff; /* Border color */
    border-left: 2px solid #1512ff; /* Add this for left border */
    border-right: 2px solid #1512ff; /* Add this for right border */
    border-bottom: none; /* No border on the bottom */
    border-radius: 20px 20px 0 0; /* Round the top borders to match the badge shape */
    box-sizing: border-box;
  }
  
  
  
  
  /* Specific background gradients for each badge */
  .badge {
    background: linear-gradient(90deg, #ffb838, #ff860b);
  }
  
  .blue-badge {
    background: linear-gradient(180deg, #19d2ff, #0d80ff);
  }
  
  /* Blue card specific styling */
  .blue-card {
    background: linear-gradient(90deg, #19d2ff, #0d80ff); /* Horizontal gradient */
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 30px 20px 10px 20px;
    margin: 20px 10px;
    position: relative;
    color: white; /* Ensure all text inside the card is white */
  }
  
  .blue-card .title {
    font-size: 1.2em; /* Same as other cards */
    font-weight: bold;
    color: white; /* White text */
  }
  
  .blue-card p {
    font-size: 0.7em; /* Same as other cards */
    color: white; /* White text */
  }
  
  .blue-card .price {
    font-size: 1.2em; /* Same as other cards */
    font-weight: bold;
    text-align: right;
    color: white; /* White text */
  }
  
  .blue-card .price span {
    font-size: 0.7em; /* Same as other cards */
    color: white; /* White text */
  }
  
  .blue-card .right-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Aligns children to the right */
  }
  
  .blue-card .price p {
    font-size: 0.7em;
    color: white; /* White text */
    margin: 0; /* Remove any margin */
    text-align: right; /* Ensure the text is aligned right */
  }

  .custom-button {
    background-color: #1512ff;
    color: white;
    width: 80%;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 1.0em;
    font-weight: 500;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.custom-button:hover {
    background-color: #0f0ccc;
}
  
  
  </style>
  

  