<template>
  <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
    <div 
class="d-flex align-items-center justify-content-between" 
style="width: 100%; cursor: pointer;"
@click="navigateToDashboard"
>
<img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
</div>
    <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
      <button 
        class="regresar-button" 
        style="margin-left:20px;"
        @click="$router.back();">
        <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
       Regresar
      </button>
    </nav>
  </header>
  <div>
        
    <div class="container">
      <div class="row justify-content-center">
        <div v-if="isDataLoaded" class="col-md-8">
          <div class="card mb-2">
            <div class="card-body">
              <h5>Chatea con tu dermatólogo</h5>  
              <p>Este chat es para dudas del tratamiento</p>    
      
              <div class="card" style="margin-top:20px; height:60vh; overflow-y: auto; overflow-x:hidden">
                <div class="message" v-for="(msg, index) in assessment.messages" :key="index"
                     :class="{'admin-message': msg.sender === 'admin', 'user-message': msg.sender === 'user'}"
                     @click="toggleDate(index)">
                  {{ msg.message }}
                  <small v-if="visibleDateIndex === index">{{ msg.date }}</small>
                </div>
              </div>
              <div class="input-group mt-3">
                <input type="text" class="form-control" id="new_message" v-model="newMessage" placeholder="Escribe un mensaje...">
                <button class="btn btn-primary" type="button" @click="sendMessage">Enviar</button>
              </div>
              <div class="text-center mt-3">
                <label style="margin-right:10px" for="images" class="btn btn-primary">Imágenes</label>
                <input class="d-none" type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="handleFileChange('images/')">
                <label style="margin-left:10px" for="pdfs" class="btn btn-primary">Exámenes</label>
                <input class="d-none" type="file" id="pdfs" name="pdfs" accept="application/pdf" multiple ref="pdfsRef" @change="handleFileChange('exams/')">
              </div>
            </div>
          </div>                
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapState } from 'vuex';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // this generates a unique identifier
import useFacebookPixel from '@/composables/useFacebookPixel';

import { onMounted } from 'vue';

export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('ViewContent', {content_name: 'Assessment Messages' });


    });

    return {};
  }, 

  data() {
    return {
      imageFiles: [],
      pdfFiles: [],
      newMessage: "",
      isDataLoaded: false,
      assessmentsLoaded: false,
      activeIndex: 0,
      HeaderLogo: require('@/assets/dermapp-white.png'),
      BackIcon: require('@/assets/arrow-back.png'),

      isUploading: false,
      imageFail: false, 
      assessment: [],
      followup_assessment_id: null,
      visibleDateIndex: null, // Track the index of the message with a visible date
    };
  },
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'primaryName', 'primaryBirthday', 'profileSubscription']),
  },
  mounted() {
        try {

    axios.get(`${this.$baseURL}/api/assessment-data-free`, {
      params: {
        assessment_id: this.$route.params.id,
      }
    }).then((response) => {
      this.assessment = response.data.data;
      this.assessment.messages = JSON.parse(this.assessment.messages);
      if(this.assessment.status > 1){
        axios.get(`${this.$baseURL}/api/get-latest-followup-assessment-free`, {
          params: {
            assessment_id: this.$route.params.id,
            mode: this.assessment.status,
          }
        }).then((followup_response) => {
          this.followup_assessment_id = followup_response.data.data.id;
        })
        .catch((error) => {
          console.error("Error fetching followup assessment data:", error);
        });
      }
      this.isDataLoaded = true;
    })
    .catch((error) => {
      console.error("Error fetching assessment data:", error);
    });
        } catch (error) {
          console.log(error);
        }

    /* onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);

          const token = localStorage.getItem('Token');
    axios.get(`${this.$baseURL}/api/assessment-data-free`, {
      params: {
        assessment_id: this.$route.params.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    }).then((response) => {
      this.assessment = response.data.data;
      this.assessment.messages = JSON.parse(this.assessment.messages);
      if(this.assessment.status > 1){
        axios.get(`${this.$baseURL}/api/get-latest-followup-assessment-free`, {
          params: {
            assessment_id: this.$route.params.id,
            mode: this.assessment.status,
          },
          headers: {
              'Authorization': `Bearer ${token}`
            }
        }).then((followup_response) => {
          this.followup_assessment_id = followup_response.data.data.id;
        })
        .catch((error) => {
          console.error("Error fetching followup assessment data:", error);
        });
      }
      this.isDataLoaded = true;
    })
    .catch((error) => {
      console.error("Error fetching assessment data:", error);
    });
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    }); */
    
  },
  methods: {
    async handleFileChange(folder) {
      let selectedFiles = [...event.target.files];
      let new_total = this.imageFiles.length + selectedFiles.length;
      let new_total_pdf = this.pdfFiles.length + selectedFiles.length;
      if(selectedFiles.length > 0){
        this.isUploading = true;
      }

      for (let i = 0; i < selectedFiles.length; i++) {
        let tempFile = {
          file: selectedFiles[i],
          status: 'Subiendo...',
        }
        if(folder == 'images/') {
          this.imageFiles.push(tempFile);
          await this.uploadToS3(this.imageFiles[this.imageFiles.length-1].file, folder, this.imageFiles.length-1, new_total);
        } else {
          this.pdfFiles.push(tempFile);
          await this.uploadToS3(this.pdfFiles[this.pdfFiles.length-1].file, folder, this.pdfFiles.length-1, new_total_pdf);
        }
      }
    },

    async uploadToS3(file, folder, position, total) {
      const fileExtension = file.name.split('.').pop();
      const newFileName = `${uuidv4()}.${fileExtension}`; // creates a new unique file name
      const fileName = `${folder}${newFileName}`;
      const destination = 'dermapp-documents';
      const availability = 'public-read';

      const formData = new FormData();
      formData.append('destination', destination);
      formData.append('fileName', fileName);
      formData.append('file', file);
      formData.append('availability', availability);

      try {
        await axios.post(`${this.$baseURL}/api/upload-to-s3`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        await this.uploadToDB(newFileName, fileExtension, folder, position, total);
      } catch (error) {
        this.imageFail = true;
        this.isUploading = false;
        if(folder == 'images/') {
          this.imageFiles[position].status = 'Cancelado';
        } else {
          this.pdfFiles[position].status = 'Cancelado';
        }
        console.error('Error uploading files to s3:', error);
      }
    },

    async uploadToDB(fileName, fileExtension, folder, position, total) {
      let fileType = folder === 'images/' ? 0 : 1;
      if(this.assessment.status > 1) {
        try {
          const files_data = {
            followup_assessment_id: this.followup_assessment_id,
            file_name: fileName,
            file_extension: fileExtension,
            file_type: fileType,
          }
          await axios.post(`${this.$baseURL}/api/assessment-followup-files`, files_data);

          if(position+1 == total) {
            if(folder == 'images/') {
              alert("Las imagenes fueron enviadas");
              if(this.assessment.messages == null) {
                this.assessment.messages = [];
              }
              this.assessment.messages.push({ sender: 'user', message: "Se enviaron nuevas imagenes de seguimiento", date: new Date().toLocaleString() });
            } else {
              alert("Los examenes fueron enviados");
              if(this.assessment.messages == null) {
                this.assessment.messages = [];
              }
              this.assessment.messages.push({ sender: 'user', message: "Se enviaron nuevos estudios", date: new Date().toLocaleString() });
            }

            let updatedMessages = {
              assessment_id: this.assessment.id,
              messages: this.assessment.messages,
              date: new Date().toLocaleString(),
            }
            await axios.put(`${this.$baseURL}/api/update-assessment-messages`, updatedMessages);
          }
        } catch (error) {
          console.error('Error creating assessment:', error);
          throw error; // Propagate the error so we can handle it in the calling function
        }
      } else {
        try {
          const files_data = {
            assessment_id: this.assessment.id,
            file_name: fileName,
            file_extension: fileExtension,
            file_type: fileType,
          }
          await axios.post(`${this.$baseURL}/api/assessment-files`, files_data);

          if(position+1 == total) {
            if(folder == 'images/') {
              alert("Las imagenes fueron enviadas");
              if(this.assessment.messages == null) {
                this.assessment.messages = [];
              }
              this.assessment.messages.push({ sender: 'user', message: "Se enviaron nuevas imagenes", date: new Date().toLocaleString() });
            } else {
              alert("Los examenes fueron enviados");
              if(this.assessment.messages == null) {
                this.assessment.messages = [];
              }
              this.assessment.messages.push({ sender: 'user', message: "Se enviaron nuevos estudios", date: new Date().toLocaleString() });
            }

            let updatedMessages = {
              assessment_id: this.assessment.id,
              messages: this.assessment.messages,
              date: new Date().toLocaleString()
            }
            await axios.put(`${this.$baseURL}/api/update-assessment-messages`, updatedMessages);
          }
        } catch (error) {
          console.error('Error creating assessment:', error);
          throw error; // Propagate the error so we can handle it in the calling function
        }
      }
    },

    async sendMessage() {
      if(this.newMessage.trim() !== '') {
        if(this.assessment.messages == null) {
          this.assessment.messages = [];
        }
        this.assessment.messages.push({ sender: 'user', message: this.newMessage, date: new Date().toLocaleString() });

        let updatedMessages = {
          assessment_id: this.assessment.id,
          messages: this.assessment.messages,
          date: new Date().toLocaleString()
        }

        this.newMessage = '';
        await axios.put(`${this.$baseURL}/api/update-assessment-messages`, updatedMessages);
      }
    },

    async markAsRead() {
      this.assessment.new_message = 0;
      let updatedMessages = {
        assessment_id: this.assessment.id, 
      }
      await axios.put(`${this.$baseURL}/api/update-read-message`, updatedMessages);
    },

    toggleDate(index) {
      if (this.visibleDateIndex === index) {
        this.visibleDateIndex = null; // Hide the date if the same message is clicked again
      } else {
        this.visibleDateIndex = index; // Show the date for the clicked message
      }
    }
  },
}
</script>
  
<style scoped>
.regresar-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: auto;
  margin-right: 20px; /* Ajusta según sea necesario */
  border: 2px solid #0D80FF;
  border-radius: 10px;
  background-color: #0D80FF; /* Color de fondo azul */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave opcional */
}

.regresar-button:hover {
  background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
}

.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.message {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
}

.user-message {
  text-align: left;
  margin-right: 10px;
  margin-left: 60px;
  background-color: #d0e0f0;
}

.admin-message {
  text-align: left;
  margin-right: 60px;
  margin-left: 10px;
  background-color: #b9b9b9;
}
</style>
